'use strict';
var ProductConstants = require('./constants'),
    Constants = require('./../../constants'),
    CssConstants = require('./../../cssconstants'),
    layout = require('./../../layout');

var zoomMediaQuery = matchMedia('(min-width: ' + layout.viewports.tablet.maxWidth + 'px)');
var zoomMobileMediaQuery = matchMedia('(max-width: ' + layout.viewports.mobile.maxWidth + 'px)');

function initZoom() {
    var $img = $("." + ProductConstants.CLASS_PRD_IMAGES_ZOOM);

    //plug-in related class -> cannot be refactored
    $('.zoomImg').remove();
    if ($img.length === 0 || !zoomMediaQuery.matches) {
        $img.each(function () {
            $(this).trigger('zoom.destroy');
        });
    }

    if (!layout.isIPadDevice()) {
        if (zoomMediaQuery.matches) {
            $img.each(function () {
                var $el = $(this),
                    $zoomedEl = $el.closest(Constants.CLASS_CAROUSEL).next(ProductConstants.CLASS_PRD_IMAGES_ZOOMED),
                    hiresUrl = $el.attr('href');
                if (hiresUrl && hiresUrl !== 'null' && hiresUrl.indexOf('noimagelarge') === -1) {
                    $el.zoom({
                        magnify: 1.2,
                        url: hiresUrl,
                        target: $zoomedEl.get(0),
                        onZoomIn: function(){
                            $zoomedEl.addClass(CssConstants.M_SHOW);
                        },
                        onZoomOut: function(){
                            $zoomedEl.removeClass(CssConstants.M_SHOW);
                        }
                    });
                }
            });
        } /* else if (zoomMobileMediaQuery.matches) {
            $img.each(function () {
                var $el = $(this),
                    hiresUrl = $el.attr('href');

                $el.click(function (e){
                    e.preventDefault();

                    var $header = $('.js-sticky_header');
                    var wasSearchVisible = !$header.hasClass('shrink');

                    if (wasSearchVisible) {
                        $header.addClass('shrink');
                    }

                    var $zoomContainer = $el.closest(Constants.CLASS_CAROUSEL).next(ProductConstants.CLASS_PRD_IMAGES_ZOOMED);
                    if (!$zoomContainer.length || layout.isMobile()) {
                        return;
                    }

                    var $closeZoomContainer = $zoomContainer.children('.js-zoom_close');

                    // get the image to zoom inside, and apply the zoom
                    $zoomContainer.append('<img class="zoomable-img"/>');
                    $zoomContainer.children('img').attr('src', hiresUrl);
                    var $zoomedEl = $zoomContainer.children('img');

                    var elem = $zoomedEl[0];
                    var panzoom = Panzoom(elem, {
                        minScale: 1,
                        contain: 'automatic'
                    })

                    $closeZoomContainer.click(function() {
                        $zoomContainer.removeClass(CssConstants.M_SHOW);
                        $zoomedEl.remove();

                        if (wasSearchVisible) {
                            $header.removeClass('shrink');
                        }
                    });

                    $zoomContainer.addClass(CssConstants.M_SHOW);
                });
            });
        } */
    }
};

zoomMediaQuery.addListener(initZoom);
zoomMobileMediaQuery.addListener(initZoom);

module.exports = {
    init : function() {
        initZoom();
    }
};
