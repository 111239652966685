'use strict';

var $ = (typeof window !== "undefined" ? window['jQuery'] : typeof global !== "undefined" ? global['jQuery'] : null);
var dialog = require('./dialog');
var util = require('./util');

var makeUrl = function (url, source, productListID) {
    if (source) {
        url = util.appendParamToURL(url, 'source', source);
    }
    if (productListID) {
        url = util.appendParamToURL(url, 'productlistid', productListID);
    }
    return url;
};

var addToBagMobile = {
    init: function () {
        if (!this.exists()) {
            this.$container = $('<div/>').attr('id', 'AddToBagDialog').appendTo(document.body);
        }
    },

    /**
     * @description show quick view dialog
     * @param {Object} options
     * @param {String} options.url - url of the product details
     * @param {String} options.source - source of the dialog to be appended to URL
     * @param {String} options.productlistid - to be appended to URL
     * @param {Function} options.callback - callback once the dialog is opened
     */
    show: function (options) {
        // if (!util.isMobile()) {
        //     return;
        // }

        var url;
        if (!this.exists()) {
            this.init();
        }

        url = makeUrl(options.url, options.source, options.productlistid);

        dialog.open({
            target: this.$container,
            url: url,
            options: {
                width: 'auto',
                title: Resources.QUICK_VIEW_POPUP,
                dialogClass: 'b-add-to-bag-mobile',
                open: function () {
                    if (typeof options.callback === 'function') { options.callback(); }
                }.bind(this)
            }
        });
    },

    exists: function () {
        return this.$container && (this.$container.length > 0);
    }

}

module.exports = addToBagMobile;
