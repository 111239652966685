'use strict';

var ajax = require('./../../ajax'),
    formPrepare = require('./formPrepare'),
    progress = require('./../../progress'),
    tooltip = require('./../../../../../app_storefront_core/cartridge/js/tooltip'),
    util = require('./../../../../../app_storefront_core/cartridge/js/util'),
    $ = (typeof window !== "undefined" ? window['jQuery'] : typeof global !== "undefined" ? global['jQuery'] : null),
    JSConstants = require('./constants'),
    CssConstants = require('./../../cssconstants'),
    shippingMethodsModel = require('./shipping-methods'),
    dialog = require('./../../dialog');

var $cache = {},
    shippingMethods,
    options = {
        shippingMethodList: '#shipping-method-list',
        orderTotal: '.js-order_total',
        shippingFields: '.js-shipping_fields',
        fieldContainer: 'div.f-field-row',
        fieldSelectAddress: '[name$="_singleshipping_addressList"]',
        fieldAddress1: '[name$="_addressFields_address1"]',
        fieldAddress2: '[name$="_addressFields_address2"]',
        fieldCountry: '[name$="_addressFields_country"]',
        fieldState: '[name$="_addressFields_states_state"]',
        fieldCity: '[name$="_addressFields_city"]',
        fieldZip: '[name$="_addressFields_postal"]',
        fieldAddToAddressBook: '[name$="_shippingAddress_addToAddressBook"]',
        fieldUseAsBillingAddress: '[name$="_shippingAddress_useAsBillingAddress"]'
    },
    giftWrappingEnabled = false,
    giftWrappingWrapper;

function initializeCache() {
    $cache.document = $(document);
    $cache.shippingMethodList = $(options.shippingMethodList);
    initializeForm();
    initializeShippingMethods();
    shippingMethods.updateShippingMethodList();
}

function initializeEvents() {
    $cache.document
        .on('click', 'input[name$="_shippingAddress_isGift"]', giftWrapping)
        .on('change', 'select[name$="_country"]', countryChangeHanlder)
        .on('blur', '[name$="_addressFields_postal"]', checkAddressForm)
        .on('address.updated', shippingMethods.updateShippingMethodList)
        .on('address.reinit', initializeForm)
        .on('address.populated', checkAddressForm)
        .on('click', JSConstants.CLASS_PLACE_ORDER_BUTTON, checkRequiredFields)
        .on('continue, continue.disabled', continueChangeState)
        .on('updatesshippingmethods', function() {
            showShippingAddressForm();
        })
        .on('change', options.shippingMethodList, function() {
            showShippingAddressForm();
        });

        $('.address').on('change',
            options.fieldAddress1 + ', ' + options.fieldAddress2 + ', ' + options.fieldState + ', ' + options.fieldCity + ', ' + options.fieldZip,
            shippingMethods.updateShippingMethodList
        );

        if (window.gle && ('GlobalE' in window) && $('select[name$="_country"]').val() != GlobalE.Country) {
            gle("ShippingSwitcher", "show");
            $('select[name$="_country"]').val('');
        }
}

function countryChangeHanlder(e) {
   if (window.gle && ('GlobalE' in window) && e.target.value !== GlobalE.Country) {
       gle("ShippingSwitcher", "show");
       e.target.value = '';
   }
  shippingMethods.updateShippingMethodList.apply(this, arguments);
}

function initializeForm() {
    formPrepare.init({
        continueSelector: '[name$="shippingAddress_save"], ' + JSConstants.CLASS_PLACE_ORDER_BUTTON,
        formSelector:'[id$="singleshipping_shippingAddress"]'
    });
}

function initializeGiftWrapping() {
    giftWrappingWrapper = $('.js-gift_wrapping-wrapper');
    giftWrappingEnabled = giftWrappingWrapper.data('giftWrappingEnabled');
}

function giftWrapping(ev) {
    if (ev.target.disabled) {
        return;
    }

    var oppositeOption = $('input[name$="_shippingAddress_isGift"]:not(:checked)')[0];

    if (ev.target.value !== 'true') {
        removeGiftWrap();
        giftMessageBox();

        ev.target.disabled = true;
        oppositeOption.disabled = false;

        return;
    }

    if (giftWrappingEnabled) {
        giftMessageBox();

        var $giftWrapper = $('.js-gift_wrapping-wrapper');
        if(!$giftWrapper.data('isGiftWrapInCart')) {
            addGiftWrapToCart()
        }

        var $shippingAddressGiftMessage = $('[id$="shippingAddress_giftMessage"]');
        if($shippingAddressGiftMessage.length) {
            $shippingAddressGiftMessage.on('blur', addGiftWrapMessage);
        }

        ev.target.disabled = true;
        oppositeOption.disabled = false;

        return;
    }

    ev.preventDefault();
    giftWrappingDialog();

    ev.target.disabled = true;
    oppositeOption.disabled = false;
}

function addGiftWrapToCart() {
    var wrapContainer = $('.js-gift_wrapping-wrapper');
    $.ajax({
        type: 'POST',
        format: 'ajax',
        url: util.appendParamsToUrl(wrapContainer.data('addToCartUrl'), { format: 'ajax', cartAction: 'add', pid: wrapContainer.data('giftWrapPid')}),
        success: function (response) {
            wrapContainer.data('isGiftWrapInCart', true);
            updateSummary();
        },
        failure: function (error) {
            console.log(error);
        }
    });
}

function removeGiftWrap() {
    var wrapContainer = $('.js-gift_wrapping-wrapper');
    $.ajax({
        type: 'POST',
        format: 'ajax',
        url: util.appendParamsToUrl(wrapContainer.data('removeFromCartUrl'), {pid: wrapContainer.data('giftWrapPid')}),
        success: function (response) {
            wrapContainer.data('isGiftWrapInCart', false);
            updateSummary();
        },
        failure: function (error) {
            console.log(error);
        }
    });
}

function addGiftWrapMessage() {
    if ($('[id$="shippingAddress_giftMessage"]').val().length) {
        var wrapContainer = $('.js-gift_wrapping-wrapper');
        $.ajax({
            type: 'POST',
            format: 'ajax',
            url: util.appendParamsToUrl(wrapContainer.data('giftWrapMsgUrl'), {giftWrapMsg: $('[id$="shippingAddress_giftMessage"]').val()}),
            success: function (response) {
            },
            failure: function (error) {
                console.log(error);
            }
        });
    }
}
/**
 * @function
 * @description Initializes gift message box, if shipment is gift
 */
function giftMessageBox() {
    var isGiftField = $('input[name$="_shippingAddress_isGift"]:checked');

    // show gift message box, if shipment is gift
    $(JSConstants.CLASS_GIFT_MESSAGE_TEXT).toggleClass('hidden', isGiftField.val() !== 'true');
    $(JSConstants.CLASS_GIFT_WRAPPING_ITEM).toggleClass(CssConstants.M_CHECKED);
}

function giftWrappingDialog() {
    dialog.open({
        html: Resources.GIFT_WRAPPING_DISALLOWED
    });
}

/**
 * @function
 * @description Initializes Shipping Methods
 */
function initializeShippingMethods() {
    shippingMethods = shippingMethodsModel.init({
        'shippingMethodList': options.shippingMethodList,
        'refreshBlock': options.orderTotal,
        'refreshUrl': Urls.totalsRefreshURL,
        'form': options.shippingFields,
        'isCart': false
    });
}

/**
 * @function
 * @description updates the order summary based on a possibly recalculated basket after a shipping promotion has been applied
 */
function updateSummary() {
    var $summary = $('#secondary.summary');
    // indicate progress
    progress.show($summary);

    // load the updated summary area
    $summary.load(Urls.summaryRefreshURL, function () {
        // hide edit shipping method link
        $summary.fadeIn('fast');
        $summary.find('.checkout-mini-cart .minishipment .header a').hide();
        $summary.find('.order-totals-table .order-shipping .label a').hide();
    });
}

function checkAddressForm() {
    shippingMethods.updateShippingMethodList();
    setTimeout(function() {
        formPrepare.validateForm();
    }, 200);
}

function checkRequiredFields() {
    var isAllValid = true;
    formPrepare.validateForm();
    $(JSConstants.CLASS_SHIPPING_FORM).find('textarea:visible,input.required:visible, select.required:visible').each(function() {
        if (!$(this).valid()) {
            $(window).scrollTop($(this).closest('.f-field-row').offset().top);
            isAllValid = false;
            return false;
        }
    });
    if(isAllValid && !shippingMethodsModel.validateDeliveryToStore()) {
        $(window).scrollTop($(JSConstants.CLASS_DELIVERY_OPTION + '.' + CssConstants.M_CHECKED).offset().top);
        isAllValid = false;
    }

    if (isAllValid) {
        var $address1 = $(options.fieldAddress1),
            $postalCode = $(options.fieldZip),
            $city = $(options.fieldCity);
        if (!$postalCode.val() || !$address1.val() || !$city.val()) {
            isAllValid = false;
            toggleForm(true);
            return false;
        }
    }
    if (isAllValid) {
        $(JSConstants.CLASS_CHECKOUT_SAVE).trigger('click');
    }
}

function toggleForm(show) {
    var edqAddressLine1Selector,
    edqAddressLine2Selector,
    edqCityLineSelector,
    edqPostalLineSelector,
    edqStateLineSelector,
    edqEmailLineSelector,
    edqPhoneLineSelectors;

    var inputSelector = document.querySelectorAll('input[id]');

    for (var i = 0; i < inputSelector.length; i++) {
        edqAddressLine1Selector = (inputSelector[i].id.toLowerCase().match(/address1/)) ? inputSelector[i] : edqAddressLine1Selector;
        edqAddressLine2Selector = (inputSelector[i].id.toLowerCase().match(/address2/)) ? inputSelector[i] : edqAddressLine2Selector;
        edqCityLineSelector = (inputSelector[i].id.toLowerCase().match(/city/)) ? inputSelector[i] : edqCityLineSelector;
        edqPostalLineSelector = (inputSelector[i].id.toLowerCase().match(/postal/)) ? inputSelector[i] : edqPostalLineSelector;
        edqPhoneLineSelectors = (inputSelector[i].id.toLowerCase().match(/phone/)) ? inputSelector[i] : edqPhoneLineSelectors;
        edqEmailLineSelector = ((inputSelector[i].id == 'dwfrm_profile_customer_email')
            || (inputSelector[i].id == 'dwfrm_billing_billingAddress_email_emailAddress')) ? inputSelector[i] : edqEmailLineSelector;
        edqStateLineSelector = (inputSelector[i].id.toLowerCase().match(/state/)) ? inputSelector[i] : edqStateLineSelector;
    }

    var edqOneLineAddressSelector = document.querySelector(
        "input[id$='oneline']"
    );
    var auxList = [
        edqAddressLine1Selector,
        edqAddressLine2Selector,
        edqCityLineSelector,
        edqStateLineSelector,
        edqPostalLineSelector,
    ];
    var display = show ? "block" : "none";
    auxList.forEach(function (element) {
        if (element) {
            document
                .querySelector("#" + element.id)
                .closest(".f-form-row").style.display = display;
        }
    });
    edqOneLineAddressSelector.closest(".f-form-row").style.display = show ? "none" : "block";

    var toggleLink = document.querySelector(".js-toggle-manual-form");

    if (show) {
        toggleLink.text = "Select address again";
        toggleLink.removeAttribute("data-collapsed");
    } else {
        toggleLink.text = "Enter address manually";
        toggleLink.setAttribute("data-collapsed", "");
    }
}

function continueChangeState(event) {
    var currentState = event.namespace;
    $(JSConstants.CLASS_PLACE_ORDER_BUTTON).prop('disabled', currentState ? 'disabled' : '');
}

function showShippingAddressForm() {
    var shippingFieldsForm = $(options.shippingFields);
    var selectedShippingMethod = $(options.shippingMethodList).find('.input-radio:checked');
    if (selectedShippingMethod.length) {
        var isDeliveryToStore = selectedShippingMethod.val().indexOf('deliveryToStore') !== -1;
        shippingFieldsForm
            .find(options.fieldSelectAddress + ', ' +
                  options.fieldAddress1 + ', ' +
                  options.fieldAddress2 + ', ' +
                  options.fieldCountry + ', ' +
                  options.fieldState + ', ' +
                  options.fieldCity + ', ' +
                  options.fieldZip + ', ' +
                  options.fieldAddToAddressBook + ', ' +
                  options.fieldUseAsBillingAddress)
        .each(function() {
            var $this = $(this);
            if (isDeliveryToStore) {
                $this.addClass(JSConstants.CLASS_VALIDATE_IGNORE).parents(options.fieldContainer).addClass(CssConstants.G_HIDDEN);
            } else {
                if($cache.shippingMethodList.data('storeid')) {
                    if ($this.is('select')) {
                        $this.find('option:first').attr('selected', true);
                    } else {
                        $this.val('');
                    }
                }
                $this.removeClass(JSConstants.CLASS_VALIDATE_IGNORE).parents(options.fieldContainer).removeClass(CssConstants.G_HIDDEN);
            }
        });
        if (!isDeliveryToStore && $(options.fieldSelectAddress).length && $cache.shippingMethodList.data('storeid')) {
            $(options.fieldSelectAddress).find('option').eq(1).attr('selected', true);
            $(options.fieldSelectAddress).trigger('change');
            $cache.shippingMethodList.data('storeid', '');
        }
    }
    shippingFieldsForm.removeClass(CssConstants.G_HIDDEN);
    $(document).trigger('address.reinit');
}

function initSelectedStore(store) {
    var $address1 = $(options.fieldAddress1),
        $country = $(options.fieldCountry),
        $postalCode = $(options.fieldZip),
        $city = $(options.fieldCity);

    if(!$address1.length || !$country.length || !$postalCode.length || !$city.length) {
        return;
    }

    $country.find('option').each(function() {
        this.selected = (this.text === store.countryCode);
    });
    $address1.val(store.address1);
    $postalCode.val(store.postalCode);
    $city.val(store.city);
}

var shipping = {
    init : function (params) {
        initializeCache();
        initializeEvents(params);
        initializeGiftWrapping();
    },
    updateShippingMethodList : function () {
        shippingMethods.updateShippingMethodList();
    },
    initSelectedStore: initSelectedStore
};

module.exports = shipping;
