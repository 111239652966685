'use strict';

function setTimeoutToRedirectFromPage() {
    // 5 min
    var time = 5 * 60000;

    setTimeout(function() {
        if (window.Urls && window.Urls.homepage) {
            window.location.href = window.Urls.homepage;
        }
    }, time);
}

exports.init = function () {
    setTimeoutToRedirectFromPage();
};
