'use strict';

var ajax = require('./../../ajax');
var util = require('./../../util');

function updateProductInfoAccordion($context, productID) {
    if (!$context || !productID) {
        return;
    }

    var params = {
        productID: productID
    };

    ajax.load({
        url: util.appendParamsToUrl(window.Urls.getProductInfo, params),
        timeoutProgress: 2500,
        callback: function(response) {
            var $parsedResponse = $($.parseHTML(response));
            var newAccordion = $parsedResponse.find('.b-tabs');
            var $oldAccordion = $context.find('.b-tabs');

            $oldAccordion.replaceWith(newAccordion);
        }
    })
}

module.exports = {
    updateProductInfoAccordion: updateProductInfoAccordion
}
