module.exports = {
    /*
     * Global
     */
    CLASS_ACTIVE_STATE              : 'm-active',

    CLASS_BILLING_FORM              : '.js-checkout_billing',
    CLASS_BILLING_AREA              : '.js-billing-form-area',
    CLASS_SHIPPING_FORM             : '.js-checkout_shipping',
    CLASS_PAYMENT_METHODS           : '.js-payment_method_options',
    CLASS_PAYMENT_METHOD_WRAPPER    : '.js-payment_method_wrapper',
    CLASS_PAYMENT_METHOD            : '.js-payment_method',
    CLASS_PAYMENT_CC_WRAPPER        : '.js-payment_method_credit_card',
    CLASS_PAYMENT_HEADER            : '.js-payment-header',
    CLASS_ADDRESS                   : '.js-address',
    CLASS_VALIDATE_IGNORE           : 'js-validate-ignore',
    CLASS_SELECT_ADDRESS            : '.js-select_address',
    CLASS_DELIVERY_THRESHOLD        : '.js-cart-delivery-threshold',

    CLASS_PLACE_ORDER_BUTTON    : '.js-checkout-continue',
    CLASS_CHECKOUT_SAVE         : '.js-checkout_save',
    CLASS_PAYPAL_SELECTOR       : '.js-paypal-button',
    CLASS_KLARNA_PLACE_BUTTON   : '.js-submit-klarna',
    CLASS_KLARNA_AUTH_BUTTON    : '.js-authorize-klarna',
     /*
     * VipPoints integration
     */
    CLASS_VP_CONTAINER          : '.js-vp_wrapper',
    // actions
    CLASS_VP_CHECKBALANCE       : '.js-vp_action_checkbalance',
    CLASS_VP_REDEEM             : '.js-vp_action_redeem',
    CLASS_VP_USE                : '.js-vp_action_use',
    CLASS_VP_REMOVE             : '.js-vp_action_remove',
    // containers
    CLASS_VP_INPUT              : '.js-vp_input',
    CLASS_VP_INPUT_AFTER        : '.js-vp_input_after',
    CLASS_VP_CHECK_CONTAINER    : '.js-vp-check-container',
    CLASS_VP_RESULT_WRAPPER     : '.js-vp_result_wrapper',
    CLASS_VP_RESULT             : '.js-vp_result',
    CLASS_VP_USE_MSG            : '.js-vp_use_message',
    CLASS_VP_USE_MSG_WRAPPER    : '.js-vp_use_message_wrapper',

    /*
     * Gift Cards integration
     */
    CLASS_GC_CONTAINER                 : '.js-gc-wrapper',
    CLASS_GC_CONTENT                   : '.js-giftcard-method',

    CLASS_GC_DIALOG             : '.js-gc-dialog',
    CLASS_GC_DIALOG_BUTTON      : '.js-gc-dialog-button',
    // actions
    CLASS_GC_CHECKBALANCE              : '.js-gc-action-checkbalance',
    CLASS_GC_APPLYGIFTCARD             : '.js-gc-action-apply',
    CLASS_GC_REMOVEGIFTCARD            : '.js-gc-action-remove',
    CLASS_GC_REDEEMGIFTCARD            : '.js-gc-action-redeem',
    CLASS_GC_OPENDIALOG                : '.js-gc-action-opendialog',
    // containers
    CLASS_GC_CODE                      : '.js-gc-cardnumber',
    CLASS_GC_PIN                       : '.js-gc-cardpin',
    CLASS_GC_AMOUNT                    : '.js-gc-cardamount',
    CLASS_GC_BALANCEWRAPPER            : '.js-gc-balance-wrapper',
    CLASS_GC_REDEEMWRAPPER             : '.js-gc-redeem-wrapper',
    CLASS_GC_RESULTWRAPPER             : '.js-gc-result-wrapper',
    CLASS_GC_RESULTMSG                 : '.js-gc-result',
    CLASS_GC_APPLYWRAPPER              : '.js-gc-amountapply-wrapper',
    CLASS_GC_USE_MSG                   : '.js-gc-use-message',
    CLASS_GC_USEMSGWRAPPER             : '.js-gc-use-message-wrapper',
    CLASS_GC_ERROR                     : '.js-gc-error',
    CLASS_GC_CURRENCY_SYMBOL           : '.js-gc_currency_symbol',
    CLASS_GC_USEMSGWRAPPER_GLOBAL      : '.js-gc-use-message-wrapper-global',
    CLASS_GC_USEMSGWRAPPER_TEMPLATE    : '.js-gc-use-message-wrapper-template',

    /*
     * Selectors for instore pickup
     */
    CLASS_POSTCODE_FIELD : '.js-delivery_to_store',
    CLASS_POSTCODE_BUTTON : '.js-delivery_to_store_button',
    CLASS_POSTCODE_FORM : '.js-postcode_form',
    CLASS_STORE : '.js-store',
    CLASS_TAB_HEADER : '.js-tab_header',
    CLASS_CHOOSEN_STORE : '.js-choosen_store',
    CLASS_DELIVERY_TO_STORE_SECTION : '.js-delivery_to_store_section',
    CLASS_SHOW_ALL_STORES : '.js-show-all-postcode-stores',
    CLASS_CHOOSE_AGAIN : '.js-choose_again',
    CLASS_CHOOSE_STORE : '.js-chose_store',
    CLASS_SHIPPING_METHOD_LIST: '.js-shipping_method_list',
    CLASS_SHIPING_ERROR_MESSAGE: '.js-shipping_error_message',
    CLASS_DELIVERY_POSTCODE_ERROR : '.js-delivery_postcode_error',
    CLASS_POSTCODE_EDIT_MESSAGE: '.js-postcode_edit_message',
    CLASS_POSTCODE_EDIT_LINK: '.js-postcode_edit_link',
    CLASS_POSTCODE_FIND_BUTTON: '.js-postcode_find_button',
    CLASS_DELIVERYTOSTORE_POSTAL: '.js-deliverytostore_postcode',
    CLASS_STORE_PARAMS: '.js-store_params',
    CLASS_COUNTRY_FIELD: '.js-country_field',
    CLASS_NOSTORE: '.js-nostore',

    /*
     * Gift Wrapping selectors
     */
    CLASS_GIFT_MESSAGE_TEXT : '.js-gift-message-text',
    CLASS_GIFT_WRAPPING_ITEM : '.js-gift_wrapping-item',
    CLASS_NICESCROLL_VIEWPORT: '.js-postcode_finder-viewport',

    /*
     * Delivery options selectors
     */
    CLASS_DELIVERY_OPTION : '.js-delivery_form_row',

    /*
     * Credit Cards
     */
    CLASS_CREDIT_CARD_NUMBER: '.js-creditcard-number',
    CLASS_PAYMENT_METHOD_IMAGE: '.js-payment-method-image',
    CLASS_CREDIT_CARD_TYPE: '.js-creditcard-type',
    /*
     * Confirmation register
     */
    CLASS_ORDERDETAILS_REGISTRATION     : '.js-orderdetails_registration',
    CLASS_CONFIRMATION_REGISTER_FORM    : '.js-confirmation_register_form',
    CLASS_CONFIRNATION_REGISTER_CONTENT : '.js-confirmation_register_content',
    CLASS_CONFIRMATION_REGISTER_ERROR   : '.js-confirmation_register_error',

    /*
     * Size Guide
     */
    CLASS_SIZE_CHART_lINK: '.js-size_chart_link',
    CLASS_SIZE_CHART_NICESCROLL_VIEWPORT: '.js-size_chart_viewport',

    /*
    * Klarna birthday fields
    */
   CLASS_BIRTHDAY: '.js-birthday',
   CLASS_BIRTHDAY_DAY: '.js-birthday_day',
   CLASS_BIRTHDAY_MONTH : '.js-birthday_month',
   CLASS_BIRTHDAY_YEAR: '.js-birthday_year',
   CLASS_BIRTHDAY_CONTAINER: '.js-klarna-birthday'

};
