'use strict';

var ajax = require('./../../ajax'),
    Constants = require('./constants'),
    tooltip = require('./../../../../../app_storefront_core/cartridge/js/tooltip'),
    util = require('./../../util'),
    dialog = require('./../../dialog');

function updateKlarnaMessages() {
    window.KlarnaOnsiteService = window.KlarnaOnsiteService || [];
    window.KlarnaOnsiteService.push({ eventName: 'refresh-placements' });
}

var productset = {
    init: function() {
        var $addToCart = $('.js-add-to-cart'),
            $addAllToCart = $('#add-all-to-cart'),
            $productSetList = $('#product-set-list');

        var updateAddToCartButtons = function () {
            if ($productSetList.find('.js-add-to-cart[disabled]').length > 0) {
                $addAllToCart.attr('disabled', 'disabled');
                // product set does not have an add-to-cart button, but product bundle does
                $addToCart.attr('disabled', 'disabled');
            } else {
                $addAllToCart.removeAttr('disabled');
                $addToCart.removeAttr('disabled');
            }
        };

        if ($productSetList.length > 0) {
            updateAddToCartButtons();
        }
        // click on swatch for product set
        $productSetList.on('click', Constants.CLASS_PRD_SET_SWATCH_ANCHOR, function (e) {
            e.preventDefault();
            if ($(this).parents('li').hasClass('unselectable')) { return; }
            var url = Urls.getSetItem + this.search;
            var $container = $(this).closest('.js-product-set-item');
            var qty = $container.find('form input[name="Quantity"]').first().val();

            ajax.load({
                url: util.appendParamToURL(url, 'Quantity', isNaN(qty) ? '1' : qty),
                target: $container,
                callback: function () {
                    updateAddToCartButtons();
                    tooltip.init();
                }
            });
        });

        $('.js-klarna-pdp-modal-trigger').on('click', function(e) {
            e.preventDefault();
            dialog.open({
                url: $(e.target).attr('data-url'),
                options: {
                    width: 900,
                    height: 640,
                    dialogClass: 'b-klarna-pdp-dialog'
                },
                callback: function () {
                    updateKlarnaMessages()
                }
            });
        });
    }
}

module.exports = productset;
