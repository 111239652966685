'use strict';

var $ = (typeof window !== "undefined" ? window['jQuery'] : typeof global !== "undefined" ? global['jQuery'] : null),
    customScroll = require('malihu-custom-scrollbar-plugin')($),
    options = {
        'container': '',
        'scrollbar-options': {
            'axis': 'y',
            'theme': 'dark-thin',
            'scrollInertia': 600,
            'documentTouchScroll': false
        }
    },
    cache = {};

function initializeParams(params) {
    options = $.extend(true, {}, options, params);
}

function initializeScrollBarElement() {
    var $element = $(options.container),
        elementOptions = $element.data('scrollbaroptions') || {};

    $element.mCustomScrollbar($.extend(true, {}, options['scrollbar-options'], elementOptions));
}

module.exports = {
    init: function(params) {
        initializeParams(params);
        initializeScrollBarElement();
    }
};