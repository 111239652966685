'use strict';

var $               = (typeof window !== "undefined" ? window['jQuery'] : typeof global !== "undefined" ? global['jQuery'] : null),
    ajax            = require('./../../../../../app_storefront_core/cartridge/js/ajax'),
    progress        = require('./../../progress'),
    util            = require('./../../util'),
    layout          = require('./../../layout'),
    urls            = (typeof window !== "undefined" ? window['Urls'] : typeof global !== "undefined" ? global['Urls'] : null),
    sessionAttributes = (typeof window !== "undefined" ? window['SessionAttributes'] : typeof global !== "undefined" ? global['SessionAttributes'] : null),
    constants       = require('./constants'),
    cssConstants    = require('./../../cssconstants'),
    sitePreferences = (typeof window !== "undefined" ? window['SitePreferences'] : typeof global !== "undefined" ? global['SitePreferences'] : null),
    dialog          = require('./../../dialog/index');

var $cache = {},
    currentData,
    initialized = false,
    isUsed = false;

function initializeCache(params) {
    $cache.document         = $(document);
    $cache.paymentHeader    = $cache.document.find(constants.CLASS_PAYMENT_HEADER);
    $cache.container        = $cache.document.find(constants.CLASS_GC_CONTAINER);
    $cache.GCDialog         = $cache.document.find(constants.CLASS_GC_DIALOG);
    // actions
    $cache.checkBalance     = $cache.container.find(constants.CLASS_GC_CHECKBALANCE);
    $cache.applyGiftCard    = $cache.container.find(constants.CLASS_GC_APPLYGIFTCARD);
    $cache.removeGiftCard   = $cache.container.find(constants.CLASS_GC_REMOVEGIFTCARD);
    $cache.redeemGiftCard   = $cache.container.find(constants.CLASS_GC_REDEEMGIFTCARD);
    $cache.openGCDialog	    = $cache.document.find(constants.CLASS_GC_OPENDIALOG);
    // containers
    $cache.gcNumber                 = $cache.container.find(constants.CLASS_GC_CODE);
    $cache.gcPin                    = $cache.container.find(constants.CLASS_GC_PIN);
    $cache.gcAmount                 = $cache.container.find(constants.CLASS_GC_AMOUNT);
    $cache.balanceWrapper           = $cache.container.find(constants.CLASS_GC_BALANCEWRAPPER);
    $cache.applyWrapper             = $cache.container.find(constants.CLASS_GC_APPLYWRAPPER);
    $cache.resultWrapper            = $cache.container.find(constants.CLASS_GC_RESULTWRAPPER);
    $cache.redeemWrapper            = $cache.container.find(constants.CLASS_GC_REDEEMWRAPPER);
    $cache.useMsgWrapper            = $cache.container.find(constants.CLASS_GC_USEMSGWRAPPER);
    $cache.useMsgWrapperTemplate    = $cache.container.find(constants.CLASS_GC_USEMSGWRAPPER_TEMPLATE);
    $cache.useMsgWrapperGlobal      = $cache.container.find(constants.CLASS_GC_USEMSGWRAPPER_GLOBAL);

    
    $cache.gcError          = $cache.container.find(constants.CLASS_GC_ERROR);
    $cache.currencySymbol   = $cache.container.find(constants.CLASS_GC_CURRENCY_SYMBOL);
    $cache.gcContent        = $cache.container.find(constants.CLASS_GC_CONTENT);
    isUsed                  = $cache.container.data('isUsed');
}

function initializeEvents(params) {
    $cache.checkBalance.on('click', checkBalance);
    $cache.applyGiftCard.on('click', applyGiftCard);
    $cache.document.on('click', constants.CLASS_GC_REMOVEGIFTCARD, removeGiftCard);
    $cache.redeemGiftCard.on('click', redeemGiftCard);
    $cache.document.on('checkout.updateinstruments', renewBalance);
    $cache.gcContent.on('toggled', function (e, data) {
        $cache.gcNumber.rules('add', {
            required: data.expanded
        });
        $cache.gcPin.rules('add', {
            required: data.expanded
        });
    });
    if (isUsed) {
        toggleBalanceWrapper(false);
        toggleAppliedGiftCards(true);
        toggleRedeemWrapper($cache.useMsgWrapperGlobal.children().length);
    }
    $cache.openGCDialog.on('click', openDialog);
    $cache.document.on('click', constants.CLASS_GC_DIALOG_BUTTON, checkBalance);
}

function checkBalance(e) {
    e.preventDefault();
    initialized = true;

    $cache.resultWrapper.addClass(cssConstants.G_HIDDEN);
    $cache.useMsgWrapper.addClass(cssConstants.G_HIDDEN);

    if(!$cache.gcNumber.val() || !$cache.gcPin.val() || !$cache.gcNumber.valid() || !$cache.gcPin.valid()) {
        return;
    } else {
        $cache.gcError.html('');
    }
    var url = urls.gcGetBalance;
    var form = $(this).closest('form');

    var options = {
            url: url,
            method: 'POST',
            cache: false,
            dataType: "json",
            data: $(this).closest('fieldset').serialize() || form.serialize()
        };
    progress.show(constants.CLASS_GC_CONTENT);
    
    $.ajax(options).done(function (response) {
        if(response.success) {
            currentData = response;
            if(response.hidebalance) {
            	toggleBalanceWrapper(!response.hidebalance);
            }
            toggleApplyGiftCardForm(response.applied || !response.money);
            
            if (!response.money) {
            	toggleRedeemWrapper(true);
            }
            
            $cache.useMsgWrapper.removeClass(cssConstants.G_HIDDEN);
            resultWrapperMessage(response.message);
            $cache.currencySymbol.html(sessionAttributes.CURRENCY_SYMBOL);
            if(response.hidebalance) {
                $cache.gcAmount
                    .val(response.moneyNeeded)
                    .rules('remove', 'max');
                $cache.gcAmount.rules('add', {
                        max:response.moneyNeeded
                    });
            }
        } else {
            $cache.gcError.html(response.message);
        }
        
        progress.hide();
    });
}

function applyGiftCard(e) {
    e.preventDefault();
    var url = urls.gcApply;
    var form = $(this).closest('form');
    if (!$cache.gcAmount.valid()) {
        if ($cache.gcAmount.val() > currentData.moneyNeeded) {
            $cache.gcAmount.val(currentData.moneyNeeded);
        }
        return false;
    }
    var options = {
            url: url,
            method: 'POST',
            cache: false,
            dataType: "json",
            data: $(this).closest('fieldset').serialize() || form.serialize()
        };
    
    $.ajax(options).done(function (response) {
        if(response.success) {
            resultWrapperMessage(response.messageBalance);
            addGiftCardItem(response);
            toggleApplyGiftCardForm(true);
            clearBalanceWrapper();
            toggleRedeemWrapper(true);
            $cache.document.trigger('checkout.updatesummary');
            $cache.document.trigger('checkout.updateinstruments', response);
        }
    });
}



function removeGiftCard(e) {
    e.preventDefault();
    var me = $(this);
    var gcNumber = {gcNumber:$(this).data('giftcard')};
    var url = urls.gcRemove;
    ajax.getJson({
        url: url,
        method: 'POST',
        dataType: "json",
        data: gcNumber,
        callback: function (response) {
            if(response.success) {
                me.parent().remove();
                toggleBalanceWrapper(!$cache.useMsgWrapperGlobal.children().length);
                toggleRedeemWrapper($cache.useMsgWrapperGlobal.children().length);
                if (!$cache.useMsgWrapperGlobal.children().length) {
                    hideWrapperMessage();
                }
                $cache.document.trigger('checkout.updatesummary');
                $cache.document.trigger('checkout.updateinstruments', response);
            }
        }
    });
}

function redeemGiftCard(e) {
    e.preventDefault();
    toggleBalanceWrapper(true);
    $cache.resultWrapper
        .addClass(cssConstants.G_HIDDEN)
        .find(constants.CLASS_GC_RESULTMSG)
        .html('');
    toggleRedeemWrapper(false);
}

function renewBalance(e, eventData) {
    e.preventDefault();
    if (eventData.payNeeded) {
        $cache.container.removeClass(cssConstants.G_HIDDEN);
        $cache.paymentHeader.removeClass(cssConstants.G_HIDDEN);
    } else if (!eventData.payNeeded && (!initialized || !isUsed)) {
        $cache.container.addClass(cssConstants.G_HIDDEN);
        $cache.paymentHeader.addClass(cssConstants.G_HIDDEN);
        return;
    }
    if (initialized){
        $cache.checkBalance.trigger('click');
    }
}

function addGiftCardItem(response) {
    var $giftEntry = $cache.useMsgWrapperTemplate.clone();
    $giftEntry.removeClass(cssConstants.G_HIDDEN);
    $giftEntry.find(constants.CLASS_GC_USE_MSG).html(response.message);
    
    $giftEntry.find('a').data('giftcard',response.gcNumber).removeClass(cssConstants.G_HIDDEN);
    $giftEntry.appendTo(constants.CLASS_GC_USEMSGWRAPPER_GLOBAL);
}

function toggleApplyGiftCardForm(toggle) {
    $cache.applyWrapper.toggleClass(cssConstants.G_HIDDEN, toggle);
}

function toggleBalanceWrapper(toggle) {
    $cache.balanceWrapper.toggleClass(cssConstants.G_HIDDEN, !toggle);
}

function resultWrapperMessage(message) {
    $cache.resultWrapper
        .removeClass(cssConstants.G_HIDDEN)
        .find(constants.CLASS_GC_RESULTMSG)
        .html(message);
}

function hideWrapperMessage() {
    $cache.resultWrapper
        .addClass(cssConstants.G_HIDDEN)
        .find(constants.CLASS_GC_RESULTMSG)
        .html('');
}

function clearBalanceWrapper() {
    $cache.gcNumber.val('');
    $cache.gcPin.val('');
}

function toggleAppliedGiftCards(toggle) {
	$cache.useMsgWrapper.toggleClass(cssConstants.G_HIDDEN, !toggle);
}

function toggleRedeemWrapper(toggle) {
    $cache.redeemWrapper.toggleClass(cssConstants.G_HIDDEN, !toggle);
}

function openDialog (e) {
    if (!$cache.gcContent.length || $cache.GCDialog.length) {
        e.preventDefault();
        dialog.open({
            url: $(e.target).data('url'),
            options: {
                open: (function () {
                	initializeCache();
                }).bind(this),
                width: 535
            }
        });
	}
}

var giftcard = {
        init : function (params) {
            if (sitePreferences.GIFTCARD_ENABLED) {
                initializeCache(params);
                initializeEvents(params);
            } else {
                // hide Contents Assest's links for Gift Cards
                $('a[href*="GiftCard"]').parent().hide();
            }
        }
    };

module.exports = giftcard;