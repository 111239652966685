'use strict';

var ajax = require('./../../ajax'),
    progress = require('./../../progress'),
    $ = (typeof window !== "undefined" ? window['jQuery'] : typeof global !== "undefined" ? global['jQuery'] : null),
    urls = (typeof window !== "undefined" ? window['Urls'] : typeof global !== "undefined" ? global['Urls'] : null),
    JSConstants = require('./constants'),
    CssConstants = require('./../../cssconstants'),
    util = require('./../../util'),
    sessionAttributes = (typeof window !== "undefined" ? window['SessionAttributes'] : typeof global !== "undefined" ? global['SessionAttributes'] : null),
    resources = (typeof window !== "undefined" ? window['Resources'] : typeof global !== "undefined" ? global['Resources'] : null),
    $cache = {};

var shippingMethods;

var attributes = [
    'shippingMethodList',
    'refreshBlock',
    'refreshUrl',
    'form',
    'isCart'
];

function initializeCache(){
    $cache.document = $(document);
}

var shippingMethodsModel = {
    init: function(params) {
        initializeCache();
        params = params || {};
        setAttributes(params);

        return {
            updateShippingMethodList: function() {
                var $shippingMethodList = $(params.shippingMethodList);
                if (!$shippingMethodList || $shippingMethodList.length === 0) {
                    return;
                }

                ajax.getJson({
                    url: getShippingMethodURL(Urls.shippingMethodsJSON, {}, params),
                    callback: function(data) {
                        if (!data) {
                            window.alert('Couldn\'t get list of applicable shipping methods.');
                            return false;
                        }
                        if (shippingMethods && shippingMethods.toString() === data.toString() && !params.isCart) {
                            // No need to update the UI.  The list has not changed.
                            return true;
                        }
                        if (data.indexOf('deliveryToStore' + sessionAttributes.CURRENCY_CODE) === -1) {
                            $cache.document.trigger('removestore');
                        }
                        // We need to update the UI.  The list has changed.
                        // Cache the array of returned shipping methods.
                        shippingMethods = data;
                        // indicate progress
                        progress.show($shippingMethodList);

                        // load the shipping method form
                        var smlUrl = getShippingMethodURL(Urls.shippingMethodsList, {}, params);
                        $shippingMethodList.load(smlUrl, function() {
                            $shippingMethodList.fadeIn('fast');
                            // rebind the radio buttons onclick function to a handler.
                            $cache.document.on('click', '[name$="_shippingMethodID"]', function() {
                                selectShippingMethod($(this).val(), params);
                                $(JSConstants.CLASS_DELIVERY_OPTION).removeClass(CssConstants.M_CHECKED);
                                $(this).parents(JSConstants.CLASS_DELIVERY_OPTION).addClass(CssConstants.M_CHECKED);
                            });

                            // update the summary
                            updateTotals(params);
                            //if nothing is selected in the shipping methods select the first one
                            if ($shippingMethodList.find('.input-radio:checked').length === 0) {
                                $shippingMethodList.find('.input-radio:first').prop('checked', 'checked');
                            }
                            ($shippingMethodList.find('.input-radio').length === 0) && $cache.document.trigger('continue.disabled') || $cache.document.trigger('continue');
                            $cache.document.trigger('updatesshippingmethods');
                        });
                    }
                });
            }
        }
    },
    validateDeliveryToStore: validateDeliveryToStore
};

function setAttributes(params) {
    for (var i = 0; i < attributes.length; i++) {
        if (attributes[i] in params) {
            var p = attributes[i];
            attributes[p] = params[p];
        }
    }
}

function updatePaymentMessages() {
    var total = $('.js-order_total').find('.js-total').data('total')*1 || $('.js-order_total').data('total')*1;
    if (total) {
        $('.js-laybuy-value').html(sessionAttributes.CURRENCY_SYMBOL + (total / 6).toFixed(2).toString().replace('.00',''));
        $('klarna-placement').attr('data-purchase-amount', total * 100);
        window.KlarnaOnsiteService = window.KlarnaOnsiteService || [];
        window.KlarnaOnsiteService.push({ eventName: 'refresh-placements' });
    }
}


function updateTotals(params) {
    var $refreshBlock = $(params.refreshBlock);

    // indicate progress
    progress.show($refreshBlock);

    // load the updated totals area
    $refreshBlock.load(params.refreshUrl, function() {
        $refreshBlock.fadeIn('fast');
        progress.hide();
        $(document).trigger('totals.uptaded');
        updatePaymentMessages();
        if (params.isCart) {
            $('select[name$="_country"]').trigger('check.shippingmethods');
            if ( $refreshBlock.find('.order-shipping-discount').length && $('.rowcoupons.g-hidden').length && $('.rowcoupons.js-bonus-product-is-not-choosen').length ||
                !$refreshBlock.find('.discount').length && $('.rowcoupons:visible .discount.shipping').length) {
                require('./../../page').redirect(urls.cartShow);
            }
        }
    });
}

function getShippingMethodURL(url, extraParams, params) {
    var $form = $(params.form);
    var localParams = {
        address1: $form.find('[name$="_address1"]').val() || '',
        address2: $form.find('[name$="_address2"]').val() || '',
        countryCode: $form.find('[id$="_country"]').val() || '',
        stateCode: $form.find('[id$="_state"]').val() || '',
        postalCode: $form.find('[name$="_postal"]').val() || '',
        city: $form.find('[name$="_city"]').val() || '',
        isCart: params.isCart
    };

    return util.appendParamsToUrl(url, $.extend(localParams, extraParams));
}

function selectShippingMethod(shippingMethodID, params) {
    // nothing entered
    if (!shippingMethodID) {
        return;
    }

    var $postal = $(JSConstants.CLASS_DELIVERYTOSTORE_POSTAL);
    if($postal.length) {
        $postal.val('');
    }

    // attempt to set shipping method
    ajax.getJson({
        url: getShippingMethodURL(Urls.selectShippingMethodsList, {
            shippingMethodID: shippingMethodID
        }, params),
        callback: function(data) {
            if (Constants.ISIOSDEVICE && params.isCart) {
                window.location.reload();
            }
            updateTotals(params);
            if (!data || !data.shippingMethodID) {
                window.alert('Couldn\'t select shipping method.');
                return false;
            }
        }
    });
}

function validateDeliveryToStore() {
    var $shippingMethodsList = $(JSConstants.CLASS_SHIPPING_METHOD_LIST),
        $selectedShippingMethod = $shippingMethodsList.find('.input-radio:checked'),
        $errorBlock = $shippingMethodsList.find(JSConstants.CLASS_SHIPING_ERROR_MESSAGE),
        isValid = true;

    if($selectedShippingMethod.val().indexOf('deliveryToStore') !== -1 && $(JSConstants.CLASS_DELIVERY_TO_STORE_SECTION).is(':visible')) {
        $errorBlock.html(resources.VALIDATE_STORE);
        isValid = false;
    }
    return isValid;
}

module.exports = shippingMethodsModel;