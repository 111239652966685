'use strict';

var util = require('./../../util'),
    cssConstants = require('./../../cssconstants');

var $cache = {};
var Countries = window.Countries || {};

function initializeCache($form) {
    $cache.document         = $(document);
    $cache.addressForm      = $form || $cache.document.find('form.address');
    $cache.countrySelect    = $cache.addressForm.find('[id$="country"]');
    $cache.postalWrapper    = $cache.addressForm.find('[id$="postal"]').closest('.f-field-row');
    Countries = window.Countries || Countries;
}

function initializeEvents() {
    savedAddressChangeHandler();
    $cache.countrySelect.on('change', updateAddressForm);
}

function savedAddressChangeHandler() {
    // select address from list
    $('select[name$="_addressList"]', $cache.addressForm).on('change', function () {
        var selected = $(this).children(':selected').first();
        var selectedAddress = $(selected).data('address');
        if (!selectedAddress) { return; }
        util.fillAddressFields(selectedAddress, $cache.addressForm);
        $(document).trigger('address.updated');
        // re-validate the form
        $cache.addressForm.validate().form();
    });
}

function updateAddressForm(event, countrySelect) {
    var countryCode = (countrySelect || $(this)).val();
    if (countryCode in Countries) {
        var selectedCountryObject = Countries[countryCode];
        var postalInput = $cache.postalWrapper.find('input');
        if(selectedCountryObject.isPostalMandatory) {
            $cache.postalWrapper.addClass(cssConstants.REQUIRED);
            postalInput
                .addClass(cssConstants.REQUIRED)
                .rules('add', { 
                    required: true,
                    minlength: selectedCountryObject.minLength,
                    maxlength: selectedCountryObject.maxLength
                });
        } else {
            postalInput
                .removeClass(cssConstants.REQUIRED)
                .rules('remove');

            $cache.postalWrapper.removeClass(cssConstants.REQUIRED);
            $cache.postalWrapper.find('span.error').hide();
            $cache.postalWrapper.find('input.error').removeClass('error');
        }
        
        $(document).trigger('address.reinit');
    }
}
/**
 * @function
 * @description Selects the first address from the list of addresses
 */
var address = {
    init: function() {
        initializeCache();
        initializeEvents();
        updateAddressForm(null, $cache.countrySelect);
    }
};

module.exports = address;