'use strict';
var constants = require('./../pages/account/constants'),
    ProductConstants = require('./../pages/product/constants'),
    $ = (typeof window !== "undefined" ? window['jQuery'] : typeof global !== "undefined" ? global['jQuery'] : null);

function getProductResults(container) {
    var results = [];
    $(container).each(function(){
        var obj = $(this).data('dcItem');
        if(obj) {
            obj['htmlRef'] = this;
        }
        results.push(obj);

    });
    return results;
}

function updatedProductList (target) {
    var container = constants.PRODUCT_CONTENT;
    if(target === 'product'){
        dcData.productData.htmlRef = $(constants.PRODUCT_CONTENT)[0];

        if($('input[name=quantityInStock]').length && $('input[name=quantityInStock]').val()) {
            dcData.productData.stock = parseInt($('input[name=quantityInStock]').val(), 10);
        }

        if($('.js-size .selected .js-product-swatch_anchor').data('attr-value')) {
            dcData.productData.size = $('.js-size .selected .js-product-swatch_anchor').data('attr-value');
        }
        if($('.js-color .selected .js-product-swatch_anchor').data('attr-value')) {
            dcData.productData.color = $('.js-color .selected .js-product-swatch_anchor').data('attr-value');
        }
        if($(constants.PRODUCT_TOP_INFO + ' ' + constants.PRODUCT_PRICE).data('price')){
            dcData.productData.price = $(constants.PRODUCT_TOP_INFO + ' ' + constants.PRODUCT_PRICE).data('price')*1;
        }
    }
    if(target === 'search'){
        dcData.searchResults.results = getProductResults(ProductConstants.CLASS_PRD_IMAGES_CONTAINER);
    }
    if($(constants.MINICART_CONTENT).length || target === 'cart'){
        dcData.basketData.items = getProductResults(target === 'cart' ? constants.CART_CONTENT + ' ' + container : constants.MINICART_CONTENT + ' ' + container);
        var $delivery = $('.js-delivery_form_row.m-checked');
        if($delivery.length){
            dcData.basketData.delivery = $delivery.find('.b-shipping_description').html();
            dcData.basketData.deliveryCost = $delivery.find('.b-shipping_title').data('price')*1;
        }
        dcData.basketData.total = $(constants.ORDER_TOTAL).find('.js-total').data('total')*1 || $(constants.ORDER_TOTAL).data('total')*1;
        if (target === 'cart') {
            dcData.basketData.htmlRef = $(constants.CART_ITEMS_CONTAINER)[0];
        } else {
            dcData.basketData.htmlRef = $(constants.ORDER_TOTAL)[0];
        }
    }
    if(target === 'orderconfirmation'){
        dcData.saleData.items = getProductResults(constants.ORDER_CONTENT + ' ' + container);
    }
}

function initializeEvents(params) {
    var target = pageContext.ns;
    updatedProductList(target);
    if(pageContext.ns === "product"){
        dcData.productData.htmlRef = $(ProductConstants.CLASS_PRD_MAIN_CONTAINER)[0];
    }
    $(document).on('products.loaded product.updated totals.uptaded minicart.updated product.addedToCart', function(){
        updatedProductList(target);
    });

    $(document).on('gleOrderConfirmation', function(event, gleData, products){
        if (gleData.StepId == gleData.Steps.CONFIRMATION && gleData.IsSuccess) {
            window.dcData.saleData = {
                currencyCode: gleData.details.customerCurrency,
                checkoutCountry: gleData.details.country.code,
                localCurrency: gleData.details.customerCurrency,
                items: getProductData(gleData.details.products, products, gleData.details.discounts),
                total: gleData.details.customerTotalPrice,
                orderid: gleData.MerchantOrderId,
                shippingDetails: getShippingDetails(gleData.details),
                details: {
                    shippingDetails: getShippingDetails(gleData.details)
                }
            }
        }
    });
}

function getProductData(data, products, discounts) {
    var productsData = [];

    for (var i = 0; i < data.length; i++) {
        var productWithBasket = findProductInBasket(data[i].sku,products);
        var discount = findDiscount(data[i].cartItemId, discounts);

        productsData.push({
            category: productWithBasket.category,
            color: productWithBasket ? productWithBasket.color : '',
            desc: productWithBasket ? productWithBasket.desc : '',
            discount: discount,
            id: data[i].sku,
            imgURL: productWithBasket ? productWithBasket.imgURL : '',
            name: data[i].name,
            price: data[i].customerPrice,
            quantity: data[i].quantity,
            size: productWithBasket ? productWithBasket.size : '',
            stock: productWithBasket ? productWithBasket.stock : '',
            subCategory: productWithBasket.subCategory,
            unitPrice: data[i].customerPrice,
            unitSalePrice: data[i].customerPrice
        });
    }

    return productsData;
}

function getShippingDetails(data) {
    return {
        name: data.customerName,
        street1: data.CustomerInfo.ShippingAddress ? data.CustomerInfo.ShippingAddress.Address1 : '',
        street2: data.CustomerInfo.ShippingAddress ? data.CustomerInfo.ShippingAddress.Address2 : '',
        city: data.CustomerInfo.ShippingAddress ? data.CustomerInfo.ShippingAddress.City : '',
        postcode: data.CustomerInfo.ShippingAddress? data.CustomerInfo.ShippingAddress.ZipCode : '',
        state: data.CustomerInfo.ShippingAddress ? data.CustomerInfo.ShippingAddress.StateCode : '',
        country: data.CustomerInfo.ShippingAddress ? data.CustomerInfo.ShippingAddress.CountryCode : '',
        telephone: data.CustomerInfo.ShippingAddress ? data.CustomerInfo.ShippingAddress.Phone1 : '',
        shippingType: data.paymentMethodName,
        shippingCost: data.customerDiscountedShippingPrice
    };
}

function findProductInBasket(id, products) {
    for (var i = 0; i < products.length; i++) {
        if (products[i]['id'] == id) {
            return products[i];
        }
    }

    return null;
}

function findDiscount(cartItemId, discount) {
    for (var i = 0; i < discount.length; i++) {
        if (discount[i].productCartItemId == cartItemId) {
            return discount[i].customerPrice;
        }
    }

    return 0;
}

var datalayer = {
    init: function(option) {
       window.dcData = window.dcData || {};
       initializeEvents();
    }
};

module.exports = datalayer;
