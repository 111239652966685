'use strict';

var SessionAttributes = window.SessionAttributes;

/**
 * @function captcha    Used to display/control the scrim containing the simulated captcha code
 **/
var captcha = {
    init: function () {
        /**
        * if the session.privacy.ratelimited element is present then show the notification
        * NOTE: You will probably want to replace this with a call to an actual CAPTCHA system to replace the simple one here
        */
       if (SessionAttributes.SHOW_CAPTCHA) {
            var head=document.getElementsByTagName("head")[0];
            var script=document.createElement('script');
            script.src="https://www.google.com/recaptcha/api.js";
            script.async=true;
            script.defer=true;
            head.appendChild(script);
        }
    },
    onSuccess: function() {
        document.querySelector('form.b-login_block-form button').disabled = false;
    },
    onExpire: function() {
        document.querySelector('form.b-login_block-form button').disabled = true;
    }
};

module.exports = captcha;
