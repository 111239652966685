'use strict';
var $ = (typeof window !== "undefined" ? window['jQuery'] : typeof global !== "undefined" ? global['jQuery'] : null),
    Constants = require('./constants'),
    CssConstants = require('./../../cssconstants');

function increaseQuantity(e) {
    var $e = $(e),
        $inputCurrentQty = $e.find(Constants.CLASS_PRD_QUANTITY_FIELD),
        currentVal = $inputCurrentQty.val();
    if (currentVal) {
        currentVal = parseInt(currentVal);
        if (currentVal !== NaN) {
            $inputCurrentQty.val(currentVal + 1);
        } else {
            $inputCurrentQty.val(0);
        }
    }
};

function decreaseQuantity(e) {
    var $e = $(e),
        $inputCurrentQty = $e.find(Constants.CLASS_PRD_QUANTITY_FIELD),
        currentVal = $inputCurrentQty.val();
    if (currentVal) {
        currentVal = parseInt(currentVal);
        if (currentVal && currentVal - 1 >= 1 ) {
            $inputCurrentQty.val(currentVal - 1);
        } else {
            $inputCurrentQty.val(1);
        }
    }
};

function initializeEvents() {
    $(document).on('click', Constants.CLASS_PRD_QUANTITY_DECREASE, function() {
        var $this = $(this),
            $thisContainer = $this.closest(Constants.CLASS_PRD_QUANTITY_CMP);
        
        if($this.hasClass(CssConstants.M_DISABLED)) return;
        
        decreaseQuantity($thisContainer);
        $this.siblings('input' + Constants.CLASS_PRD_QUANTITY_FIELD).trigger('change');
    })
    .on('click', Constants.CLASS_PRD_QUANTITY_INCREASE, function() {
        var $this = $(this),
            $thisContainer = $this.closest(Constants.CLASS_PRD_QUANTITY_CMP);
        
        if($this.hasClass(CssConstants.M_DISABLED)) return;
        
        increaseQuantity($thisContainer);
        $this.siblings('input' + Constants.CLASS_PRD_QUANTITY_FIELD).trigger('change');
    })  
    .on('change', Constants.CLASS_PRD_QUANTITY_FIELD, function() {
        var $this = $(this),
            $thisDecrease = $this.prev(Constants.CLASS_PRD_QUANTITY_DECREASE),
            $thisIncrease = $this.next(Constants.CLASS_PRD_QUANTITY_INCREASE),
            newValue = parseInt($this.val()),
            maxAvailableCount = parseInt(Resources.MAX_AVAILABLE_COUNT);
        if (newValue <= 1) {
            $this.val(1);
            $thisDecrease.addClass(CssConstants.M_DISABLED);
            $thisIncrease.removeClass(CssConstants.M_DISABLED);
        } else if (newValue >= maxAvailableCount) {
            $this.val(maxAvailableCount);
            $thisDecrease.removeClass(CssConstants.M_DISABLED);
            $thisIncrease.addClass(CssConstants.M_DISABLED);
        } else {
            $this.val(newValue);
            $thisDecrease.removeClass(CssConstants.M_DISABLED);
            $thisIncrease.removeClass(CssConstants.M_DISABLED);
        }
    });
}

module.exports = {
    init: function() {
        initializeEvents();
    }
};