
var dialog = require('./../dialog');

exports.init = function () {
    /****************
     * array helpers *
     ****************/
    Array.prototype.indexOf2D = function (itemtofind) {
        var array2d = this;
        var index = array2d.flat().indexOf(itemtofind);

        // return "false" if the item is not found
        if (index === -1) {
            return false;
        }

        var numColumns = array2d[0].length;

        var row = parseInt(index / numColumns);
        var col = index % numColumns;

        return [row, col];
    };

    function getSize() {
        return currentPosition
            ? sizes[currentPosition[0]][currentPosition[1]]
            : null;
    }

    function modifyPosition(vertical, horizontal) {
        currentPosition[0] += vertical;
        currentPosition[1] += horizontal;

        // Position can't be out of bounds!
        for (var i = 0; i < 2; i++) {
            if (currentPosition[i] < 0) {
                currentPosition[i] = 0;
            } else if (currentPosition[i] > 8) {
                currentPosition[i] = 8;
            }
        }

        positionHistory[currentStep] = currentPosition.slice();
    }

    /*************************
     * move around the steps *
     *************************/

    function loadStep(stepNo) {

        var steps = document.querySelectorAll('[class^="step-"]');

        steps.forEach(function (step) {
            if (step.classList.contains("step-" + stepNo)) {
                step.style.display = "block";
            } else {
                step.style.display = "none";
            }
        });

        var stepsIndicator = document.querySelectorAll('[name="steps-control"]');
        stepsIndicator.forEach(function (step) {
            step.checked = (step.id == "step-" + stepNo);
        });


        currentStep = stepNo;

        switch (currentStep) {
            case 2:
                if (
                    !document
                        .querySelector(".underband-carousel")
                        .classList.contains("slick-initialized")
                ) {
                    $(".underband-carousel").slick({
                        dots: true,
                        arrows: false,
                        centerMode: false,
                        infinite: false,
                        speed: 300,
                        slidesToShow: 3,
                        slidesToScroll: 1,
                        responsive: [
                            {
                                breakpoint: 767,
                                settings: {
                                    centerMode: false,
                                    slidesToShow: 1,
                                    slidesToScroll: 1,
                                },
                            },
                        ],
                    });
                }
                break;

                case 3:
                    if (
                        !document
                            .querySelector(".straps-carousel")
                            .classList.contains("slick-initialized")
                    ) {
                        $(".straps-carousel").slick({
                            dots: true,
                            arrows: false,
                            centerMode: false,
                            infinite: false,
                            speed: 300,
                            slidesToShow: 3,
                            slidesToScroll: 1,
                            responsive: [
                                {
                                    breakpoint: 767,
                                    settings: {
                                        centerMode: false,
                                        slidesToShow: 1,
                                        slidesToScroll: 1,
                                    },
                                },
                            ],
                        });
                    }
                    break;

            case 4:
                if (
                    !document
                        .querySelector(".cup-carousel")
                        .classList.contains("slick-initialized")
                ) {
                    $(".cup-carousel").slick({
                        dots: true,
                        arrows: false,
                        centerMode: false,
                        infinite: false,
                        speed: 300,
                        slidesToShow: 5,
                        slidesToScroll: 1,
                        responsive: [
                            {
                                breakpoint: 767,
                                settings: {
                                    centerMode: false,
                                    slidesToShow: 1,
                                    slidesToScroll: 1,
                                },
                            },
                        ],
                    });
                }
                break;
            case 5:
                if (
                    !document
                        .querySelector(".centre-carousel")
                        .classList.contains("slick-initialized")
                ) {
                    $(".centre-carousel").slick({
                        dots: true,
                        arrows: false,
                        centerMode: false,
                        infinite: false,
                        speed: 300,
                        slidesToShow: 4,
                        slidesToScroll: 1,
                        responsive: [
                            {
                                breakpoint: 767,
                                settings: {
                                    centerMode: false,
                                    slidesToShow: 1,
                                    slidesToScroll: 1,
                                },
                            },
                        ],
                    });
                }
                break;

            default:
                break;
        }

        // Scroll top
        document.querySelector('div[class*="step-"]:not([style*="display: none;"]) h2').scrollIntoView(false);
    }

    document.querySelectorAll(".back").forEach(function (backButton) {
        backButton.addEventListener("click", function (e) {
            e.preventDefault();
            currentPosition = positionHistory[currentStep - 2]
                ? positionHistory[currentStep - 2].slice()
                : null;
            loadStep(currentStep - 1);
        });
    });

    /***************
     * definitions *
     ***************/

    var sizes = [
        ["28A", "28B", "28C", "28D", "28DD", "28E", "28F", "28FF", "28G"],
        ["30A", "30B", "30C", "30D", "30DD", "30E", "30F", "30FF", "30G"],
        ["32A", "32B", "32C", "32D", "32DD", "32E", "32F", "32FF", "32G"],
        ["34A", "34B", "34C", "34D", "34DD", "34E", "34F", "34FF", "34G"],
        ["36A", "36B", "36C", "36D", "36DD", "36E", "36F", "36FF", "36G"],
        ["36B", "38B", "38C", "38D", "38DD", "38E", "38F", "38FF", "38G"],
        ["38B", "38C", "40C", "40D", "40DD", "40E", "40F", "40FF", "40G"],
        ["XXX", "40C", "40D", "42D", "42DD", "42E", "42F", "42FF", "42G"],
        ["XXX", "XXX", "42D", "44D", "44DD", "44E", "44F", "44FF", "44G"],
    ];
    var originalSize;
    var currentBrand;
    var currentSize;
    var currentPosition;
    var positionHistory = new Array();

    var currentStep;

    /***************
     * quiz events *
     ***************/

    loadStep(1);

    // Q1: Current Size

    document.querySelector("#measuring-video").addEventListener("click", function (e) {
        e.preventDefault();
        dialog.open({
            url: e.target.href
        });
    });

    function validateQ1() {
        document.querySelector("input#q1").disabled =
            Array.from(
                document.querySelectorAll(".currentsize-select")
            ).filter(function (x) {
                return x.value !== "";
            }).length === 3
                ? false
                : true;
    }
    // Store the "other" value in the dropdown
    document.querySelector("#otherbrand").addEventListener("change", function(input){
        document.querySelector(".currentsize-select#brand").options[10].value = input.target.value;
        validateQ1();
    });

    // Allow proceed to next step when all values are selected
    document.querySelectorAll(".currentsize-select").forEach(function (select) {
        select.addEventListener("change", function(select) {
            if(select.target.id==="brand") {
                // Show or hide "other" input
                document.querySelector("#otherbrand").style.display = (select.target.selectedOptions[0].textContent === "other") ? "block" : "none";
            }
            validateQ1();
        });
    });

    document.querySelector("input#q1").addEventListener("click", function () {
        currentSize =
            document.querySelector(".currentsize-select#underband").value +
            document.querySelector(".currentsize-select#cup").value;
        originalSize = currentSize;
        currentBrand = document.querySelector(".currentsize-select#brand").value;

        currentPosition = sizes.indexOf2D(currentSize);

        positionHistory[currentStep] = currentPosition.slice();

        loadStep(2);
    });

    //Q2: Underband
    // [-1,1] [0,0] [1,-1] diagonal moves to maintain the underband-cup ratio
    document
        .querySelectorAll(".underband-carousel .carousel-tile")
        .forEach(function (tile) {
            tile.addEventListener("click", function () {
                var position = parseInt(this.dataset.shift);
                modifyPosition(position, -position);

                document
                    .querySelectorAll(".underband-carousel .carousel-tile")
                    .forEach(function (t) {
                        t.classList.remove("carousel-tile-selected");
                    });

                tile.classList.add("carousel-tile-selected");
                document.querySelector("input#q2").disabled = false;
            });
        });

    document.querySelector("input#q2").addEventListener("click", function () {
        loadStep(3);
    });

    //Q3: Straps
    //???
    document
        .querySelectorAll(".straps-carousel .carousel-tile")
        .forEach(function (tile) {
            tile.addEventListener("click", function () {
                //do something!

                document
                    .querySelectorAll(".straps-carousel .carousel-tile")
                    .forEach(function (t) {
                        t.classList.remove("carousel-tile-selected");
                    });

                tile.classList.add("carousel-tile-selected");
                document.querySelector("input#q3").disabled = false;
            });
        });

    document.querySelector("input#q3").addEventListener("click", function () {
        loadStep(4);
    });

    //Q4: Cups
    // [0,-2] [0,-1] [0,0] [0,1] [0,-2] TODO: check how to maintain ratio in case of out of bounds
    document
        .querySelectorAll(".cup-carousel .carousel-tile")
        .forEach(function (tile) {
            tile.addEventListener("click", function () {
                var position = parseInt(this.dataset.shift);
                modifyPosition(0, position);

                document
                    .querySelectorAll(".cup-carousel .carousel-tile")
                    .forEach(function (t) {
                        t.classList.remove("carousel-tile-selected");
                    });

                tile.classList.add("carousel-tile-selected");
                document.querySelector("input#q4").disabled = false;
            });
        });

    document.querySelector("input#q4").addEventListener("click", function () {
        loadStep(5);
    });

    //Q5: Centre front
    //???
    document
        .querySelectorAll(".centre-carousel .carousel-tile")
        .forEach(function (tile) {
            tile.addEventListener("click", function () {
                //do something!

                document
                    .querySelectorAll(".centre-carousel .carousel-tile")
                    .forEach(function (t) {
                        t.classList.remove("carousel-tile-selected");
                    });

                tile.classList.add("carousel-tile-selected");
                document.querySelector("input#q5").disabled = false;
            });
        });

    document.querySelector("input#q5").addEventListener("click", function () {
        loadStep(6);
    });

    //Q6: Breast shape
    //???
    document
        .querySelectorAll(".shape-list .list-tile")
        .forEach(function (tile) {
            tile.addEventListener("click", function () {
                document.querySelector(
                    "#suggestedShape"
                ).value = this.dataset.shape;

                document
                    .querySelectorAll(".shape-list .list-tile")
                    .forEach(function (t) {
                        t.classList.remove("list-tile-selected");
                    });

                tile.classList.add("list-tile-selected");
                document.querySelector("input#q6").disabled = false;
            });
        });

    document.querySelector("input#q6").addEventListener("click", function () {
        loadStep(7);

        // Send data to server
        $.ajax({
            url: Urls.BraQuizStoreValues,
            type: "POST",
            data: $.param({
                suggestedShape: document.querySelector("#suggestedShape").value,
                currentBrand: currentBrand,
                originalSize: originalSize,
                suggestedSize: getSize(),
            }),
            contentType: "application/x-www-form-urlencoded; charset=UTF-8",
            success: function (response) {
                console.log(response.status);
            },
            error: function () {
                console.log("error");
            },
        });
    });
};
