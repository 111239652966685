'use strict';

var $cache = {},
    resources = (typeof window !== "undefined" ? window['Resources'] : typeof global !== "undefined" ? global['Resources'] : null),
    quickview = require('./../quickview'),
    carousel = require('./../carousel'),
    Constants = require('./../constants'),
    util = require('./../util'),
    urls = (typeof window !== "undefined" ? window['Urls'] : typeof global !== "undefined" ? global['Urls'] : null),
    $ = (typeof window !== "undefined" ? window['jQuery'] : typeof global !== "undefined" ? global['jQuery'] : null);


function initializeCache(params) {
    $cache.form = params.form;
    $cache.message = $cache.form.find('.js-message');
    $cache.emailInput = $cache.form.find('input[name$="emailsignup_email"]');
}

function initializeEvents(params) {
    $cache.form.on('focusout','input',function(){
        if(!$cache.form.valid()){
            $cache.message.html('');
        }
    });

    $cache.form.on('submit', function (e) {
        e.preventDefault();
        if($(this).valid()){
            var url = $(this).prop('action'),
                email = $cache.emailInput.val(),
                dataParams = $(this).serialize() + '&email=' + email;

            $.ajax({
                url: url,
                data: dataParams,
                success: onSubscribeSuccess(email, params.callback),
                error: onSubscribeError
            });
        }
        return false;
    });
}

function onSubscribeSuccess(email, callback) {
    return function(response, status, jqXHR) {

        dynamicYield.callEvent('Newsletter Subscription', { email: email });

        if(callback){
            callback(response);
        } else {
            if (response.status == 'subscribed') {
                window.location.href = urls.accoutRegister+'?footer=true';
            } else if (jqXHR.status == '201') {
                var message = resources.SUBSCRIPTION_EXIST.replace('{0}', urls.accoutLogin);
                $cache.message.html(message);
            } else {
                var message = resources.CSRF_FAILED;
                $cache.message.html(message);
            }
       }
    };
}

function onSubscribeError(response) {
    $cache.message.html(resources.FOOTER_SUBSCRIPTION_ERROR);
}

var subscription = {
    init: function(params) {
        if (params.form && params.form.length) {
            initializeCache(params);
            initializeEvents(params);
        }
    }
};

module.exports = subscription;
