var page = require('./page'),
    utils = require('./components-utils'),
    componentsConfig = require('./components-config'),
    config = componentsConfig.configuration,
    references = componentsConfig.references;

/**
 * The reference getter
 * @param {name}
 * @returns {Object}
 */
function getReference(name) {
    return name && references[name] || {};
}


function getPageComponents(namespace, page) {
    var pages = getPages(namespace);
    return pages[page] && pages[page].components || {};
}

function getPages(namespace) {
    var nsConfig = getNamespaceConfig(namespace);
    return nsConfig.pages || {};
}
/**
 * Specific namespace components getter
 * @param {String} name
 * @returns {Object}
 */
function getNamespaceComponents(name) {
    var nsConfig = getNamespaceConfig(name);
    return nsConfig.components || {};
}

function getNamespaceConfig(name) {
    return name && config[name] || {};
}

/**
 * Global components configuration getter
 * @returns {Object}
 */
function getGlobalComponents() {
    return getNamespaceComponents('global');
}

/**
 * Component reference getter
 * @param {String} page
 * @param {name} name
 * @returns {Object}
 */
function getComponent(page, name) {
    var components = page && config[page] && config[page].components || {};

    if ('undefined' === typeof name) {
        return getReference(page);
    }

    return references[name] || {};
}

/**
 * Component module initization helper
 * @param {Object} module The require reference to the component module
 * @param {Object} configuration Inialization config
 * @returns {void}
 */
function initializeModule(module, configuration) {
    if (!module) {
        throw '[components]: Component module reference missed';
    }

    if ('function' !== typeof module.init) {
        throw '[components]: The mandatory "init" method missed';
    }

    utils.collectExecutionData(module.init, module, configuration || {});
}

/**
 * Component inialization helper
 * @param {String} name The key-name of the component
 * @param {Object} options Object with initalization options
 * @returns {void}
 */
function initializeComponent(name, options) {
    var component = references[name],
        configuration = $.extend(true, {}, getGlobalComponents(), getNamespaceComponents(page.ns), options);

        initializeModule(component, configuration.options || {});
        return component;
}

/**
 * Validate the component avaliability on current page
 * @param {String} name
 * @returns {void}
 */
function isComponentEnabled(name) {
    var configuration = $.extend(true, {}, getGlobalComponents(), getNamespaceComponents(page.ns));
    return 'undefined' === typeof configuration[name].enabled || !!configuration[name].enabled
}

/**
 * Current components configuration extend method. Implements
 * the possibility to customized configuration extension on brand level
 * @param {Object} componentsConfig
 * @returns {Object} this
 */
function extendConfig(componentsConfig) {
    references = $.extend(true, {}, references, componentsConfig.references || {});
    config = $.extend(true, {}, config, componentsConfig.configuration || {});
    return this;
}

function initializePage(namespace, pageName) {
    namespace = namespace || page.ns;
    var pageComponents = getPageComponents(namespace, pageName);

    for(var componentKey in pageComponents) {
        var component = getReference(componentKey);
        initializeModule(component, pageComponents[componentKey].options);
    }
}

/**
 * The main initialization method for all enabled and availiable components
 * on current page
 * @returs {void}
 */
function initializeAll(namespace, pageName, options) {
    namespace = namespace || page.ns;
    pageName = pageName || page.page;
    options = options || {};

    var currentNamespace = getReference(namespace),
        globalComponents = getGlobalComponents(),
        nsComponents = getNamespaceComponents(namespace),
        pageComponents = getPageComponents(namespace, pageName);

    var components = $.extend({}, globalComponents, nsComponents, pageComponents, options);

    try {
        initializeModule(currentNamespace, config[namespace] && config[namespace].options);
        utils.setExecutionData(namespace);
    } catch (e) {
        utils.handleExceptions(e, '"' + namespace + '" initialization failed');
    }

    for (var name in components) {
        if ('undefined' !== typeof components[name].enabled && !components[name].enabled) {
            utils.setInitStatus(name, 'disabled');
            continue;
        }

        try {
            initializeModule(references[name], components[name].options);
            utils.setInitStatus(name, 'initialized');
        } catch (e) {
            utils.handleExceptions(e, '"' + name + '" initialization failed');
            utils.setInitStatus(name, 'skipped');
        }
    }

    utils.exposeStatuses(namespace, pageName);
}

module.exports = {
    'get' : getComponent,
    'config' : config,
    'initAll' : initializeAll,
    'initComponent' : initializeComponent,
    'isComponentEnabled' : isComponentEnabled,
    'extendConfig' : extendConfig,
    'initPage': initializePage
};