'use strict'

var cookie = {
    get: function(name) {
        var parts = ('; ' + document.cookie).split('; ' + name + '=');
        if (parts.length === 2) {
            return parts.pop().split(';').shift();
        }
    },

    set: function(name, value, expires) {
        var expiresDate = (expires !== undefined) ? '; Expires=' + expires : '';
        document.cookie = name + '= ' + value + '; path=/' + expiresDate;
    },

    remove: function(name) {
        document.cookie = name + '=; path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    }
}

module.exports = cookie;
