'use strict';

var ajax = require('./../../ajax'), 
    util = require('./../../util'), 
    Constants = require('./constants');

var options = {
    productForm : Constants.CLASS_PRD_FORM,
    productNavigation : Constants.CLASS_PRD_NAVIGATION
};

function init() {
    var $pidInput = $(options.productForm + ' input[name="pid"]').last(), 
        $varGroupPid = $(options.productForm + ' input[name="varGroupPid"]'),
        $navContainer = $(options.productNavigation);

    if ($pidInput.length === 0
            || $navContainer.length === 0) {
        return;
    }
    
    var hash = '';
    if (window.location.hash.length > 1) {
        hash = window.location.hash.substr(1);
    }

    var pid = $varGroupPid.val() || $pidInput.val(),
        url = util.appendParamToURL(Urls.productNav + '?' + hash, 'pid', pid),
        qsParams = (window.location.search.length > 1) ? util.getQueryStringParams(window.location.search.substr(1)) : {};
    url = qsParams['cgid'] ? util.appendParamToURL(url, 'cgid', qsParams['cgid']) : url;

    ajax.load({
        url : url,
        target : $navContainer
    });
};

module.exports = {
    init : function(params) {
        init();
    }
};
