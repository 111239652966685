'use strict';

var util = require('./util'),
    dialog = require('./dialog'),
    customScroll = require('./custom-scrollbar'),
    JSConstants = require('./pages/checkout/constants');

var $cache = {},
    options = {
        mainContainer: '#wrapper',
        refinementOpenClass: 'js-refinements_open',
        refinementInitClass: 'js-refinements-init',
        refinementsPannel: '.js-refinements-pannel',
        positionRelative: 'm-relative',
        closeClass: 'm-close',
        niceScrollViewport: JSConstants.CLASS_SIZE_CHART_NICESCROLL_VIEWPORT
    };

function initializeCache() {
    $cache.document = $(document);
    $cache.mainContainer = $cache.document.find(options.mainContainer);
}

function initializeEvents() {
    $cache.document.on('click', '.js-refinement-tooltip', function(e) {
        e.preventDefault();
        var contentId = $(e.currentTarget).data('contentid');

        if (!contentId) {
            return;
        }

        $.ajax({
            url: Urls.pageShow,
            data: {
                format: 'ajax',
                cid: contentId,
                type: 'POST'
            }
        }).done(function(response) {
            dialog.open({
                options: {
                    dialogClass: 'b-size_guide'
                },
                callback: function() {
                    customScroll.init({
                        'container': options.niceScrollViewport
                    });
                },
                html: response
            });
        });
    })
}

module.exports = {
    init: function() {
        initializeCache();
        initializeEvents();
    }
}
