module.exports = {
    /*
     * Cart
     */
    CLASS_SHIPPING_METHOD_LIST:  '.js-shipping_method_list',
    CLASS_ORDER_TOTAL:           '.js-order_total',
    CLASS_SHIPPING_FIELDS:       '.js-shipping_fields',
    ClASS_NO_SHIPPING_METHODS:   '.js-no_shipping_methods',
    CLASS_CHECKOUTCART_BTN:      '.js-checkoutcart_btn',
    CLASS_CART_CONTENT:          '.js-cart_content',
    CLASS_CART_ADD_COUPON:       '.js-cart-addcoupon',
    CLASS_QUANTITY:              '.js_quantity',
    CLASS_QTY_CURRENT:           '.js-qty_current',
    CLASS_EDIT_DETAILS:          '.js-edit_details',
    CLASS_BONUS_ITEM:            '.js-bonus_item',
    CLASS_CHECKOUT_FORM:         '.js-checkout_form',
    CLASS_GE_SHOW_POPUP:         '.js-ge_show_popup',
    CLASS_DELIVERY_THRESHOLD:    '.js-cart-delivery-threshold',
    // selectors
    SELECTOR_COUPON_INPUT:       'input[name$="_couponCode"]',
    /*
     * PLP / Search
     */
    CLASS_SEARCH_RESULT_CONTENT: '.js-search-result-content'
};
