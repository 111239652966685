'use strict';

var $cache = {},
    progress = require('./progress'),
    layout = require('./layout'),
    util = require('./util'),
    variant = require('./pages/product/variant'),
    refinementsModel = require('./pages/search/refinements-model'),
    JSConstants = require('./constants'),
    CssConstants = require('./cssconstants'),
    $ = (typeof window !== "undefined" ? window['jQuery'] : typeof global !== "undefined" ? global['jQuery'] : null),
    sessionAttributes = (typeof window !== "undefined" ? window['SessionAttributes'] : typeof global !== "undefined" ? global['SessionAttributes'] : null),

    options = {
        refinementWrapper: CssConstants.WRAPPER,
        refinementMobileWrapperClass: JSConstants.CLASS_REFINEMENT_OPEN.substr(1),
        refinementValuesSwitcher: JSConstants.CLASS_ACCORDION_SWITCHER.substr(1),
        refinementButtonsClass: JSConstants.CLASS_REFINEMENT_BUTTONS_MOBILE.substr(1),
        refinementAccordion: JSConstants.CLASS_ACCORDION_ITEM.substr(1),
        refinementHeaderMobile: JSConstants.CLASS_REFINEMENT_HEADER_MOBILE.substr(1),
        refinementCheckbox: JSConstants.CLASS_CHECKBOX,
        refinementSelectedValueClass: CssConstants.M_SELECTED,
        refinementUnselectableValueClass: CssConstants.M_UNSELECTABLE,
        refineByClass: CssConstants.M_REFINE_BY,

        refinementBreadcrumbsLinks: JSConstants.CLASS_REFINEMENT_VALUE + ' a',

        refinementCloseButtonClass: CssConstants.B_REFINEMENTS_CLOSE_MOBILE,         // do not change
        refinementDoneButtonClass: CssConstants.B_REFINEMENTS_APPLY_MOBILE,          // do not change
        refinementClearallButtonClass: CssConstants.B_REFINEMENTS_CLEARALL_MOBILE,   // do not change
        refinementFullShreenMobile: CssConstants.B_REFINEMENTS_FULLSCREEN_MOBILE,    // do not change
        refinementMobileButtons: CssConstants.B_REFINEMENTS_BUTTONS_MOBILE
    },

    refinementsToApply,
    currentRefinementsSelectedValues = [],

    containerSelector = CssConstants.MAIN,
    selectedClass = CssConstants.M_SELECTED,
    showRefinementsClass = JSConstants.CLASS_REFINEMENTS_SHOW.substr(1),
    clearallButtonJsClass = JSConstants.CLASS_REFINEMENT_CLEARALL.substr(1),
    clearOneButtonClass = JSConstants.CLASS_REFINEMENT_CLAERONE.substr(1),
    closeButtonClass = JSConstants.CLASS_REFINEMENTS_BACK.substr(1),
    refinementHeadersClass = JSConstants.CLASS_REFINEMENT_HEADER.substr(1),
    refinementAjaxLinksClass = JSConstants.CLASS_REFINEMENTS_LINK.substr(1),
    refinementContainer = JSConstants.CLASS_REFINEMENT_CONTAINER,
    refinement = JSConstants.CLASS_REFINEMENT_ITEM,
    refinementItem = JSConstants.CLASS_REFINEMENT_LINK,

    priceRefinementName = 'price',

    priceParamMin = Constants.PRICE_MIN_PARAMETER,
    priceParamMax = Constants.PRICE_MAX_PARAMETER,

    currencySymbol = sessionAttributes.CURRENCY_SYMBOL,
    header = JSConstants.CLASS_STICKY_HEADER;

function initializeConfig(params) {
    $.extend(options, params || {});
}

function initializeCache() {
    $cache.document = $(document);
    $cache.location = $(location);
    $cache.container = $(containerSelector);
    $cache.refinementWrapper = $(options.refinementWrapper);
    $cache.refinementHeaderMobile = $(options.refinementHeaderMobile);
}

function initializeEvents() {
    $cache.document.on('search.updated', function() {
        initializeCache();
    });

    $cache.container.on('click', options.refinementBreadcrumbsLinks, ajaxReloadHandler);

    $cache.container.off('click', '.' + refinementAjaxLinksClass);
    $cache.container.on('click', '.' + refinementAjaxLinksClass, ajaxReloadHandler);
    $cache.container.on('change', '#js-selected-size', ajaxReloadHandler);

    $cache.document.on('window.modechanged', function() {
        initializeMobile();
    });

    $('.b-refinements .js-show-more').click(function(e) {
        e.preventDefault();
        var $self = $(this);
        $('.swatch-hidden', $self.parent().parent()).addClass('swatch-shown');
        $('.swatch-hidden', $self.parent().parent()).removeClass('swatch-hidden');
        $self.hide();
        $('.js-show-less', $self.parent()).show();
    });

    $('.b-refinements .js-show-less').click(function(e) {
        e.preventDefault();
        var $self = $(this);
        $('.swatch-shown', $self.parent().parent()).addClass('swatch-hidden');
        $('.swatch-shown', $self.parent().parent()).removeClass('swatch-shown');
        $self.hide();
        $('.js-show-more', $self.parent()).show();
    });
}

function initializeMobile() {
    if(!layout.isDesktop()) {

        initializeMobileEvents();

        refinementsToApply = refinementsModel.init({
            'url': window.location.search,
            'priceMin': priceParamMin,
            'priceMax': priceParamMax,
            'refineName': Constants.REFINE_NAME_PARAMETER_PREFIX,
            'refineValue': Constants.REFINE_VALUE_PARAMETER_PREFIX,
            'priceRefinementName': priceRefinementName,

            'categoryID': Constants.CATEGORYID_PARAMETER,
            'searchPhrase': Constants.SEARCH_PHRASE_PARAMETER,
            'promotionID': Constants.PROMOTIONID_PARAMETER,
            'sortBy': Constants.SORT_BY_PARAMETER_PREFIX,
            'sortDirection': Constants.SORT_DIRECTION_PARAMETER_PREFIX,
            'sortRule': Constants.SORTING_RULE_PARAMETER,
            'pagingSize': Constants.PAGING_SIZE_PARAMETER,
            'pagingStart': Constants.PAGING_START_PARAMETER
        });

        $cache.refinementWrapper.removeClass(options.refinementMobileWrapperClass);

    }
}

function initializeDesktop() {
    if(layout.isDesktop()) {
        $cache.refinementWrapper.addClass(options.refinementMobileWrapperClass);
    }
}

function initializeMobileEvents() {
    var closeBtn = '.' + closeButtonClass,
        clearOneBtn = '.' + clearOneButtonClass,
        clearAllBtn = '.' + clearallButtonJsClass;

    $cache.container.on('click', '.' + showRefinementsClass, openRefinementsMenu);

    $cache.container.on('click', closeBtn, closeRefinementsMenu);

    $cache.container.on('click', clearOneBtn, clearValuesOfCurrentRefinement);

    $cache.container.off('click', '.' + refinementHeadersClass);
    $cache.container.on('click', '.' + refinementHeadersClass, openRefinementValuesMenu);

    $cache.container.on('click', clearAllBtn, clearAllRefinements);

    $cache.document.on('search.updated', searchUpdatedMobileHandler);
}

function selectValue(e) {
    e.preventDefault();
    var $this = $(this),
        $refinements = $(refinement),
        $parent = $this.parents(refinementContainer),
        isSelectable = !$this.parent().hasClass(options.refinementUnselectableValueClass),
        name = refinementsToApply.getCurrentRefinement(),
        isMultiselect = $parent.data('multiselect'),
        value;

    if (!isSelectable) {
        return;
    }

    if(name == priceRefinementName) {
        value = [ $this.data('value_from'), $this.data('value_to') ];
    } else {
        value = $this.data('value');
    }

    // note: toggling class of a parent is required for bra sizes table
    if(!isMultiselect) {
        var ifSelected = $this.parent().hasClass(selectedClass);

        $refinements.removeClass(selectedClass);
        $parent.find('.' + refinementAjaxLinksClass).removeClass(selectedClass);

        if(!ifSelected){
            $this.parent().addClass(selectedClass);
            $this.addClass(selectedClass);
        }
        if (value.length > 1 && currentRefinementsSelectedValues.length && ~currentRefinementsSelectedValues[0].indexOf(value[0]) && ~currentRefinementsSelectedValues[0].indexOf(value[1])) {
            currentRefinementsSelectedValues = [];
        } else {
            currentRefinementsSelectedValues = [];
            currentRefinementsSelectedValues.push(value);
        }
    } else {
        var index = currentRefinementsSelectedValues.indexOf(value);

        if (index === -1) {
            currentRefinementsSelectedValues.push(value);
        } else {
            currentRefinementsSelectedValues.splice(currentRefinementsSelectedValues, 1);
        }
        $this.toggleClass(selectedClass);
        $this.parent().toggleClass(selectedClass);
    }
}

function addValueToCurrentRefinement(value) {

    var refinement = refinementsToApply.getCurrentValues();

    if(!refinement) {
        refinementsToApply.newCurrentRefinementValue();
        refinement = refinementsToApply.getCurrentValues();
    }

    refinementsToApply.toggleCurrentRefinementValue(value);
}

function clearValuesOfCurrentRefinement() {
    var $this = $(this);
    var currentRefinement = refinementsToApply.getCurrentRefinement();

    refinementsToApply.clearCurrentRefinement();

    $this.removeClass(selectedClass);
    $this.parent().removeClass(selectedClass);
    refreshSelectedRefinements(currentRefinement);
}

function openRefinementValuesMenu(e) {
    if($(e.target).hasClass(JSConstants.CLASS_REFINEMENT_TOOLTIP.substr(1))) {
        return;
    }

    var $this = $(this);
    var $content = $this.closest('.g-accordion-title').siblings('.g-accordion-content');
    var $parent = $content.find(refinementContainer);

    var currentRefinement = $parent.find('a:not(.' + options.refinementUnselectableValueClass + ')').first().data('name');
    refinementsToApply.setCurrentRefinement(currentRefinement);
    refinementsToApply.saveCurrentValues();

    addRefineByInHeader($this);
}

function clearAllRefinements() {
    refinementsToApply.clearAllRefinements();
    applyRefinements();
}

function setCustomPrice(e) {
    var $this = $(this);
    var $priceInputs = $cache.customPriceForm.find('input');

    var priceMin = $priceInputs.first().val(),
        priceMax = $priceInputs.last().val();
    refinementsToApply.toggleCurrentRefinementValue([ priceMin, priceMax ]);

    refreshCloseDoneButtons($this.parents('ul').first());
}

function searchUpdatedMobileHandler() {
    refinementsToApply.constructFromUrl(window.location.search);
}

function ajaxReloadHandler(e) {
    e.preventDefault();

    // don't intercept for category and folder refinements, as well as unselectable
    var $this = $(this);
    var categoryRefinementLength = $this.parents('.category-refinement').length,
        folderRefinementLength = $this.parents('.folder-refinement').length,
        isSelectable = !$this.parent().hasClass(options.refinementUnselectableValueClass);
    var refinementsScrollToBlock = $(JSConstants.CLASS_REFINEMENT_SCROLL);
    var refinementsScrollToPosition = refinementsScrollToBlock.offset().top + refinementsScrollToBlock.height() - $(header).height();
    var bodyScrollPosition = $('body').scrollTop();
    var menuScrollPosition = $('.js-refinement_wrapper').scrollTop();
    if (categoryRefinementLength > 0 || folderRefinementLength > 0 || !isSelectable) {
        return;
    }

    if (menuScrollPosition > 0) {
        $cache.container.trigger('search.update', {
            'targetURL': this.href,
            'scroller' : {
                element: $('.js-refinement_wrapper'),
                position: menuScrollPosition,
                speed: 0
            }
        });
    }
    else {
        $cache.container.trigger('search.update', {
            'targetURL': this.href
        });
    }

    if(bodyScrollPosition > refinementsScrollToPosition) {
        util.scrollBrowser(refinementsScrollToPosition, 700);
    }

    refreshShowLessMore();
}

/**
 * Add into header of refinement block selected refinement values
 * @param {String} name Refinement name
 */
function refreshSelectedRefinements(name) {
    var $div = $('.' + name);
    var $header = $div.find('.' + refinementHeadersClass);
    $header.find('.' + options.refinementSelectedValueClass).remove();
    var text = $header.html();
    $header.html(text);
    var pref = refinementsToApply.get(name);
    if(!pref) {
        $div.find(refinementItem).removeClass(selectedClass);
        $header.removeClass(selectedClass);
        return;
    }
    var len = pref.length;
    var $toAppend = $(document.createDocumentFragment());
    for(var i = 0; i < len; i++) {
        var html = ' ';

        if(name == priceRefinementName) {
            var min = window.pricerefinementValueMap ? window.pricerefinementValueMap[pref[0]] : pref[0],
                max = window.pricerefinementValueMap ? window.pricerefinementValueMap[pref[1]] : pref[1],
                needCurrency = window.pricerefinementValueMap ? false : true;
            html += (needCurrency ? currencySymbol : '') + min + ' - ' +  (needCurrency ? currencySymbol : '') + max;
            i++;
        } else {
            html += pref[i];
        }

        var $value = $('<span>', {
            'class': options.refinementSelectedValueClass,
            'html': html
        });
        $toAppend.append($value);
    }
    $header.append($toAppend);
    $header.addClass(selectedClass);
    variant.init();
}

/**
 * Adds name of selected refinement into header in refinement values menu
 * @param {Object} $this
 */
function addRefineByInHeader($this) {
    var refineName = $this.clone().children().remove().end().text().replace(':','');

    $('.' + options.refineByClass).remove();

    var span = $('<span>', {
        'class': options.refineByClass,
        'html': refineName
    });
    $cache.refinementHeaderMobile.append(span);
}

function openRefinementsMenu() {
    $('.' + options.refinementValuesSwitcher).attr('checked', 'checked');
    $cache.refinementWrapper.addClass(options.refinementMobileWrapperClass);

    //toggleApplyButtons();
    var prefs = refinementsToApply.getPrefs();
    for(var p in prefs) {
        refreshSelectedRefinements(p);
    }

    $('.' + options.refineByClass).remove();

    refinementsToApply.removeCurrentRefinement();

    $('body').trigger('layout.lock');
}
function closeRefinementsMenu() {
    $cache.refinementWrapper.removeClass(options.refinementMobileWrapperClass);
    $('body').trigger('layout.unlock');

    // Reload events after filtering tiles
    variant.init();
    // Once the tiles are loaded, check if include different images for sizing and then show the main filter
    if ($('.js-product-images_switch .b-product_tile-size_switches').length > 0) {
        $('.b-plp_header_right .b-product_tile-size_switches').css('visibility', 'visible');
    }
}
/**
 * Applies selected refinements by using AJAX call
 */
function applyRefinements() {
    var objectForUrl = refinementsToApply.composeRefinementsForUrl();
    objectForUrl[Constants.PAGING_START_PARAMETER] = 0;

    var url = util.appendParamsToUrl($cache.location.attr('origin') + $cache.location.attr('pathname'), objectForUrl);

    var $refValues = $('.' + options.refinementValuesSwitcher).attr('checked', 'checked');
    if($refValues.length == 0) {
        progress.show($cache.refinementWrapper);
    } else {
        progress.show($refValues);
    }

    $cache.container.load(util.ajaxUrl(url), function(response) {
        history.pushState(undefined, '', url);
        progress.hide();

        variant.init();
    });
}

function refreshShowLessMore() {
    $('.b-refinements .js-show-more').click(function(e) {
        e.preventDefault();
        var $self = $(this);
        $('.swatch-hidden', $self.parent().parent()).addClass('swatch-shown');
        $('.swatch-hidden', $self.parent().parent()).removeClass('swatch-hidden');
        $self.hide();
        $('.js-show-less', $self.parent()).show();
    });

    $('.b-refinements .js-show-less').click(function(e) {
        e.preventDefault();
        var $self = $(this);
        $('.swatch-shown', $self.parent().parent()).addClass('swatch-hidden');
        $('.swatch-shown', $self.parent().parent()).removeClass('swatch-shown');
        $self.hide();
        $('.js-show-more', $self.parent()).show();
    });
}

var refinements = {
    init: function(params) {
        initializeConfig(params);
        initializeCache();
        initializeEvents();
        initializeMobile();
        initializeDesktop();
    },
    refresh: function() { refreshShowLessMore(); }
};

module.exports = refinements;
