module.exports = {
    /*
     * Product Container
     */
    CLASS_PRD_CONTAINER: '.js-product_container',
    CLASS_PRD_SET_CONTAINER: '.js-product-set-item',
    CLASS_PRD_CONTENT: '.js-product_content',
    CLASS_PRD_FORM: '.js-product_form',
    CLASS_PRD_SIZE_CONTAINER: '.js-size',
    CLASS_PRD_COLOR_CONTAINER: '.js-color',
    CLASS_PRD_MAIN_CONTAINER: '#main',
    CLASS_PRD_VARIATIONS_CONTAINER: '.l-pdp-variations',
    /*
     * Product Images
     */
    CLASS_PRD_IMAGE: '.js-product-image',
    CLASS_PRD_IMAGES_PDP: '.js-product-images_pdp',
    CLASS_PRD_IMAGES_CONTAINER: '.js-product-images_container',
    CLASS_PRD_IMAGES_MAIN: '.js-product-images_main',
    CLASS_PRD_IMAGES_ZOOMED: '.js-zoomed_image_container',
    CLASS_PRD_IMAGES_SIZE: '.js-product-images_size',
    /*
     * Product Size Switcher
     */
    CLASS_PRD_IMAGES_SWITCH: '.js-product-images_switch',
    CLASS_PRD_IMAGES_SWITCH_AD: '.js-size_ad',
    CLASS_PRD_IMAGES_SWITCH_DDG: '.js-size_ddg',
    /*
     * Bra size grid
     */
    CLASS_PRD_BRA_SIZE_GRID_FIRST: '.js-bra_size_grid_first',
    CLASS_PRD_BRA_SIZE_GRID_SECOND: '.js-bra_size_grid_second',
    CLASS_PRD_BRA_SIZE_GRID_FIRST_PDP: '.js-pdp-bra_size_grid_first',
    CLASS_PRD_BRA_SIZE_GRID_SECOND_PDP: '.js-pdp-bra_size_grid_second',
    /*
     * Product Images Options
     */
    CLASS_PRD_IMAGES_ZOOM: 'js-zoom',
    CLASS_PRD_IMAGES_SWAP: 'js-swap',
    CLASS_PRD_IMAGES_VIEWTYPE: 'js-viewtype',
    CLASS_PRD_IMAGES_SLIDER: 'js-slider',
    CLASS_PRD_IMAGES_THUMB: 'js-thumb',
    CLASS_PRD_IMAGES_CLICK: 'js-click',
    /*
     * Product Thumbs
     */
    CLASS_PRD_THUMBS: '.js-product-images_thumbs',
    CLASS_PRD_THUMB: '.js-product-images_thumb',
    CLASS_PRD_THUMB_ANCHOR: '.js-product-images_thumb_anchor',
    CLASS_PRD_THUMB_CAROUSEL: '.js-thumbnails-carousel',
    /*
     * Product Navigation
     */
    CLASS_PRD_NAVIGATION: '.js-product_nav_container',
    CLASS_PRD_NAME : ".js-product-name",
    /*
     * Product Swatches
     */
    CLASS_PRD_SWATCH_ANCHOR: '.js-product-swatch_anchor',
    CLASS_PRD_SWATCH_ANCHOR_COLOR: '.js-product-swatch_anchor_color',
    CLASS_PRD_SWATCH_SELECT: '.js-product-swatch_select',
    CLASS_PRD_SWATCH_UNSELECTABLE: '.m-unselectable',
    CLASS_PRD_SET_SWATCH_ANCHOR: '.js-product-set-item .js-product-swatch_anchor',
    /*
     * Product Quantity
     */
    CLASS_PRD_QUANTITY_FIELD: '.js-qty_current',
    CLASS_PRD_QUANTITY_DECREASE: '.js-decr',
    CLASS_PRD_QUANTITY_INCREASE: '.js-inc',
    CLASS_PRD_QUANTITY_CMP: '.js-quantity-cmp',
    /*
     * Product View Type Switcher
     */
    CLASS_PRD_SWITCH: '.js-switcher',
    /*
     * Product View Type Options
     */
    CLASS_PRD_SWITCH_FRONT: 'js-switch_front',
    CLASS_PRD_SWITCH_BACK: 'js-switch_back',
    /*
     * Product Images Popup
     */
    CLASS_PRD_IMAGES_POPUP_HIDDEN: '.js-hidden-images-popup',
    CLASS_PRD_IMAGES_POPUP_BODY: '.js-body',
    CLASS_PRD_IMAGES_POPUP_CLOSE: '.js-close-images-popup'
};
