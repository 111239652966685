'use strict';

require('slick-carousel');

var $ = (typeof window !== "undefined" ? window['jQuery'] : typeof global !== "undefined" ? global['jQuery'] : null),
    initialized = false,
    options = require('./carousel-config'),
    events = ['afterChange', 'beforeChange', 'breakpoint', 'destroy', 'edge', 'init', 'reInit', 'setPosition', 'swipe', 'lazyLoaded', 'lazyLoadError'],
    layout = require('./layout');

function initializeParams (params) {
    options = $.extend(true, {}, options, params);
}

function initSlick () {
    var $carousel = $(this);
    var carouselOptions = $carousel.data('slick');

    if (typeof carouselOptions === "string") {
        carouselOptions = require('./carousel-' + carouselOptions + '-config');
    }
    carouselOptions = $.extend({}, options, carouselOptions);
    if (carouselOptions.events) {
        for (var i in events) {
            if (carouselOptions.events[events[i]]) {
                $carousel.on(events[i], carouselOptions.events[events[i]], this);
            }
        }
    }
    $carousel.not('.slick-initialized').slick(carouselOptions);
    setSameHeight({target: $carousel});
    /*
     * Allows closable slides
     */
    $carousel.find('.js-closebutton').on('click', function(event) {
        event.preventDefault();
        var currentIndex;

        if (layout.isMobile()) {
            currentIndex = $(this).closest('.slick-slide').data('slick-index');
        } else {
            currentIndex = $(this).closest('.slick-slide').index();
        }
        $carousel.slick('slickRemove', currentIndex);
        decrementSlides($carousel);
    });
    setPositionForArrow();
    initEvents();

    $carousel.parent().removeClass('is--hidden');
}

function decrementSlides ($carousel) {
    $carousel.slick("slickSetOption", "slidesToShow",
            $carousel.slick("slickGetOption", "slidesToShow") - 1, true);
}

function initAllCarousels () {
    $(this)
        .find(options.baseClass)
        .each(initSlick);
}

function initCarousel(container, callback){
    if (!container) {
        return;
    }
    if (callback){
        $(container).not('.slick-initialized').on('init', callback);
    }
    initSlick.call(container);
}

function setPositionForArrow () {
    var imageHeight = $(".js-carousel .b-product_tile-thumb_image:visible").eq(0).height();
    var arrowHeight = $(".js-carousel .slick-arrow").eq(0).height();
    $(".slick-arrow").css("top", ((imageHeight/2) - (arrowHeight/2)) + "px");
}

function setSameHeight(ev) {
    var $carousel = $(ev.target), $sameHeight = null;
    var maxHeight = null;
    var sameHeightSelector = $carousel.data('sameHeigth');

    if (sameHeightSelector && $(window).width() >= 768) {
        $sameHeight = $carousel.find(sameHeightSelector);
        $sameHeight.each(function() {
            if (maxHeight === null) {
                maxHeight = $(this).innerHeight();
            }
            else {
                maxHeight = $(this).innerHeight() > maxHeight ? $(this).innerHeight() : maxHeight;
            }
        });
    }
    if (null !== maxHeight && null !== $sameHeight) {
        $sameHeight.each(function() {
            $(this).height(maxHeight).addClass('fix_height');
        });
    }
}

function initEvents() {
    $('.js-carousel').on('setPosition', function(ev){
        setPositionForArrow();
    });
}

module.exports = {
    init: function(params) {
        if (initialized) return;
        initializeParams(params);
        initAllCarousels.call(params.container || document);
        initialized = true;
    },
    initCarousel: function(element, callback){
        initCarousel(element, callback);
        setPositionForArrow();
        initEvents();
    },
    initCarousels: function(elements, callback){
        $(elements).each(function () {
            if (!$(this).hasClass('slick-initialized')) {
                initCarousel(this, callback);
            }
        });
    }
}
