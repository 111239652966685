const sitePreferences = (typeof window !== "undefined" ? window['SitePreferences'] : typeof global !== "undefined" ? global['SitePreferences'] : null);
const Loader = require('google-maps').Loader;

let googleMapsLoader = null;
function getGoogleMapsLoader() {
    if (googleMapsLoader) {
        return googleMapsLoader;
    }

    if (!sitePreferences.GOOGLE_MAPS_KEY) {
        return;
    }

    googleMapsLoader = new Loader(sitePreferences.GOOGLE_MAPS_KEY, {
        libraries: ['geometry', 'places']
    });

    return googleMapsLoader;
}

module.exports = {
    getGoogleMapsLoader: getGoogleMapsLoader
}
