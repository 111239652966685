'use strict';

var $ = (typeof window !== "undefined" ? window['jQuery'] : typeof global !== "undefined" ? global['jQuery'] : null),
    $cache = {},
    Urls = (typeof window !== "undefined" ? window['Urls'] : typeof global !== "undefined" ? global['Urls'] : null),
    util = require('./util'),
    CssConstants = require('./cssconstants'),
    Constants = require('./constants');

var $cache = {},
    options = {
        selectboxCountry: Constants.CLASS_COUNTRIES_SELECTBOX,
        currentCountry: Constants.CLASS_COUNTRIES_CURRENT,
        countries: Constants.CLASS_COUNTRIES
    };

function initializeCache() {
    $cache.document = $(document);
};

function initializeEvents($container) {
    var selectbox = $container.find(options.selectboxCountry),
        country = $container.find(options.currentCountry);
    
    function closeSelectBox() {
        selectbox.removeClass(CssConstants.M_SHOW);
    }
    
    country.on('click', function() {
        selectbox.toggleClass(CssConstants.M_SHOW);
    });
    
    selectbox.on( 'click', 'li', function() {
        closeSelectBox();
        setCurrencyAndGoToURL($(this).data('currency'));
    });
    
    selectbox.add(country).on('mouseleave', function() {
        $cache.document.one( 'click', closeSelectBox);
    }).on('mouseenter', function() {
        $cache.document.off( 'click', closeSelectBox);
    });
    
}

function setCurrencyAndGoToURL(currency) {
    $.ajax({
        dataType: 'json',
        url: Urls.setSessionCurrency,
        data: {
            format: 'ajax',
            currencyMnemonic: currency
        }
    })
    .done(function (response) {
        if (!response.success) {
            throw new Error('Unable to set currency');
        }
        $cache.document.trigger('removestore');
        
        var url = util.removeParamFromURL(location.href, 'currency');
        if (url === location.href)
            location.reload();
        else
        	location.href = url;
    });
}

function initialize() {
    $(options.countries).each(function () {
        initializeEvents($(this));
    });
}

exports.init = function () {
    initializeCache();
    initialize();
};