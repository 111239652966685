'use strict';

var util = require('./../../util'),
    $ = (typeof window !== "undefined" ? window['jQuery'] : typeof global !== "undefined" ? global['jQuery'] : null),
    urls = (typeof window !== "undefined" ? window['Urls'] : typeof global !== "undefined" ? global['Urls'] : null),
    formPrepare = require('./../../../../../app_storefront_core/cartridge/js/pages/checkout/formPrepare'),
    resources = (typeof window !== "undefined" ? window['Resources'] : typeof global !== "undefined" ? global['Resources'] : null),
    dialog = require('./../../dialog/index'),
    ajax = require('./../../ajax');

var $cache = {},
    initialized = false;

function showSendVipCardPopup() {
    if (!initialized) {
        return;
    }

    dialog.open({
        url: util.ajaxUrl(urls.vipCardShow),
        callback: function() {
            dialog.$container.find('.js-close_popup-btn').click(function(e) {
                e.preventDefault();
                dialog.close();
            });

            dialog.$container.find('.js-send_me_card').click(function(e) {
                e.preventDefault();
                showDestinationPopup();
            });
        },
        options: {width: 535}
    });
}

function showDestinationPopup() {
    dialog.open({
        url: util.ajaxUrl(urls.vipCardDestinationShow),
        callback: function() {
            dialog.$container.find('.js-send_withorder').click(function(e) {
                e.preventDefault();
                $.ajax({
                    type: 'get',
                    url: urls.vipCardSend
                });
                dialog.close();
            });

            dialog.$container.find('.js-send_separate').click(function(e) {
                e.preventDefault();
                showAddressPopup();
            });
        },
        options: {width: 535}
    });
}

function showAddressPopup() {
    dialog.open({
        url: util.ajaxUrl(urls.vipCardAddress),
        callback: onAddressPopupOpen,
        options: {width: 535}
    });
}

function onAddressPopupOpen() {
    formPrepare.init({
        continueSelector: '[name$="vipcardaddress_send"]',
        formSelector:'[id$="vipcardaddress"]'
    });

    $(document).on('address.populated', function() {
        dialog.$container.find("[id$=postal]").trigger('change');
    });

    dialog.$container.find('.js-vipcard_address-form').submit(function(e) {
        var $self = $(this);
        e.preventDefault();

        $.ajax({
            type: $self.attr('method'),
            url: util.ajaxUrl($self.attr('action')),
            data: $self.serialize(),
            success: function (data) {
                dialog.close();
            },
            error: function (data) {
                dialog.openWithContent({html: data.responseText, callback: onAddressPopupOpen});
            },
        });
    });
}

var vipcard = {
    init : function (params) {
        if (params['countryCode'] == Constants.VIPCARD_ALLOWED_COUNTRY_CODE) {
            initialized = true;
        }
    },
    showSendVipCardPopup: showSendVipCardPopup
};

module.exports = vipcard;
