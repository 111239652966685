'use strict';

function initProductTileEvents() {
    if (!window.$) {
        return;
    }

    $(document).on('change', '.js-tile-size-dropdown', function() {
        var size = this.selectedOptions[0].text;
        var f = $('form', $(this).parent().parent().parent());
        var pid = $('[name="pid"]', f);
        if (pid.length === 0 || pid.val() === "null") {
            var pid = $('[name="varGroupPid"]', f);
        }

        var oldVal = pid.val();
        var newval = pid.val().substr(0, 11) + size;
        pid.val(newval);

        var datalayerInfoElem = f.find('#datalayer_info_product_' + oldVal);
        var variantData = datalayerInfoElem.data('datalayerProductInfoJson');
        if (variantData) {
            variantData.item_id = newval;
            variantData.size = size;

            datalayerInfoElem.attr('id', 'datalayer_info_product_' + newval);
            datalayerInfoElem.data('datalayerProductInfoJson', variantData);
        }

        var addButton = $('.js-add-to-cart', f);
        addButton.prop('disabled', false);
    });

    $(document).on('click', '.dy-tracked-product .js-add-to-cart', function() {
        dynamicYield.callEvent('Add to Cart', {
            productId: $('#pid', $(this).parent()).val(),
            quantity: 1
        });
    });

    $(document).on('click', '.dy-tracked-product .b-product_tile-thumb_image, .dy-tracked-product .b-product_tile-image, .dy-tracked-product .b-product_swatch, .dy-tracked-product .js-product-name', function() {
        var $this = $(this);

        var parent = null;
        if ($this.hasClass('b-product_tile-thumb_image')) {
            parent = $this.closest('.b-product_carousel-slide')[0];
        }

        if ($this.hasClass('js-product-name') || $this.hasClass('b-product_tile-image')) {
            parent = $this.closest('.b-product_carousel-slide')[0] || $this.closest('.b-product_list-tile')[0];
        }

        if ($this.hasClass('b-product_swatch')) {
            if ($this.closest('.b-product_carousel-slide')[0] || $this.closest('.b-product_list-tile')[0]) {
                parent = $this.closest('.b-product_carousel-slide')[0] || $this.closest('.b-product_list-tile')[0]
            } else if ($this.parents('#pdpMain').length) {
                parent = $this.parents('#pdpMain')[0];
            }
        }

        if (!parent) {
            return;
        }

        var elem = parent.querySelector('[data-itemid]');
        var pid = $(elem).data('itemid');
        dynamicYield.callEvent('Click-through', {
            origin: document.URL,
            productId: pid
        });
    });
}

function initSearchEvents() {
    $(document).on('click','.js-refinement-link', function() {
        if(!$(this).hasClass('m-selected') && !$(this).hasClass('b-refinements-clear_link')){
            var data = $(this).data('filter');
            dynamicYield.callEvent('Filter Items', data);
        }
    });

    $(document).on('click','.js-add-to-wishlist', function(e) {
        e.preventDefault();

        var DYhref = $(this).attr('href');
        var productId = $(this).data('pid');
        dynamicYield.callEvent('Add to Wishlist', {productId: productId, DYhref: DYhref});
    });

    var searchPhrase = $('#js-dynamic-yield-info').data('search-phrase');
    if (searchPhrase) {
        dynamicYield.callEvent('Keyword Search', {keywords: searchPhrase});
    }
}

function initProductDetailPageEvents() {
    if ($('.js-product-set-item').length > 0) {

        $('.js-product-set-item').each(function() {
            var jsQuickviewURL = $(this).find('button.js-quickview')[0].getAttribute('data-url-quickview');
            dynamicYield.callSPAEvent({
                context: {
                    type: "PRODUCT",
                    data: [$(this).find('span[itemprop="productID"]').text()]
                },
                url: jsQuickviewURL,
                countAsPageview: true
            });

            $(this).on('click','.js-add-to-wishlist, a[data-action=wishlist]',function(e){
                e.preventDefault();
                var DYhref = $(this).attr('href');
                var productId = $(this).parentsUntil('.js-product-set-item').find('span[itemprop="productID"]').text() || window.pageContext.productID;
                dynamicYield.callEvent('Add to Wishlist', {
                    productId: productId,
                    DYhref: DYhref
                });
            });
        });

    } else {
        $(document).on('click','.js-add-to-wishlist, a[data-action=wishlist]',function(e){
            e.preventDefault();
            var DYhref = $(this).attr('href');
            var productId = $(this).data('pid') || window.pageContext.productID;
            dynamicYield.callEvent('Add to Wishlist', {
                productId: productId,
                DYhref: DYhref
            });
        });
    }
}

function initCartEvents() {
    $("[name$='_deleteProduct']").on('click', function(e) {
        var thisName = $(this).attr('name');
        $(this).append('<input type="hidden" value="Remove" name="'+thisName+'">');
        var DYform = $(this).closest('form');
        var line = $(this).closest( ".cart-row" );
        var quantity = line.data("quantity");
        var productId = line.data("itemid");
        dynamicYield.callEvent('setRemovedItem', {
            productId: productId,
            quantity: quantity
        });
    });

    $('.g-button-wishlist').on('click', function(e){
        e.preventDefault();
        var line = $(this).closest( ".cart-row" );
        var DYhref = $(this).attr("href");
        var productId = line.data("itemid");
        dynamicYield.callEvent('Add to Wishlist', {
            productId: productId,
            DYhref: DYhref
        });
    });
}

function initializeEvents() {
    $(document).ready(function() {
        initProductTileEvents();

        if (window.pageContext.ns === 'search') {
            initSearchEvents();

            return;
        }

        if (window.pageContext.ns === 'product') {
            initProductDetailPageEvents();

            return;
        }

        if (window.pageContext.ns === 'cart') {
            initCartEvents();

            return;
        }
    });
}

exports.init = function () {
    initializeEvents();
};
