'use strict'

var $ = (typeof window !== "undefined" ? window['jQuery'] : typeof global !== "undefined" ? global['jQuery'] : null),
    urls = (typeof window !== "undefined" ? window['Urls'] : typeof global !== "undefined" ? global['Urls'] : null),
    cookie = require('./../../cookie'),
    options = {
        annualDeliveryPass : '.js-add-to-cart_annual_delivery_pass',
        annualDeliveryPassLogin : '.js-login_annual_delivery_pass',
        annualDeliveryLoginForm : '.js-annual-delivery_login-form',
        annualDeliveryDialog : '.js-annual-delivery_dialog_terms',
        annualDeliveryErrorAdd : '.js-annual-delivery__error-msg-add',
        annualDeliveryErrorCheck : '.js-annual-delivery__error-msg-check',
        annualDeliveryCheckbox: '.js-adp-checkbox',
        toggle : '.js-adp-toggle',
        toggleIcon : '.b-annual-delivery__toggle-icon',
        toggleClass : 'is-active',
        content : '.js-adp-content',
        radioBtn : 'input[name="adp-radio-btn"]'
    },
    EXPANDMINICART_COOKIE = 'expandminicart',
    EXPANDMINICART_COOKIE_DURATION_MIN = 5,
    $cache = {},
    dialog = require('./../../dialog'),
    page = require('./../../page'),
    addingAnnualDeliveryPassToCart = false;

function initializeConfig(params) {
    $.extend(options, params || {});
}

function cacheInit(){
    $cache.document = $(document);
}

function initEvents(){
    $cache.document.on('click', options.toggle, toggleAnnualDeliveryPass);
    $cache.document.on('click', options.annualDeliveryPass, addAnnualDeliveryPass);
    $cache.document.on('click', options.annualDeliveryPassLogin, loginAnnualDeliveryPass);
    $cache.document.on('click', options.annualDeliveryDialog, dialogAnnualDeliveryPass);
}

function toggleAnnualDeliveryPass(e) {
    var $actionButton = $(this);

    var $radioBtn = $actionButton.find(options.radioBtn);
    if ($radioBtn.prop('checked')) {
        $radioBtn.prop('checked', false);
    } else {
        $radioBtn.prop('checked', true);
    }

    $actionButton.siblings(options.content).toggle();
}

function addAnnualDeliveryPass(e) {
    var $actionButton = $(this);
    if (User.authenticated) {
        var annualDeliveryCheckbox = $(options.annualDeliveryCheckbox);
        $(options.annualDeliveryErrorCheck).hide();
        $(options.annualDeliveryErrorAdd).hide();
        if (annualDeliveryCheckbox.length > 0) {
            if (annualDeliveryCheckbox.prop('checked')) {
                $(options.annualDeliveryErrorCheck).hide();
                ajaxAddAnnualDeliveryPass();
            } else {
                $(options.annualDeliveryErrorCheck).show();
            }
        } else {
            ajaxAddAnnualDeliveryPass();
        }
    } else {
        if (pageContext.ns === 'annualdeliverypasslandingpage' && $cache.document.find(options.annualDeliveryPassLogin).length) {
            e.preventDefault();
            return;
        }
        if (!addingAnnualDeliveryPassToCart) {
            addingAnnualDeliveryPassToCart = true;
            $.ajax({
                url: urls.addAnnualDeliveryPass,
                type: "post",
                data: {ns: pageContext.ns}
            }).done(function(response) {
                var $content = $(options.content);
                if (pageContext.ns === 'annualdeliverypasslandingpage' && !($cache.document.find(options.annualDeliveryPassLogin).length)) {
                    $content.after(response);
                } else {
                    $content.html(response);
                }
                addingAnnualDeliveryPassToCart = false
            });
        }

    }
}

function ajaxAddAnnualDeliveryPass () {
    $.ajax({
        url: urls.addAnnualDeliveryPass,
        type: "post",
        data: {ns: pageContext.ns}
    }).done(function(response) {
        if (pageContext.ns === 'cart') {
            $cache.document.trigger('addAnnualDeliveryPass', response);
        } else if (pageContext.ns === 'checkout') {
            page.refresh();
        } else {
            $cache.document.trigger('product.addedToCart', response);
        }
        $(options.annualDeliveryErrorAdd).hide();
    }).fail(function(response) {
        $(options.annualDeliveryErrorAdd).show();
    });
}

function dialogAnnualDeliveryPass(e) {
    e.preventDefault();
    $.ajax({
        url: Urls.pageShow,
        data: {
            format: 'ajax',
            cid: 'adp-terms-conditions',
            type: 'post'
        }
    }).done(function(response) {
        dialog.open({
            html: response,
            options: {
                width: 'auto'
            },
        });
    });
}

function loginAnnualDeliveryPass(e) {
    e.preventDefault();
    var form = $(e.target).closest('form');
    if (form.valid()) {
        $.ajax({
            url: urls.loginAnnualDeliveryPass,
            type: "post",
            data: form.serialize()
        }).done(function(response) {
            if (pageContext.ns === 'annualdeliverypasslandingpage') {
                setExpandMinicart();
            }
            page.refresh();
        }).fail(function(response) {
            if (pageContext.ns === 'annualdeliverypasslandingpage' && ($(document).find(options.annualDeliveryLoginForm).length) &&
                    ($(response.responseText).find(options.annualDeliveryLoginForm).length)) {
                var $responseLoginForm = $(response.responseText).find(options.annualDeliveryLoginForm).first().html();
                $(document).find(options.annualDeliveryLoginForm).first().html($responseLoginForm);
            } else {
                var $content = $(options.content);
                $content.html(response.responseText);
            }
        });
    } else {
        form.validate();
    }
}

function setExpandMinicart() {
    var timeStamp = Math.floor(Date.now()),
        expirationDate = timeStamp + EXPANDMINICART_COOKIE_DURATION_MIN * 60 * 1000;
    cookie.set(EXPANDMINICART_COOKIE, true, new Date(expirationDate));
}

function triggerExpandMinicart() {
    if (cookie.get(EXPANDMINICART_COOKIE)) {
        $cache.document.trigger('product.addedToCart');
        cookie.remove(EXPANDMINICART_COOKIE);
    }
}
var annualDeliveryPass = {
    init: function(params){
        initializeConfig(params);
        cacheInit();
        initEvents();
        triggerExpandMinicart();
    }
}

module.exports = annualDeliveryPass;
