'use strict';

var $ = (typeof window !== "undefined" ? window['jQuery'] : typeof global !== "undefined" ? global['jQuery'] : null),
    resources = (typeof window !== "undefined" ? window['Resources'] : typeof global !== "undefined" ? global['Resources'] : null),
    CssConstants = require('./../../cssconstants'),
    Constants = require('./constants'),
    formPrepare = require('./../../../../../app_storefront_core/cartridge/js/pages/checkout/formPrepare');

var $cache = {},
    options = {
        form: '',
        isDisabledBtn: false
    };

function initializeConfig(params) {
    options = $.extend({}, options, params);
}

function initializeCache() {
    $cache.form = $(options.form);

    formPrepare.init({
        formSelector: Constants.CLASS_ADDRESS,
        continueSelector: Constants.CLASS_CHECKOUT_SAVE + ', ' + Constants.CLASS_PLACE_ORDER_BUTTON
    });
}

function initializeEvents() {
    var $form = $(options.form);
    $cache.form.on('change', 'select[name$="_country"]', function() {
        validateStateField(options.form);
    });

    if (isRangeCountry($form)) {
        $cache.form.on('change', 'input[name$="_state"]', formPrepare.validateEl);
    }

    validateStateField($form);
    $(document).on('address.populated', function() {
        validateStateField($form);
    });
}

function isRangeCountry($form) {
    var countries = resources.VALIDATE_COUNTRIES.split(',');
    if (~countries.indexOf($form.find('select[name$="_country"]').val())) {
        return true;
    } else {
        return false;
    }
}

function validateStateField($form) {
    var $stateField = $form.find('input[name$="_state"]'),
        $label = $form.find('label[for$="_state"]'),
        isShippingStep = $stateField.prop('id').includes('shippingAddress'),
        isBillingStep = $stateField.prop('id').includes('billingAddress');

    if (isRangeCountry($form)) {
        $stateField
            .addClass(CssConstants.REQUIRED)
            .rules('add', {
                required: true
            });

        $label.find('span').html((isShippingStep || isBillingStep) ? resources.STATE_REQUIRED : resources.STATE);

        if(options.isDisabledBtn) {
            $stateField.trigger('change');
        }
    } else {
        $stateField
            .removeClass(CssConstants.ERROR + ' ' + CssConstants.REQUIRED)
            .rules('add', {
                required: false
            });

        $label.find('span').html((isShippingStep || isBillingStep) ? resources.STATE_OPTIONAL : resources.STATE);
    }
}

var states = {
    init: function(params) {
        initializeConfig(params);
        initializeCache();
        initializeEvents();
    },
    validateStateField: validateStateField
};

module.exports = states;
