'use strict';

var sitePreferences = (typeof window !== "undefined" ? window['SitePreferences'] : typeof global !== "undefined" ? global['SitePreferences'] : null),
    $ = (typeof window !== "undefined" ? window['jQuery'] : typeof global !== "undefined" ? global['jQuery'] : null),
    urls = (typeof window !== "undefined" ? window['Urls'] : typeof global !== "undefined" ? global['Urls'] : null),
    page = require('./../page'),
    ajax = require('./../ajax'),
    JSConstants = require('./constants'),
    CssConstants = require('./../cssconstants'),
    constants = (typeof window !== "undefined" ? window['Constants'] : typeof global !== "undefined" ? global['Constants'] : null),
    carousel = require('./../carousel'),
    util = require('./../util'),
    options = {
        countryField: JSConstants.CLASS_COUNTRY_FIELD,
        autocompleteField: JSConstants.CLASS_AUTOCOMPLETE_FIELD,
        countryAddress: JSConstants.CLASS_COUNTRY_ADDRESS,
        storelocatorCountry: JSConstants.ID_STORELOCATOR_COUNTRY,
        storelocatorData: JSConstants.CLASS_STORELOCATOR_DATA,
        page: JSConstants.CLASS_PAGE,
        pagination: JSConstants.CLASS_PAGINATION,
        activeClass: CssConstants.M_ACTIVE,
        disabledClass: CssConstants.M_DISABLED,
        storedetailsurl: urls.storedetails,
        storelocatorDetailsMap: JSConstants.ID_STORELOCTATOR_DETAILS_MAP,
        storelocatorDetailsData: JSConstants.CLASS_STORELOCATOR_DETAILS_DATA,
        pagecontrol: JSConstants.CLASS_PAGE_CONTROL,
        storedetails: JSConstants.CLASS_STORE_DETAILS,
        storelocatorContentWrapper: JSConstants.CLASS_STORELOCATOR_CONTENT_WRAPPER,
        reloadStoresUrl: urls.reloadstores,
        errorMessage: JSConstants.CLASS_ERROR_MESSAGE,
        storelocatorWrapper: JSConstants.CLASS_STORELOCATOR_WRAPPER,
        pageNext: JSConstants.CLASS_NEXT_PAGE,
        pagePrev: JSConstants.CLASS_PREV_PAGE
    },
    distances = {
        "GB": 999,
        "AE": 300,
        "MT": 100,
        "LY": 900,
        "GI": 200,
        "SA": 500
    },
    $cache = {};

const googleMapsUtils = require('./../googleMapsUtils');


function initializeConfig(params) {
    options = $.extend({}, options, params);
}

function initCache(){
    $cache.document = $(document);
    $cache.map = null;
    $cache.detailMap = null;
    $cache.google = null;
    $cache.detailGoogle = null;
    $cache.coords = new Array();
    $cache.storelocatorData = $(options.storelocatorData);
    $cache.storelocatorCountry = $(options.storelocatorCountry);
    $cache.autocompleteField = $(options.autocompleteField);
    $cache.countryField = $(options.countryField);
    $cache.pagingControl = 7;
    if (util.isMobile()){
        $cache.pagingControl = 5;
    }
}

function queryAddress(){
    var country = $(options.countryField).val(),
        csrf_token = $(options.countryAddress).find('input[name$="csrf_token"]').val(),
        autocomplete = $(options.autocompleteField).val(),
        address = "",
        distance = "",
        initial = "";
    if(autocomplete != ""){
        address = country + " " + autocomplete
    }
    else{
        address = country;
        distance = distances[country];
        initial = "initial";
    }
    $(options.errorMessage).addClass(CssConstants.G_HIDDEN);
    var geocoder = new google.maps.Geocoder();
    geocoder.geocode({'address': address, componentRestrictions: { country: country}}, function(results, status) {
        if (results.length > 0){
            var latitude = results[0].geometry.location.lat();
            var longitude = results[0].geometry.location.lng();
            $.ajax({
                url: options.reloadStoresUrl,
                data: {
                    json: true,
                    longitude: longitude,
                    latitude: latitude,
                    format: 'ajax',
                    country: country,
                    distance: distance,
                    initial: initial,
                    csrf_token: csrf_token
                }
            })
            .done(function(response){
                if(!$.isEmptyObject(response)){
                    $(options.storelocatorWrapper).html(response);
                    initCache();
                    initMap(false, $cache.storelocatorCountry.get(0), {lat: latitude, lng: longitude}, function() {
                        initPagination();
                        $cache.google.maps.event.trigger($cache.map, 'resize');

                        if (autocomplete != ""){
                            $cache.map.setZoom(8);
                        }
                    });

                }
                else{
                    $(options.errorMessage).removeClass(CssConstants.G_HIDDEN);
                }
            })
        }
        else{
            $(options.errorMessage).removeClass(CssConstants.G_HIDDEN);
        }
    })
    return false;
}

function initMap(reinit, container, location, loadCallback){
    $cache.coords = new Array();
    var storesData = JSON.parse($cache.storelocatorData.find('script').text());
    $(storesData).each(function(){
        $cache.coords.push(this);
    });

    const googleMapsLoader = googleMapsUtils.getGoogleMapsLoader();

    if ($cache.coords.length > 0 && googleMapsLoader){
        googleMapsLoader.load().then(function(google) {
            $cache.google = google;
            $cache.map = new google.maps.Map(container, {
                zoom: 6,
                center: {lat: 52.7945643, lng: -1.6747691}
            });
            var geocoder = new google.maps.Geocoder(),
                country = $(options.countryField).val();
            geocoder.geocode( {'address' : country, componentRestrictions: { country: country}}, function(results, status) {
                if (status == google.maps.GeocoderStatus.OK) {
                    var loc = location ? location : results[0].geometry.location;
                    $cache.map.setCenter(loc);
                }
            });

            updateCountryAutocomplete(reinit);

            $($cache.coords).each(function(index){
                if (this.latitude != null && this.longitude != null){
                    var currCoords = {lat: this.latitude, lng: this.longitude},
                        image = urls.storeIcon,
                        name = this.name,
                        storeHours = "";
                    if (this.storeHours != null){
                        storeHours = this.storeHours
                    }
                    var shape = {
                            coords: [1, 1, 1, 34, 25, 34, 25 , 1],
                            type: 'poly'
                        };
                    var postalCode = this.postalCode || '';
                    var storeText = "<div class='b-tooltip_wrapper'><a href='" + urls.storedetails + "?StoreID=" + this.id + "' class='js-store-details-link b-store_info-details_link'>" + name + "</a>" +
                            "<div class='b-storelocator-details'>" +
                                "<div class='b-store_info'>" +
                                    "<div>" + this.address1 + "</div>" +
                                    "<div>" + this.address2 + "</div>" +
                                    "<div>" + this.city + "</div>" +
                                    "<div>" + postalCode + "</div>" +
                                "</div>" +
                                "<div class='b-store_hours'>" +
                                    "<h3 class='b-details_title'>"
                                     + Resources.OPENING_HOURS +
                                    "</h3>" +
                                    "<div>" + storeHours + "</div>" +
                                "</div>"+
                            "</div>"+
                            "<div class='icon-phone b-store_info-element'>" + this.phone + "</div>" +
                                "<div class='icon-envelope b-store_info-element'>" + this.email + "</div>" +
                                "<div class='b-full_info'>" +
                                    "<div data-href='" + urls.storedetails + "?StoreID=" + this.id + "' data-exists-in-pagedesigner='" + this.existInPageDesigner + "' data-pageid-in-pagedesigner='" + this.pageIDInPageDesigner + "' class='js-store-details-link b-full_info-link'>View full info</div>" +
                                "</div>" +
                        "</div>";

                    var imageObj = new Image();
                    imageObj.src = urls.storeIcon;
                    imageObj.setAttribute('crossOrigin', 'anonymous');
                    imageObj.onload = function(){
                        var marker = new google.maps.Marker({
                            position: currCoords,
                            map: $cache.map,
                            title: name,
                            icon: getComplexMarker(google, index + 1, imageObj),
                            shape: shape,
                            zIndex: 9999
                        }),
                        infowindow = new google.maps.InfoWindow({
                            content: storeText
                        });
                        google.maps.event.addListener(marker, 'click', function() {
                            infowindow.open($cache.map,marker);
                        });
                    }
                }
            });

            var storeID = util.getParameterValueFromUrl('StoreID');
            if(storeID && !reinit){
                detailPage();
            }
        }).then(loadCallback || function() {});
    }
}

function getComplexMarker(google, label, imageObj){
    var canvas = document.createElement('canvas');
    var context = canvas.getContext("2d");

    context.drawImage(imageObj, 0, 0);

    //Font adjustment
    context.font = "11px FuturaLt Bold";
    var xShift = 9;
    if (parseInt(label) > 9){
        xShift = 5
    }
    //Adjusting text position and style on marker
    context.fillStyle = 'white';
    context.fillText(label, xShift, 17);

    var image = {
        url: canvas.toDataURL(),
        size: new google.maps.Size(25, 34),
        origin: new google.maps.Point(0,0),
        anchor: new google.maps.Point(25, 34)
    };
    return image;
}

function initPagination(){
    var pagingPrepare = $(options.page),
        plainCode = "";
    if(pagingPrepare.length > 1){
        plainCode = plainCode + "<div class='js-page_control_prev b-pagination-control " + options.disabledClass + "'>Prev</div>";
        $(pagingPrepare).each(function(index){
            var pageActive = "";
            if (index == 0){
                pageActive = ' '+ options.activeClass;
            }
            plainCode = plainCode + "<div class='js-page_control" + pageActive + "'>" + (index + 1) + "</div>"
        });
        plainCode = plainCode + "<div class='js-page_control_next b-pagination-control'>Next</div>"
    }

    $(options.page).not('.' + options.activeClass).hide();
    $(options.pagination).append(plainCode);
    drawVisiblePagintators($cache.pagingControl);
}

function switchPage(indexSwitch){
    var index = null;
    if (!$(indexSwitch.target).hasClass('js-page_control')){
        index = indexSwitch
    }
    else{
        index = $(indexSwitch.target).index() - 1;
    }
    if (index > 0){
        $(options.pagePrev).removeClass(options.disabledClass);
    }
    else{
        $(options.pagePrev).addClass(options.disabledClass);
    }
    if (index != ($(options.page).length - 1)){
        $(options.pageNext).removeClass(options.disabledClass);
    }
    else{
        $(options.pageNext).addClass(options.disabledClass);
    }

    $(options.page).hide();
    $(options.page).removeClass(options.activeClass);
    $(options.page).eq(index).show().addClass(options.activeClass);
    $(options.pagecontrol).removeClass(options.activeClass);
    $(options.page).eq(index).addClass(options.activeClass);
    $(options.pagecontrol).eq(index).addClass(options.activeClass);
    drawVisiblePagintators($cache.pagingControl);
}

function pageNext(){
    var index = $(options.pagecontrol + ".m-active").index()
    switchPage(index);
}

function pagePrev(){
    var index = ($(options.pagecontrol + ".m-active").index() - 2);
    switchPage(index);
}

function drawVisiblePagintators(limit){
    var pages = $(options.pagecontrol).length,
        index = $(options.pagecontrol + ".m-active").index(),
        rightShift = 0,
        leftShift = 0,
        cutLimit = limit / 2;
    cutLimit = cutLimit - (cutLimit % 1);
    var leftCut = $(options.pagecontrol + ".m-active").prevAll(':lt(' + cutLimit + ')').not(options.pagePrev).length;
    if (leftCut < cutLimit){
        rightShift = cutLimit - leftCut;
    }
    var rightCut = $(options.pagecontrol + ".m-active").nextAll(':lt(' + cutLimit + ')').not(options.pageNext).length;
    if ((pages - index) < cutLimit){
        leftShift = cutLimit - rightCut;
    }

    $(options.pagecontrol).hide();
    $(options.pagecontrol + ".m-active").show();
    $(options.pagecontrol + ".m-active").prevAll(':lt(' + (cutLimit + leftShift) + ')').not(options.pagePrev).show();
    $(options.pagecontrol + ".m-active").nextAll(':lt(' + (cutLimit + rightShift) + ')').not(options.pageNext).show();
}

function detailPage(){
    var isExistsInPagedesigner = $(this).attr('data-exists-in-pagedesigner'),
        pageIDInPagedesigner = $(this).attr('data-pageid-in-pagedesigner');

    if ((isExistsInPagedesigner === 'true') && pageIDInPagedesigner) {
        page.redirect(util.appendParamToURL(urls.pageShow, 'cid', pageIDInPagedesigner));
    } else {
        var href = $(this).data('href');
        var storeID = util.getParameterValueFromUrl('StoreID', href);
        var url = urls.storedetails + "?StoreID=" + storeID;
        ajax.load({
            url: url,
            target: $(options.storedetails),
            callback: function () {
                $(options.storedetails).removeClass(CssConstants.G_HIDDEN);
                $(options.storelocatorContentWrapper).addClass(CssConstants.G_HIDDEN);
                if ($(options.storedetails).find('.js-carousel').length > 0){
                    carousel.initCarousel($(options.storedetails).find('.js-carousel').get(0), function(){
                        initDetailedStore();
                    });
                }
                else{
                    initDetailedStore();
                }
                $cache.detailGoogle.maps.event.addListenerOnce($cache.detailMap, 'idle', function() {
                    $cache.detailGoogle.maps.event.trigger($cache.detailMap, 'resize');
                });
            }
        });
    }

    return false;
}

function initDetailedStore(){
    var storesData = JSON.parse($(options.storelocatorDetailsData).find('script').text());
    const googleMapsLoader = googleMapsUtils.getGoogleMapsLoader();
    if (!googleMapsLoader) {
        return;
    }

    googleMapsLoader.load().then(function(google) {
        $cache.detailGoogle = google;
        $cache.detailMap = new google.maps.Map($(options.storelocatorDetailsMap).get(0), {
            zoom: 12,
            center: {lat: storesData.latitude, lng: storesData.longitude}
        });
        var image = urls.storeIconDetailed;
        new google.maps.Marker({
            position: {lat: storesData.latitude, lng: storesData.longitude},
            map: $cache.detailMap,
            title: storesData.name,
            icon: image
        });
    });
}

function storeDetailsClose(){
    $(options.storedetails).addClass(CssConstants.G_HIDDEN);
    $(options.storelocatorContentWrapper).removeClass(CssConstants.G_HIDDEN);
    $(options.storedetails).empty();
    initMap(true, $cache.storelocatorCountry.get(0));
}

function updateCountryAutocomplete(init){
    if(init){
        $(options.autocompleteField).val('');
    }
    new google.maps.places.Autocomplete($cache.autocompleteField.get(0)).setComponentRestrictions({country: $(options.countryField).val()});
}

function allStores(){
    var country = $(options.countryField).val(),
        distance = distances[country],
        initial = "initial";

    var geocoder = new google.maps.Geocoder();
    geocoder.geocode({'address': country, componentRestrictions: { country: country}}, function(results, status) {
        var latitude = results[0].geometry.location.lat();
        var longitude = results[0].geometry.location.lng();
        $.ajax({
            url: urls.reloadstores,
            data: {
                json: true,
                longitude: longitude,
                latitude: latitude,
                format: 'ajax',
                country: country,
                distance: distance,
                initial: initial
            }
        })
        .done(function(response){
            $(options.storelocatorWrapper).html(response);
            initCache();
            initMap(false, $cache.storelocatorCountry.get(0), {lat: latitude, lng: longitude}, function() {
                initPagination();
                $cache.google.maps.event.trigger($cache.map, 'resize');
            });
        })
    })
}

function initializeEvents(){
    $cache.document.on('click', options.pagecontrol, switchPage);
    $cache.document.on('click', options.pageNext + ':not(.m-disabled)', pageNext);
    $cache.document.on('click', options.pagePrev + ':not(.m-disabled)', pagePrev);
    $cache.document.on('click', JSConstants.CLASS_STORE_DETAILS_LINK, detailPage);
    $cache.document.on('click', JSConstants.CLASS_STOREDETAILS_CLOSE, storeDetailsClose);
    $cache.document.on('click', JSConstants.CLASS_ALL_STORES, allStores);
    $cache.document.on('change', options.countryField, updateCountryAutocomplete);
    $(options.countryAddress).submit(queryAddress);
}

function openStoreDetailsByHash(hash) {
    var hashParams = util.getParamsFromHash(hash);
    if (typeof (hashParams['sid']) !== 'undefined') {
        $(JSConstants.CLASS_STORE_DETAILS_LINK + '[data-href$='+hashParams['sid']+']').click();
    }
}

module.exports = {
    init: function(params) {
        initializeConfig(params);
        initCache();
        initMap(false, $cache.storelocatorCountry.get(0));
        initPagination();
        initializeEvents();

        openStoreDetailsByHash(window.location.hash);
    }
}
