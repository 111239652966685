'use strict';

var analyticsData = pageContext.analytics || {};

/**
 * @description collection of functions which sends reporting
 *              separated by page, which is specified in pageContext
 */
var tagsLibrary = {
    'global': function() {
        ga('send', 'pageview');
    },
    'orderconfirmation' : function() {
        var orderData = analyticsData.orderData;
        
        if(!orderData) {
            return;
        }
        
        ga('require', 'ecommerce');
        ga('ecommerce:addTransaction', {
            'id': orderData.transaction_id,
            'affiliation': 'bouxavenue',
            'revenue': orderData.value,
            'shipping': orderData.shipping,
            'tax': orderData.tax,
            'currency': orderData.currencyCode
        });

        var products = orderData.items;
        if (products) {
            for (var i = 0; i < products.length; i++) {
                var product = products[i];
                ga('ecommerce:addItem', {
                    'id': orderData.transaction_id,
                    'name': product.item_name,
                    'sku': product.item_id,
                    'category': product.item_category,
                    'price': product.price,
                    'quantity': product.quantity,
                    'currency': orderData.currencyCode
                });
            }
        }

        ga('ecommerce:send');
    }
}

/**
 * @description includes google analytics js script and registeres account
 * 
 * @return {Void}
 */
function initGoogleAnalitycs() {
    (function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
        (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
        m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
    })(window,document,'script','https://www.google-analytics.com/analytics.js','ga');

    ga('create', SitePreferences.GA_ACCOUNT_ID, 'auto');
    ga('set', 'location', window.location.href);
}

/**
 * @description sends global data and page specific data, depending on 'currentPage' value
 * 
 * @return {Void}
 */
function sendTags() {
    tagsLibrary['global']();
    
    if(tagsLibrary[pageContext.currentPage]) {
        tagsLibrary[pageContext.currentPage]();
    }
}

module.exports = {
    init: function () {
        if(!SitePreferences.GA_ENABLED) {
            return;
        }
        
        initGoogleAnalitycs();
        sendTags();
    }
};
