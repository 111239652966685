'use strict';

var dialog = require('./../../dialog'),
    productStoreInventory = require('./../../../../../app_storefront_core/cartridge/js/storeinventory/product'),
    tooltip = require('./../../../../../app_storefront_core/cartridge/js/tooltip'),
    util = require('./../../../../../app_storefront_core/cartridge/js/util'),
    addToCart = require('./../../../../../app_storefront_core/cartridge/js/pages/product/addToCart'),
    availability = require('./../../../../../app_storefront_core/cartridge/js/pages/product/availability'),
    image = require('./../../../../../app_storefront_core/cartridge/js/pages/product/image'),
    productNav = require('./../../../../../app_storefront_core/cartridge/js/pages/product/productNav'),
    productSet = require('./../../../../../app_storefront_core/cartridge/js/pages/product/productSet'),
    recommendations = require('./../../../../../app_storefront_core/cartridge/js/pages/product/recommendations'),
    variant = require('./../../../../../app_storefront_core/cartridge/js/pages/product/variant'),
    JSConstants = require('./../checkout/constants'),
    customScroll = require('./../../custom-scrollbar'),
    validator = require('./../../../../../app_storefront_core/cartridge/js/validator'),
    options = {
        niceScrollViewport: JSConstants.CLASS_SIZE_CHART_NICESCROLL_VIEWPORT
    };

/**
 * @description Initialize product detail page with reviews, recommendation and product navigation.
 */
function initializeDom() {
    productNav();
    recommendations();
    tooltip.init();
}

/**
 * @description Initialize event handlers on product detail page
 */
function initializeEvents() {
    var $pdpMain = $('#pdpMain');
    var $document = $(document);

    addToCart();
    availability();
    variant();
    image();
    productSet();

    if (SitePreferences.STORE_PICKUP) {
        productStoreInventory.init();
    }
    if (SitePreferences.LAYBUY_POPUP_ENABLED) {
        require('./../../../../../int_laybuy/cartridge/static/default/js/laybuy').init();
    }
    // Add to Wishlist and Add to Gift Registry links behaviors
    $pdpMain.on('click', '[data-action="wishlist"], [data-action="gift-registry"]', function () {
        var data = util.getQueryStringParams($('.pdpForm').serialize());
        if (data.cartAction) {
            delete data.cartAction;
        }
        var url = util.appendParamsToUrl(this.getAttribute('data-link'), data);
        this.setAttribute('data-link', url);
    });

    // product options
    $pdpMain.on('change', '.product-options select', function () {
        var salesPrice = $pdpMain.find('.product-add-to-cart .price-sales');
        var selectedItem = $(this).children().filter(':selected').first();
        salesPrice.text(selectedItem.data('combined'));
    });

    // prevent default behavior of thumbnail link and add this Button
    $pdpMain.on('click', '.thumbnail-link, .unselectable a', function (e) {
        e.preventDefault();
    });

    $pdpMain.on('click', JSConstants.CLASS_SIZE_CHART_lINK, function (e) {
        e.preventDefault();
        dialog.open({
            url: $(e.target).attr('href'),
            options: {
                dialogClass: 'b-size_guide'
            },
            callback: function() {
                customScroll.init({
                    'container': options.niceScrollViewport
                });
            }
        });
    });

    $pdpMain.on('click', '.js-notify-me-set-product', function (e) {
        e.preventDefault();

        var notifyMeInfo = JSON.parse($(this).attr('data-notify-me-info'));

        var notifyMeUrl = util.appendParamsToUrl(notifyMeInfo.notifyMeUrl, {
            colorCode: notifyMeInfo.selectedColor,
            nonselectable: notifyMeInfo.nonSelectableSizes.join(','),
            attrID: notifyMeInfo.targetSizeAttr
        });

        dialog.open({
            url: notifyMeUrl,
            options: {
                dialogClass: 'b-size_guide'
            },
            callback: function() {
                customScroll.init({
                    'container': options.niceScrollViewport
                });
            }
        });
    });

    $document.on('click', '.js-notify-me', function (e) {
        e.preventDefault();

        var notifyMeInfo = JSON.parse($(this).attr('data-notify-me-info'));

        var nonselectAttrArr = notifyMeInfo.nonSelectableSizes;

        var notifyMeUrl = util.appendParamsToUrl(notifyMeInfo.notifyMeUrl, {
            colorCode: notifyMeInfo.selectedColor,
            nonselectable: nonselectAttrArr.join(','),
            attrID: notifyMeInfo.targetSizeAttr
        });

        dialog.open({
            url: notifyMeUrl,
            options: {
                dialogClass: 'b-notify-me_modal'
            },
            callback: function() {
                customScroll.init({
                    'container': options.niceScrollViewport
                });
            }
        });
    });

    $(document).on('click', '.js-request-notify-me', function(e) {

        validator.init();

        var $this = $(this);
        var $notifyMeForm = $this.parents('[name="notifyMeForm"]');

        if (!$notifyMeForm.valid()) {
            return
        }

        e.preventDefault();

        var data = $notifyMeForm.serialize();

        $.ajax({
            type: 'POST',
            url: $notifyMeForm.attr('action'),
            data: data,
            success: function (response) {
                dialog.$container.html(response);
            },
            failure: function() {
                dialog.$container.html('<h1>' + 1111 + '</h1>');
            }
        });
    });
}



var product = {
    initializeEvents: initializeEvents,
    init: function () {
        initializeDom();
        initializeEvents();
    }
};

module.exports = product;
