'use strict';

var stickyheader = require('./../stickyheader');

module.exports = {
    init: function () {
        $('.js-close-slot').click(function () {
            var $wrapper = $(this).closest('.js-slot');

            if (!$wrapper) {
                console.warn('Content slot doesnt have wrapper with class "is-visible-slot" and slot ID');

                return;
            }
            
            $wrapper.addClass('g-hidden');

            $.ajax({
                url: Urls.hideContentSlot,
                method: 'POST',
                dataType: "json",
                data: {
                    ID: $wrapper.data('slot-id')
                },
                success: function (response) {
                    $wrapper.html('');
                    stickyheader.headerPromoClose();
                }
            });
        });
    }
};
