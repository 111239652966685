'use strict';

// Libs
var $ = (typeof window !== "undefined" ? window['jQuery'] : typeof global !== "undefined" ? global['jQuery'] : null);
var CryptoJS = require('crypto-js');

// Core files
var ajax = require('./../../ajax');
var formPrepare = require('./formPrepare');
var progress = require('./../../progress');
var giftcard = require('./../../../../../app_storefront_core/cartridge/js/giftcard');
var util = require('./../../../../../app_storefront_core/cartridge/js/util');
var checkoutConfigs = require('./config');
var constants = require('./constants');

// Common files
var sitePreferences = (typeof window !== "undefined" ? window['SitePreferences'] : typeof global !== "undefined" ? global['SitePreferences'] : null);
var urls = (typeof window !== "undefined" ? window['Urls'] : typeof global !== "undefined" ? global['Urls'] : null);
var resources = (typeof window !== "undefined" ? window['Resources'] : typeof global !== "undefined" ? global['Resources'] : null);

var $cache = {};

function initializeCache() {
    $cache.document = $(document);
}

function initializeDOM() {
    initializeForm();
}

function initializeEvents() {
    $cache.document
        .on('checkout.updatesummary', updateSummary)
        .on('checkout.updateinstruments', updatePaymentOptions)
        .on('address.populated,card.selected', checkAddressForm)
        .on('checkout.switchmethod', handleSwitchMethod)
        .on('click', constants.CLASS_PLACE_ORDER_BUTTON, checkRequiredFields)
        .on('click', constants.CLASS_KLARNA_PLACE_BUTTON, proceedPlacingOrder)
        .on('change', [constants.CLASS_BIRTHDAY_DAY, constants.CLASS_BIRTHDAY_MONTH, constants.CLASS_BIRTHDAY_YEAR].join(','), updateBirthday)
        .on('address.reinit', initializeForm)
        .on('submit', 'form[id$="billing"]', billingSubmitHandler)
        .on('blur', '[name$="_addressFields_postal"]', checkAddressForm);

    $cache.document.on('change', '.js-billing_as_shipping_toggle input', function(event) {
        var checkboxState = $(event.currentTarget).is(':checked');

        $('.js-billing_checkout-form').toggleClass('b-billing_checkout-form_collapsed', checkboxState);
        $('.js-billing_checkout-form').toggleClass('b-billing_checkout-form_collapsed-not', !checkboxState);
        $('.js-add_to_addresslist').toggleClass('hidden', checkboxState);
    });

    initWorldPayEvents();
}

function billingSubmitHandler(event) {
    var selectedPaymentMethod = $(constants.CLASS_PAYMENT_METHODS).find(':checked').val();
    if (selectedPaymentMethod == 'CREDIT_CARD'){
        event.preventDefault();

        if ($(this).valid()) {
            progress.show($('body'));
        }
    }
}

/**
 * @function
 * @description Fills the Credit Card form with the passed data-parameter and clears the former cvn input
 * @param {Object} data The Credit Card data (holder, type, masked number, expiration month/year)
 */
function setCCFields(data) {
    var $creditCard = $('[data-method="CREDIT_CARD"]');
    $creditCard.find('input[name$="creditCard_owner"]').val(data.holder).trigger('change');
    $creditCard.find('[name$="_type"]').val(data.type).trigger('change');
    $creditCard.find('input[name*="_creditCard_number"]').val(data.maskedNumber).trigger('change');
    $creditCard.find('[name$="_month"]').val(data.expirationMonth).trigger('change');
    $creditCard.find('[name$="_year"]').val(data.expirationYear).trigger('change');
    if(!data.showCvvField){
        $creditCard.find('input[name*="_cvn"]').removeClass('required');
        $creditCard.find('div.cvn').hide();
    }else{
        $creditCard.find('input[name$="_cvn"]').val('').trigger('change');
    }
    $cache.document.trigger('card.selected');
}

/**
 * @function
 * @description Updates the credit card form with the attributes of a given card
 * @param {String} cardID the credit card ID of a given card
 */
function populateCreditCardForm(cardID) {
    // load card details
    var url = util.appendParamToURL(Urls.billingSelectCC, 'creditCardUUID', cardID);
    ajax.getJson({
        url: url,
        callback: function (data) {
            if (!data) {
                window.alert(resources.CC_LOAD_ERROR);
                return false;
            }
            setCCFields(data);
        }
    });
}


/**
 * @function
 * @description Remove required class for element in credit card form
 * @param {String} $creditCard Credit card which contains element for removing required class
 */
function removeRequiredClass($creditCard) {
    $creditCard.find('input[name$="creditCard_owner"]').removeClass('required');
    $creditCard.find('select[name$="_type"]').removeClass('required');
    $creditCard.find('input[name*="_creditCard_number"]').removeClass('required');
    $creditCard.find('[name$="_month"]').removeClass('required');
    $creditCard.find('[name$="_year"]').removeClass('required');
    $creditCard.find('input[name$="_cvn"]').removeClass('required');
}

function checkAddressForm() {
    setTimeout(function() {
        formPrepare.validateForm();
    }, 200);
}

/**
 * @function
 * @description Changes the payment method form depending on the passed paymentMethodID. This function has been changed according to WorldPay integration guide
 * @param {String} paymentMethodID the ID of the payment method, to which the payment method form should be changed to
 */
function updatePaymentMethod(paymentMethodID) {
    $.ajax({
        url: urls.loadPaymentMethods,
        type:"POST",
        data:{"selectedPaymentMethodId":paymentMethodID},
        success:function(response){
            $(constants.CLASS_PAYMENT_CC_WRAPPER).html(response);

            if (paymentMethodID === 'CREDIT_CARD' && !sitePreferences.PAYMENT_METHODS_COLLAPSED) {
                var $activeMethod = $(constants.CLASS_PAYMENT_METHODS).find(':checked').closest(constants.CLASS_PAYMENT_METHOD_WRAPPER).first();
                $activeMethod.addClass(constants.CLASS_ACTIVE_STATE);
            }

            // select credit card from list
            $('.creditCardList').on('change', function () {
                var cardUUID = $(this).val();
                if (!cardUUID) {return;}
                                // remove server side error
                $('.error-message').remove();
                var selectedPaymentMethod = $(constants.CLASS_PAYMENT_METHODS).find(':checked').val();
                if(selectedPaymentMethod === 'WorldPay'){
                    if(cardUUID !== ""){
                        $(".card-block").show();
                        //$(".save-card-block").hide();
                    }else{
                        $(".card-block").hide();
                        $(".save-card-block").show();
                    }
                }
                populateCreditCardForm(cardUUID);
                var  $creditCardSection = $('[data-method="WorldPay"]');
                $creditCardSection.find('[name$="_cards"]').val('');
                var $form = $('.address');
                var countryCode = $('select[id$="_country"]', $form).val();
                if(countryCode === "BR"){ //Integration for Brazil requires double check. It was OOS
                    if(paymentMethodID === "WorldPay"){
                        $("#Payment_Brazil").show();
                        $creditCardSection.find('input[name$="creditCard_cpf"]').addClass('required');
                        $creditCardSection.find('input[name$="creditCard_installments"]').addClass('required');
                    } else if(paymentMethodID == "CREDIT_CARD"){
                        var  $creditCardSection = $('[data-method="CREDIT_CARD"]');
                        $creditCardSection.find('input[name$="creditCard_cpf"]').addClass('required');
                        $creditCardSection.find('input[name$="creditCard_installments"]').addClass('required');
                    }
                }
                var $creditCard = $('[data-method="CREDIT_CARD"]');
                $creditCard
                    .find('input[name$="creditCard_owner"],input[name*="_creditCard_number"]')
                    .on('keydown', function() {
                        $('.creditCardList').val('');
                });
            });

            if(paymentMethodID === 'Worldpay'){
                var  $creditCard = $('[data-method="Worldpay"]');
                $creditCard.find('input, select').val('');
                removeRequiredClass();
                $('.creditCardList').val('');
                $creditCard.find('input[name$="_saveCard"]').val('true');
                $(".card-block").hide();
                $(".save-card-block").show();
            }
            var $form = $('.address');
            var countryCode = $('select[id$="_country"]', $form).val();

            if(countryCode =="BR"){
                // Integration for Brazil requires double check. It was OOS
                // fieldForBrazil(paymentMethodID, countryCode); - not implemented
            }

            // select prefered credit card from list
            $('#preferedCreditCardList').on('change', function (e) {
                e.preventDefault();
                var  $creditCard = $('[data-method="WorldPay"]');
                $creditCard.find('input[name$="creditCard_owner"]').val('');
                $creditCard.find('input[name*="_creditCard_number"]').val('');
                removeRequiredClass();
                $('.creditCardList').val('');
                $creditCard.find('input[name$="_saveCard"]').val('true');
                $(".card-block").hide();
                $(".save-card-block").show();
                //$("#Payment_Brazil").hide(); Brazil integration is not implemented
            });

            $("#clearpaymentform").on('click',function(e){
                e.preventDefault();
                var  $creditCard = $('[data-method="WorldPay"]');
                $creditCard.find('input,select').val('');
                removeRequiredClass();
                $('.creditCardList').val('');
                $creditCard.find('input[name$="_saveCard"]').val('true');
                $(".card-block").hide();
                $(".save-card-block").show();
                //$("#Payment_Brazil").hide(); Brazil integration is not implemented
            });
            $(constants.CLASS_PAYMENT_CC_WRAPPER).closest('.js-payment-method-content').show();
        }
    });

    $('input[name$="_selectedPaymentMethodID"]').removeAttr('checked');
    $('input[value=' + paymentMethodID + ']').prop('checked', 'checked');
}


/**
 * @function
 * @description init events which will be handled by WorldPay
 */
function initWorldPayEvents() {
    var $checkoutForm = $(constants.CLASS_BILLING_FORM);
    var $field,
        errorObject;

    var dsType = (document.getElementById("dstype")) ? document.getElementById("dstype").value : null;

    $(window).on('message', function(e) {
        if (e.originalEvent.origin.indexOf('worldpay.com') >= 0 || e.originalEvent.origin.indexOf('cardinalcommerce.com') >= 0) {
            var data = JSON.parse(e.originalEvent.data);
            if (data && data.Status && data.SessionId) {
                $('[data-method="CREDIT_CARD"]').find('input[name*="_creditCard_dfReferenceId"]').val(data.SessionId);
                proceedPlacingOrder();
                return;
            }
        }
    });
    $checkoutForm.on('submit', function(e) {
        e.preventDefault();
        if(sitePreferences.WP_ENABLE_CLIENT_SIDE_ENCRYPTION && !$('#creditCardList').val()){
            if( $(constants.CLASS_PAYMENT_METHODS).find(':checked').val() === 'CREDIT_CARD' ){

                var formValidator = $(this).data('validator');
                formValidator.resetForm();

                var $creditCard = $('[data-method="CREDIT_CARD"]');
                $creditCard.find('[name$="_encryptedData"]').val('');

                var data = {
                    cvc: $creditCard.find('input[name*="_cvn"]').val() !== '' ? $creditCard.find('input[name*="_cvn"]').val() : null,
                    cardHolderName: $creditCard.find('input[name$="creditCard_owner"]').val(),
                    cardNumber: $creditCard.find('input[name*="_creditCard_number"]').val(),
                    expiryMonth: $creditCard.find('[name$="_month"]').val() < 10 ? '0' + $creditCard.find('[name$="_month"]').val():$creditCard.find('[name$="_month"]').val(),
                    expiryYear: $creditCard.find('[name$="_year"]').val()
                }

                if (dsType === 'two3d') {
                    var $this = $(this);
                    var JWT_TOKEN = createJWT();
                    var iframeurl = $('#cardiframe').data('url');
                    if (JWT_TOKEN && JWT_TOKEN != undefined) {
                        var iframeurl2 = iframeurl + '?cardNum=' + data.cardNumber + '&JWT' + JWT_TOKEN;

                        $('#cardiframe').attr('src', iframeurl2);

                        window.addEventListener('message', function(event) {
                            var data1 = JSON.parse(event.data);
                            var dataSessionId = data1.SessionId;
                            var url = Urls.Sess;
                            $.ajax({
                                url: url,
                                data: {
                                    dataSessionId: dataSessionId
                                },
                                type: 'POST'
                            });
                            $this.unbind('submit').submit();
                        }, false);
                    }
                }
                Worldpay.setPublicKey(sitePreferences.WP_CSE_PUBLIC_KEY);
                var encryptedData = Worldpay.encrypt(data, function(errorCodes) {
                        errorObject = {};

                        for (var i = 0; i < errorCodes.length; i++){
                            $field = $(checkoutConfigs.creditcarderrors[errorCodes[i]]);
                            errorObject[$field.prop('name')] =   resources["WORLDPAY_CC_ERROR_" + errorCodes[i]];
                        }

                        formValidator.showErrors(errorObject);
                    });
                if (encryptedData) {
                    $creditCard.find('input[type != checkbox], select').addClass('js-validate-ignore');
                    $creditCard.find('[name$="_encryptedData"]').val(encryptedData);
                } else {
                    return false;
                }
            }
        }
        var continueURL = $(this).attr('action');
        var $actionBtn = $(constants.CLASS_CHECKOUT_SAVE);
        var formData = $(this).serialize() + '&' + $actionBtn.attr('name') + '=' +$actionBtn.val();
        $.ajax({
            url: continueURL,
            method: 'POST',
            data: formData,
            success: function(response){
                if (response) {
                    if (response.indexOf('finalizeBillingForm') > 0) {
                        $(constants.CLASS_BILLING_AREA).append(response);
                    } else {
                        $('.js-checkout-page').addClass('b-checkout_page--disabled');
                        $(constants.CLASS_BILLING_AREA).html(response);
                        $(window).scrollTop($(constants.CLASS_BILLING_AREA).offset().top);
                    }
                }
            }
        })
    });
}


function performDDC() {
    var $creditCard = $('[data-method="CREDIT_CARD"]');
    var bin = $creditCard.find('input[name*="_creditCard_number"]').val();
    $('#ddcIframe').remove();
    var $iframe = $('<iframe></iframe>')
        .attr('id', 'ddcIframe')
        .addClass('hidden')
        .attr('target', '_top');
    $iframe.attr('src', urls.getDDCIframe + '?cardbin=' + bin + '&_t'+ new Date().getTime());
    $('body').append($iframe);
}

/**
 * @function
 * @description updates birthday value
 * @param {String} day class for birthday's day field
 * @param {String} month class for birthday's month field
 * @param {String} year class for birthday's year field
 * @param {String} birthday class for birthday field
 */
function updateBirthday() {
    var $day = $(constants.CLASS_BIRTHDAY_DAY),
        $month = $(constants.CLASS_BIRTHDAY_MONTH),
        $year = $(constants.CLASS_BIRTHDAY_YEAR),
        $birthday = $(constants.CLASS_BIRTHDAY),
        currentDate = new Date();
    if ((+$year.val() + 18) == currentDate.getFullYear() && +$month.val() == (currentDate.getMonth() + 1)) {
        if (+$day.val() > currentDate.getDate()){
            $day.val('');
        }
    }
    if ((+$year.val() + 18) == currentDate.getFullYear() && +$month.val() > (currentDate.getMonth() + 1)) {
        $month.val('');
    }
    if($day.val() && $month.val() && $year.val()) {
        $birthday.val($month.val() + '/' + $day.val() + '/' + $year.val());
    } else {
        $birthday.val('');
    }
}

/**
 * @function
 * @description checks if Klarna birthday fields are valid
 */
function checkKlarnaFields() {
    updateBirthday();
    var isKlarnaValid = true;
    $(constants.CLASS_BIRTHDAY_CONTAINER).find('input:visible, select:visible').each(function() {
        if (!$(this).valid()) {
            $(window).scrollTop($(this).closest('.f-field-row').offset().top);
            isKlarnaValid = false;
        }
    });
    return isKlarnaValid;
}

function checkRequiredFields(e, disableContinue) {
    var isAllValid = true;
    var isContinue = !disableContinue;
    var findClass = 'input.required:visible, select.required:visible';

    var $billingCheckoutAddress = $('.js-billing_checkout-form');
    if ($billingCheckoutAddress.length && !$billingCheckoutAddress.hasClass('b-billing_checkout-form_collapsed')) {
        findClass = "input.required:visible, select.required:visible, input.required[id^='dwfrm_billing_billingAddress_addressFields_']";
    }

    $('form[id$="billing"]').find(findClass).each(function() {
        if (!$(this).valid()) {
            $(window).scrollTop($(this).closest('.f-field-row').offset().top);
            isAllValid = false;
            return false;
        }
    });
    var selectedPaymentMethod = $(constants.CLASS_PAYMENT_METHODS).find(':checked').val();
    if (isKlarnaMethod(selectedPaymentMethod)){
        isAllValid = checkKlarnaFields() && $(constants.CLASS_PAYMENT_METHODS).find(':checked').is(':enabled');
    }

    if (isContinue) {
        if (isAllValid) {
            if (!isKlarnaMethod(selectedPaymentMethod)){
                if (selectedPaymentMethod == 'CREDIT_CARD') {
                    performDDC();
                    return;
                }
                proceedPlacingOrder();
            } else {
                //else wait for Klarna authorization
                if (window.KlarnaPaymentsUrls) {
                    $.getJSON(window.KlarnaPaymentsUrls.updateSession);
                }
                $(constants.CLASS_KLARNA_AUTH_BUTTON).click();
            }
        }
    } else {
        return isAllValid;
    }

}

/**
 * @function
 * @description place order
 */
function proceedPlacingOrder() {
    var isOptedOut = $('#dwfrm_billing_billingAddress_addToEmailList')[0].checked;
    dynamicYield.callEvent('Checkout Opt In', { optedInEmails: !isOptedOut });
    $(constants.CLASS_CHECKOUT_SAVE).prop("disabled", false);
    $(constants.CLASS_CHECKOUT_SAVE).trigger('click');
}

/**
 * @function
 * @description updates the order summary based on a possibly recalculated basket after a payment method has been applied
 */
function updateSummary() {
    var $summary = $('#secondary.summary');
    // indicate progress
    progress.show($summary);

    // load the updated summary area
    $summary.load(Urls.summaryRefreshURL, function () {
        // hide edit shipping method link
        $summary.fadeIn('fast');
        $summary.find('.checkout-mini-cart .minishipment .header a').hide();
        $summary.find('.order-totals-table .order-shipping .label a').hide();
    });
}

function updatePaymentOptions(){
    var $paymentMethodsContainer = $(constants.CLASS_PAYMENT_METHODS);
    var selectedPaymentMethod = null;
    var request = $.ajax({
        url: urls.loadPaymentOptions,
        type: "POST",
        success: function(response){
                $paymentMethodsContainer.html(response);
                selectedPaymentMethod = $paymentMethodsContainer.find(':checked').val();
            }
    });
    request.done(function(){
        updatePaymentMethod((selectedPaymentMethod) ? selectedPaymentMethod : 'CREDIT_CARD');
    });
}

function initializeForm() {
    formPrepare.init({
        continueSelector: '[name$="billing_save"], ' + constants.CLASS_PLACE_ORDER_BUTTON,
        formSelector: 'form[id$="billing"]'
    });
}

/**
 * @function
 * @description checks if selected method is Klarna method
 * @param {String} method selected method that will be checked for Klarna
 */
function isKlarnaMethod (method) {
    return method && method.toLowerCase().indexOf('klarna') >= 0;
}

function handleSwitchMethod(event, data) {
    if (data && data.method === resources.LB_METHOD_ID) {
        formPrepare.validateForm();
    }
    if (data && isKlarnaMethod(data.method) && $(constants.CLASS_BIRTHDAY).val()) {
        checkKlarnaFields();
        formPrepare.validateForm();
    }
}

/**
 * @function
 * @description adds birthday fields to Klarna payment method block
 */
function updateKlarnaContainer (method) {
    var $birthday = $(constants.CLASS_BIRTHDAY_CONTAINER);
    var currentDate = new Date();
    $('.js-birthday-wrapper-' + method).append($birthday);
    $(constants.CLASS_BIRTHDAY_YEAR).find('option').each(function(indx, el) {
        if ($(el).val() > currentDate.getFullYear() - 18) {
            $(el).remove();
        }
    });
    $(constants.CLASS_BIRTHDAY_DAY + ',' + constants.CLASS_BIRTHDAY_MONTH + ',' + constants.CLASS_BIRTHDAY_YEAR).addClass('required');
    $(constants.CLASS_BIRTHDAY_CONTAINER).show();
    updateBirthday();
}

// World Pay
function base64url(source) {
    // Encode in classical base64
    var encodedSource = CryptoJS.enc.Base64.stringify(source);

    // Remove padding equal characters
    encodedSource = encodedSource.replace(/=+$/, '');

    // Replace characters according to base64url specifications
    encodedSource = encodedSource.replace(/\+/g, '-');
    encodedSource = encodedSource.replace(/\//g, '_');

    return encodedSource;
}

function uuidv4() {
    return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, function(c) {
        return (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    })
}

function createJWT() {
    var header = {
        "alg": "HS256",
        "typ": "JWT"
    };
    if (document.getElementById("iss") && document.getElementById("OrgUnitId")
        && document.getElementById("jwtMacKey") && document.getElementById("iat")) {
        var jwtIssuer = document.getElementById("iss").value;
        var orgUnitId = document.getElementById("OrgUnitId").value;
        var jwtMacKey = document.getElementById("jwtMacKey").value;
        var iatVal = document.getElementById("iat").value;
        var iat = Math.floor(iatVal/1000);
        var jti = uuidv4();

        var data = {
            "jti": jti,
            "iat": iat,
            "iss": jwtIssuer,
            "OrgUnitId": orgUnitId
        };

        var secret = jwtMacKey;
        var stringifiedHeader = CryptoJS.enc.Utf8.parse(JSON.stringify(header));
        var encodedHeader = base64url(stringifiedHeader);


        var stringifiedData = CryptoJS.enc.Utf8.parse(JSON.stringify(data));
        var encodedData = base64url(stringifiedData);
        var signature = encodedHeader + "." + encodedData;

        signature = CryptoJS.HmacSHA256(signature, secret);
        signature = base64url(signature);

        var encodedJWT = encodedHeader + "." + encodedData + "." + signature;

        return encodedJWT;
    }
}

/**
 * @function
 * @description loads billing address, Gift Certificates, Coupon and Payment methods
 */
var billing = {
    init: function() {

        var $selectPaymentMethod = $(constants.CLASS_PAYMENT_METHODS);
        var selectedPaymentMethod = $selectPaymentMethod.find(':checked').val();

        if (!sitePreferences.PAYMENT_METHODS_COLLAPSED) {
            // default payment method to 'CREDIT_CARD'
            updatePaymentMethod((selectedPaymentMethod) ? selectedPaymentMethod : 'CREDIT_CARD');
        }

        initializeCache();
        initializeDOM();
        initializeEvents();
        // TODO: refactor to use cashe, events and handlers
        var $checkoutForm = $(constants.CLASS_BILLING_FORM);
        var $addGiftCert = $('#add-giftcert');
        var $giftCertCode = $('input[name$="_giftCertCode"]');
        var $addCoupon = $('#add-coupon');
        var $couponCode = $('input[name$="_couponCode"]');

        $(constants.CLASS_BIRTHDAY_CONTAINER).hide();
        $selectPaymentMethod.on('click', 'input[type="radio"]', function () {
            updatePaymentMethod($(this).val());
            if (isKlarnaMethod($(this).val())) {
                updateKlarnaContainer($(this).val());
                formPrepare.validateForm();
            } else {
                $(constants.CLASS_BIRTHDAY_DAY + ',' + constants.CLASS_BIRTHDAY_MONTH + ',' + constants.CLASS_BIRTHDAY_YEAR).removeClass('required');
            }
            // add active class to selected payment method

            $(constants.CLASS_PAYMENT_METHOD_WRAPPER).removeClass(constants.CLASS_ACTIVE_STATE);
            $(this).closest(constants.CLASS_PAYMENT_METHOD_WRAPPER).addClass(constants.CLASS_ACTIVE_STATE);
            $cache.document.trigger('checkout.switchmethod', {'method' : $(this).val()});
        });

        // select credit card from list
        $('#creditCardList').on('change', function () {
            var cardUUID = $(this).val();
            if (!cardUUID) {return;}
            populateCreditCardForm(cardUUID);

            // remove server side error
            $('.error-message').remove();
        });

        $('#check-giftcert').on('click', function (e) {
            e.preventDefault();
            var $balance = $('.balance');
            if ($giftCertCode.length === 0 || $giftCertCode.val().length === 0) {
                var error = $balance.find('span.error');
                if (error.length === 0) {
                    error = $('<span>').addClass('error').appendTo($balance);
                }
                error.html(resources.GIFT_CERT_MISSING);
                return;
            }

            giftcard.checkBalance($giftCertCode.val(), function (data) {
                if (!data || !data.giftCertificate) {
                    $balance.html(resources.GIFT_CERT_INVALID).removeClass('success').addClass('error');
                    return;
                }
                $balance.html(resources.GIFT_CERT_BALANCE + ' ' + data.giftCertificate.balance).removeClass('error').addClass('success');
            });
        });

        $addGiftCert.on('click', function (e) {
            e.preventDefault();
            var code = $giftCertCode.val(),
                $error = $checkoutForm.find('.giftcert-error');
            if (code.length === 0) {
                $error.html(resources.GIFT_CERT_MISSING);
                return;
            }

            var url = util.appendParamsToUrl(Urls.redeemGiftCert, {giftCertCode: code, format: 'ajax'});
            $.getJSON(url, function (data) {
                var fail = false;
                var msg = '';
                if (!data) {
                    msg = resources.BAD_RESPONSE;
                    fail = true;
                } else if (!data.success) {
                    msg = data.message.split('<').join('&lt;').split('>').join('&gt;');
                    fail = true;
                }
                if (fail) {
                    $error.html(msg);
                    return;
                } else {
                    window.location.assign(Urls.billing);
                }
            });
        });

        $addCoupon.on('click', function (e) {
            e.preventDefault();
            var $error = $checkoutForm.find('.coupon-error'),
                code = $couponCode.val();
            if (code.length === 0) {
                $error.html(resources.COUPON_CODE_MISSING);
                return;
            }

            var url = util.appendParamsToUrl(Urls.addCoupon, {couponCode: code, format: 'ajax'});
            $.getJSON(url, function (data) {
                var fail = false;
                var msg = '';
                if (!data) {
                    msg = resources.BAD_RESPONSE;
                    fail = true;
                } else if (!data.success) {
                    msg = data.message.split('<').join('&lt;').split('>').join('&gt;');
                    fail = true;
                }
                if (fail) {
                    $error.html(msg);
                    return;
                }

                //basket check for displaying the payment section, if the adjusted total of the basket is 0 after applying the coupon
                //this will force a page refresh to display the coupon message based on a parameter message
                if (data.success) {
                    dynamicYield.callEvent('Promo Code Entered', {
                        code: code,
                        ajax: true
                    });
                    if(data.baskettotal === 0) {
                        window.location.assign(Urls.billing);
                    }
                }
            });
        });

        // trigger events on enter
        $couponCode.on('keydown', function (e) {
            if (e.which === 13) {
                e.preventDefault();
                $addCoupon.click();
            }
        });
        $giftCertCode.on('keydown', function (e) {
            if (e.which === 13) {
                e.preventDefault();
                $addGiftCert.click();
            }
        });

    },
    checkRequiredFields: checkRequiredFields
};

module.exports = billing;
