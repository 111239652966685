'use strict'

var $ = (typeof window !== "undefined" ? window['jQuery'] : typeof global !== "undefined" ? global['jQuery'] : null),
    urls = (typeof window !== "undefined" ? window['Urls'] : typeof global !== "undefined" ? global['Urls'] : null),
    dialog = require('./../../dialog'),
    customScroll = require('./../../custom-scrollbar'),
    resources = (typeof window !== "undefined" ? window['Resources'] : typeof global !== "undefined" ? global['Resources'] : null),
    Constants = (typeof window !== "undefined" ? window['Constants'] : typeof global !== "undefined" ? global['Constants'] : null),
    JSConstants = require('./constants'),
    CssConstants = require('./../../cssconstants'),
    shipping = require('./shipping'),
    layout = require('./../../layout'),
    options = {
        postcodeField : JSConstants.CLASS_POSTCODE_FIELD  + ' input',
        postcodeButton : JSConstants.CLASS_POSTCODE_BUTTON,
        postcodeForm : JSConstants.CLASS_POSTCODE_FORM,
        store : JSConstants.CLASS_STORE,
        tabHeader : JSConstants.CLASS_TAB_HEADER,
        choosenStore : JSConstants.CLASS_CHOOSEN_STORE,
        deliveryToStoreSection : JSConstants.CLASS_DELIVERY_TO_STORE_SECTION,
        niceScrollViewport: JSConstants.CLASS_NICESCROLL_VIEWPORT,
        noStore: JSConstants.CLASS_NOSTORE
    },
    $cache = {
        countryField : JSConstants.CLASS_COUNTRY_FIELD
    };

const googleMapsUtils = require('./../../googleMapsUtils');

function initializeConfig(params) {
    $.extend(options, params || {});
}

function cacheInit(){
    $cache.document = $(document);
}

function setMaxHeightToStoreContainer() {
    var $stores = $(options.store);
    if ($stores.length) {
        var maxHeight = Math.max.apply(null, $stores.map(function ()
            {
                return $(this).height();
            }).get());
        $stores.each(function(){
            $(this).height(maxHeight);
        });
    }
}

function checkField(e){
    let $this = $(e.currentTarget);
    let $validator = $this.closest(options.postcodeForm).validate();
    let $postCodeInputField = $this.closest(options.postcodeForm).find(options.postcodeField);
    let postalCode = $postCodeInputField.val();
    let csrf_token = $this.closest(options.postcodeForm).find('input[name="csrf_token"]').val();

    if ( !$postCodeInputField.valid() ){
        return false;
    } else {
        var $countryField = $($cache.countryField),
            country = $countryField.length ? $countryField.val() : $('[name$="_addressFields_country"]').val();

        const googleMapsLoader = googleMapsUtils.getGoogleMapsLoader();
        if (!googleMapsLoader) {
            return;
        }

        googleMapsLoader.load().then(function(google) {
            var geocoder = new google.maps.Geocoder();
            geocoder.geocode( {'address' : postalCode, componentRestrictions: {country: country || Constants.DEFAULT_COUNTRY}}, function(results, status) {
                if (status == google.maps.GeocoderStatus.OK) {
                    var latitude = results[0].geometry.location.lat();
                    var longitude = results[0].geometry.location.lng();
                    $.ajax({
                        url: urls.postcodesearch,
                        data: {
                            format: 'ajax',
                            longitude: longitude,
                            latitude: latitude,
                            postalCode: postalCode,
                            csrf_token: csrf_token
                        }
                    })
                    .done(function (response) {
                        if ($.isEmptyObject(response)){
                            $postCodeInputField
                                .removeClass(CssConstants.VALID)
                                .addClass(CssConstants.ERROR)
                                .parent().find('label.' + CssConstants.ERROR).remove();
                            $postCodeInputField.after(
                                '<label id="' + $postCodeInputField.attr('id') + '-error" for="' +
                                    $postCodeInputField.attr('id') + '" class="' + CssConstants.ERROR + '">' +
                                    resources.POSTCODE_NO_STORES +
                                '</label>'
                            );
                        } else {
                            dialog.open({
                                html: response,
                                options: {
                                    width: 'auto',
                                    dialogClass: 'b-postcode_finder-dialog'
                                },
                                callback: function() {
                                    customScroll.init({
                                        'container': options.niceScrollViewport
                                    });
                                    if (!layout.isMobile()) {
                                        setMaxHeightToStoreContainer();
                                    }
                                }
                            });
                        }
                    })
                    .fail(function(response){
                        console.log('Unable to find stores!');
                    });
                } else if (google.maps.GeocoderStatus.ZERO_RESULTS) {
                    var errors = {};
                    errors[$postCodeInputField.prop('id')] = resources.INVALID_POSTCODE;
                    $validator.showErrors(errors);
                }
            });
        })
    }
}

function getAllStore() {
    var $postCodeInputField = $(options.postcodeForm).find(options.postcodeField);
    $.ajax({
        url: urls.getallstore,
        data: {
            format: 'ajax'
        }
    })
    .done(function (response) {
        if ($.isEmptyObject(response)){
            $postCodeInputField
                .removeClass(CssConstants.VALID)
                .addClass(CssConstants.ERROR)
                .parent().find('label.' + CssConstants.ERROR).remove();
            $postCodeInputField.after(
                '<label id="' + $postCodeInputField.attr('id') + '-error" for="' +
                    $postCodeInputField.attr('id') + '" class="' + CssConstants.ERROR + '">' +
                    resources.POSTCODE_NO_STORES +
                '</label>'
            );
        } else {
            dialog.open({
                html: response,
                options: {
                    width: 'auto',
                    dialogClass: 'b-postcode_finder-dialog'
                },
                callback: function() {
                    customScroll.init({
                        'container': options.niceScrollViewport
                    });
                }
            });
        }
    });
}

function switchTab(){
    var switchTo = $(this).data("partner").partner,
        counter = 0,
        $showAllBtn = $(JSConstants.CLASS_SHOW_ALL_STORES);
    $(options.store).addClass(CssConstants.G_HIDDEN);
    $(options.noStore).addClass(CssConstants.G_HIDDEN);
    $(options.store).each(function(){
        if($(this).data("selector").partner == switchTo && counter < 4){
            $(this).toggleClass(CssConstants.G_HIDDEN);
            counter++;
        }
    });
    $(options.tabHeader).toggleClass(CssConstants.M_ACTIVE);
    $(JSConstants.CLASS_SHOW_ALL_STORES).removeClass(CssConstants.G_HIDDEN);
    if (switchTo === 'ryman') {
        $showAllBtn
            .html(resources.SHOW_ALL_RYMAN_STORES)
            .data('partner', 'ryman');
    } else {
         if (!counter) {
            $(options.noStore).removeClass(CssConstants.G_HIDDEN);
        }
        $showAllBtn
            .html(resources.SHOW_ALL_BOUX_STORES)
            .data('partner', 'boux');
    }
}

function selectStore(storeID){
    var storeid = null;

    if ($(storeID.target).data("selector")){
        storeid = $(storeID.target).data("selector").storeid;
    }
    else{
        storeid = storeID;
    }

    $.ajax({
        url: urls.storedetails,
        data: {
            format: 'ajax',
            StoreID: storeid,
            choosen: true
        }
    })
    .always(function(response){
        $(JSConstants.CLASS_CHOOSEN_STORE).html(response);
        $(JSConstants.CLASS_DELIVERY_TO_STORE_SECTION).addClass(CssConstants.G_HIDDEN);
        $(JSConstants.CLASS_CHOOSEN_STORE).removeClass(CssConstants.G_HIDDEN);
        shipping.initSelectedStore($($.parseHTML(response)).find(JSConstants.CLASS_STORE_PARAMS).data('store-params'));
        dialog.close();
        setStoreToPickup(storeid);
    })
}

function selectAgain(){
    $(JSConstants.CLASS_DELIVERY_TO_STORE_SECTION + ", " + JSConstants.CLASS_CHOOSEN_STORE).toggleClass(CssConstants.G_HIDDEN);
    $(options.choosenStore).empty();
}

function showPostcodeSection(){
    if (~this.value.indexOf('deliveryToStore')){
        $(options.deliveryToStoreSection).removeClass(CssConstants.G_HIDDEN);
    }
    else{
        $(options.deliveryToStoreSection).addClass(CssConstants.G_HIDDEN);
        $(options.choosenStore).empty();
        setStoreToPickup(null);
    }
}

function showAllStores(){
    var $that = $(this);
    $(options.store).addClass(CssConstants.G_HIDDEN);
    $(options.store).each(function(){
        if($(this).data("selector").partner === $that.data('partner')){
            $(this).removeClass(CssConstants.G_HIDDEN);
        }
    });
    $(JSConstants.CLASS_SHOW_ALL_STORES).addClass(CssConstants.G_HIDDEN);

    if ($that.data('partner') === 'ryman' && !$that.data('countPartner')) {
        getAllStore();
    }
    if ($that.data('partner') === 'boux' && !$that.data('countMain')) {
        getAllStore();
    }
}

function setStoreToPickup(storeID){
    var data = {
            storepickup: true,
            storeid: storeID
        };
    $.ajax({
        url: urls.setSessionStorePickup,
        data: data,
        success: function (response) {
            // TODO error handling
        }
    });
}

function initEvents(){
    $cache.document
        .on('click', options.postcodeButton, checkField)
        .on('click', JSConstants.CLASS_TAB_HEADER + ':not(.m-active)', switchTab)
        .on('click', JSConstants.CLASS_CHOOSE_STORE, selectStore)
        .on('click', JSConstants.CLASS_CHOOSE_AGAIN, selectAgain)
        .on('change', JSConstants.CLASS_SHIPPING_METHOD_LIST + ' input:not(#dwfrm_deliverytostore_postcode)', showPostcodeSection)
        .on('click', JSConstants.CLASS_SHOW_ALL_STORES, showAllStores)
        .on('click', JSConstants.CLASS_POSTCODE_EDIT_LINK, function(e) {
            e.preventDefault();
            $(JSConstants.CLASS_POSTCODE_EDIT_MESSAGE).addClass(CssConstants.G_HIDDEN);
            $(options.postcodeForm).removeClass(CssConstants.G_HIDDEN);
        })
        .on('keypress', options.postcodeField, function(e) {
            if (e.keyCode == $.ui.keyCode.ENTER) {
                e.preventDefault();
                checkField(e);
            }
        })
        .on('click', JSConstants.CLASS_POSTCODE_FIND_BUTTON, checkField)
        .on('removestore', function () {
            setStoreToPickup(null);
        });
}

var postCodeDelivery = {
        init: function(params){
            initializeConfig(params);
            cacheInit();
            initEvents();
        },
        selectStore: function(storeID){
            selectStore(storeID);
        }
}

module.exports = postCodeDelivery;
