'use strict';

var util = require('./util'),
    initialized = false,
    viewportWidth = {
    'mobile' : { 'maxWidth' : 767, 'minWidth' : 320 },
    'tablet' : { 'maxWidth' : 1279, 'minWidth' : 768 }
    };

function initializeEvents() {
    var viewMode = layout.getMode();
    $(window).on('resize', util.eventDelay(function() {
        $(document).trigger('window.resize');
        var layoutView = layout.getMode();
        if(viewMode != layoutView) {
            viewMode = layoutView;
            $(document).trigger('window.modechanged', { mode: viewMode });
        }
    }, 500));
    $(window).on('scroll', util.eventDelay(function() {
        $(document).trigger('window.scroll', { scrollTop: $(this).scrollTop() });
    }, 300));

    $(document).on('layout.togglelock', 'body', toggleBodyLocking)
                  .on('layout.lock', 'body', bodyLock)
                  .on('layout.unlock', 'body', bodyUnlock);
};

function toggleBodyLocking() {
    var $this = $(this);
    if($this.css('overflow') == 'auto') {
        $this.trigger('layout.lock');
    } else {
        $this.trigger('layout.unlock');
    }
}

function bodyLock() {
    $(this).css({
        height: '100%',
        overflow: 'hidden',
        width: '100%',
        position: 'fixed'
    });
}

function bodyUnlock() {
    $(this).css({
        height: 'unset',
        overflow: 'auto',
        width: 'unset',
        position: 'unset'
    });
}

var layout = {
    viewports: viewportWidth,
    getMode : function () {
        var windowWidth = $(window).width();
        if( windowWidth <= viewportWidth.mobile.maxWidth ) {
            return 'mobile';
        } else if ( windowWidth <= viewportWidth.tablet.maxWidth ) {
            return 'tablet';
        } else {
            return 'desktop';
        }
    },
    isMobile: function() {
        return this.getMode() === 'mobile';
    },
    isIPadDevice: function () {
        var userAgent = window.navigator.userAgent.toLowerCase();
        return (userAgent.indexOf('ipad') > -1 || userAgent.indexOf('macintosh') > -1 &&
                ('ontouchstart' in window || navigator.msMaxTouchPoints || navigator.maxTouchPoints));
    },
    isTablet: function() {
        return this.getMode() === 'tablet';
    },
    isDesktop: function() {
        return this.getMode() === 'desktop';
    },
    init : function() {
        if (initialized) return;
        initializeEvents();
        initialized = true;
    },
    bodyLock: bodyLock,
    bodyUnlock: bodyUnlock
}

module.exports = layout;
