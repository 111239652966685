'use strict';

var addProductToCart = require('./product/addtocart'),
    $ = (typeof window !== "undefined" ? window['jQuery'] : typeof global !== "undefined" ? global['jQuery'] : null),
    page = require('./../page'),
    util = require('./../../../../app_storefront_core/cartridge/js/util'),
    urls = (typeof window !== "undefined" ? window['Urls'] : typeof global !== "undefined" ? global['Urls'] : null),
    login = require('./../../../../app_storefront_core/cartridge/js/login'),
    Constants = require('./account/constants');

var $cache = {},
    options = {
        quantityFieldSelector: 'input[name="Quantity"]'
    };

function initializeCache() {
    $cache.document = $(document);
}

/**
 * @description Handler to handle the add to cart event
 */
function addToCartHandler(e) {
    e.preventDefault();

    var $actionButton = $(this);
    var $form = $actionButton.closest('form');

    if($form.valid()) {
        $cache.document.trigger('before.additem.tocart');
    } else {
        $cache.document.trigger('form.addtocart.invalid');
        return;
    }

    addItemToCart($form).then(function (response) {
        var $uuid = $form.find('input[name="uuid"]');
        if (!$uuid.length || !$uuid.val().length) {
            $cache.document.trigger('product.addedToCart', [response, $actionButton, $form]);

            dynamicYield.callEvent('Add to Cart', {
                productId: $('.b-wishlist-product_main_info .b-desc_product .b-desc_product-sku .value',$form.closest('.b-wishlist-product'))[0].innerText,
                quantity: $form.find( options.quantityFieldSelector ).val()
            });
        }
        $actionButton.closest('.b-wishlist-product').find('.g-button-remove').click();
    }.bind(this));
}

/**
 * @description Make the AJAX request to add an item to cart
 * @param {Element} form The form element that contains the item quantity and ID data
 * @returns {Promise}
 */
function addItemToCart(form) {
    var $form = $(form),
        $qty = $form.find( options.quantityFieldSelector );

    if ($qty.length === 0 || isNaN($qty.val()) || parseInt($qty.val(), 10) === 0) {
        $qty.val('1');
    }

    var pid = $form.find('#pid').attr('value').replace('/', '\\/');
    var variantData = $form.find('#datalayer_info_product_' + pid).data('datalayerProductInfoJson');
    $(document).trigger('gtm.push.pdp.event', ['add_to_cart', +$qty.val(), variantData]);

    dynamicYield.callEvent('setAddedItem', {
        productId: $form.find('input[name="pid"]').val(),
        quantity: $qty.val()
    });

    return $.ajax({
        type: 'POST',
        url: util.ajaxUrl(urls.addProduct),
        data: $form.serialize()
    });
}

function initializeEvents() {
	addProductToCart.init();
    $('#editAddress').on('change', function () {
        page.redirect(util.appendParamToURL(urls.wishlistAddress, 'AddressID', $(this).val()));
    });

    //add js logic to remove the , from the qty feild to pass regex expression on client side
    $('.option-quantity-desired input').on('focusout', function () {
        $(this).val($(this).val().replace(',', ''));
    });

    $cache.document.on('click', Constants.CLASS_ADD_TO_CART_WISH_LIST, addToCartHandler);
}

exports.init = function () {
    initializeCache();
    initializeEvents();
    login.init();
};
