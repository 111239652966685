/**
 *    (c) 2009-2014 Demandware Inc.
 *    Subject to standard usage terms and conditions
 *    For all details and documentation:
 *    https://bitbucket.com/demandware/sitegenesis
 */

'use strict';

// if jQuery has not been loaded, load from google cdn
if (!window.jQuery) {
	var s = document.createElement('script');
	s.setAttribute('src', 'https://ajax.googleapis.com/ajax/libs/jquery/1.7.1/jquery.min.js');
	s.setAttribute('type', 'text/javascript');
	document.getElementsByTagName('head')[0].appendChild(s);
}

require('./../../../app_storefront_core_ext/cartridge/js/js-ext')();
require('./../../../app_storefront_core_ext/cartridge/js/jquery-ext')();

var app = window.app = {
	init: function () {
		this.components.extendConfig(require('./components-config')).initAll();
	},
	'page' : require('./../../../app_storefront_core_ext/cartridge/js/page'),
	'components' : require('./../../../app_storefront_core_ext/cartridge/js/components')
};

// initialize app
$(document).ready(function () {
	app.init();
});