'use strict';

var constants = require('./constants'),
    CssConstants = require('./../../cssconstants'),
    resources = (typeof window !== "undefined" ? window['Resources'] : typeof global !== "undefined" ? global['Resources'] : null),
    $ = (typeof window !== "undefined" ? window['jQuery'] : typeof global !== "undefined" ? global['jQuery'] : null);

var $cache = {};

function initializeCache(params) {
    $cache.form = params.form;
    $cache.subscribeBtn = $cache.form.find('.js-news_subscription');
    $cache.messagesContainer = $(document).find('.js-account_subscribe-messages-container');
    $cache.email = $(document).find('#customerEmail');
}

function initializeEvents(params) {
    $cache.form.on('submit', function (e) {
        e.preventDefault();

        var url = $(this).prop('action');
        var dataParams = $(this).serialize();

        $.ajax({
            url: url,
            data: dataParams,
            success: onSubscribeSuccess
        });

        return false;
    });
}

function onSubscribeSuccess(response) {
    if (response && response.success) {
        var text;
        if (response.status === 'subscribed') {
            text = resources.SUBSCRIBED;
            $cache.messagesContainer.html(resources.SUBSCRIBED_MESSAGE);

            $cache.subscribeBtn
                .toggleClass('js-unsubscribe', true)
                .toggleClass('m-subscribed', true)
                .toggleClass('m-updated', true)
                .text(text);

            dynamicYield.callEvent('Newsletter Subscription', { email: $cache.email[0].value });
        } else if (response.status === 'unsubscribed') {
            text = resources.RESUBSCRIBE;
            $cache.messagesContainer.html(resources.RESUBSCRIBE_MESSAGE);

            $cache.subscribeBtn
                .toggleClass('js-unsubscribe', false)
                .toggleClass('m-subscribed', false)
                .toggleClass('m-updated', true)
                .text(text);
        }
    }
}

module.exports = {
    init: function(params) {
        if (params.form && params.form.length) {
            initializeCache(params);
            initializeEvents(params);
        }
    }
}
