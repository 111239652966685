'use strict';

var $ = (typeof window !== "undefined" ? window['jQuery'] : typeof global !== "undefined" ? global['jQuery'] : null),
	JSConstants = require('./constants'),
	options = {
		storelocatorDetailsMap: JSConstants.ID_STORELOCTATOR_DETAILS_MAP,
		storelocatorDetailsData: JSConstants.CLASS_STORELOCATOR_DETAILS_DATA
	},
	$cache = {};

const googleMapsUtils = require('./../googleMapsUtils');

function initializeConfig(params) {
    options = $.extend({}, options, params);
}

function initCache(){
	$cache.storelocatorDetailsMap = $(options.storelocatorDetailsMap).get(0);
	$cache.storelocatorDetailsData = $(options.storelocatorDetailsData);
}

function initDetails(){
    let map = null;
    let storesData = JSON.parse($cache.storelocatorDetailsData.find('script').text());

    const googleMapsLoader = googleMapsUtils.getGoogleMapsLoader();
    if (!googleMapsLoader) {
        return;
    }

    googleMapsLoader.load().then(function(google) {
        map = new google.maps.Map($cache.storelocatorDetailsMap, {
            zoom: 12,
            center: {lat: storesData.latitude, lng: storesData.longitude}
        });

        new google.maps.Marker({
            position: {lat: storesData.latitude, lng: storesData.longitude},
            map: map,
            title: storesData.name
        });
    });
}

module.exports = {
	init: function(params) {
		initializeConfig(params);
		initCache();
		initDetails();
	}
}
