'use strict';
var util = require('../../util');

var $detailsTabsContainer = $('.js-pdp-tabs'),
    $detailsReadLess = $('.js-pdp-tabs-read-less')


function detailsTabs() {
    if(util.isMobile()) {
        $detailsReadLess.click(() => {
            if($(document).scrollTop() > $detailsTabsContainer.offset().top) {
                window.scrollTo(0, $detailsTabsContainer.offset().top - 80)
            }
        })
    }
}

module.exports = detailsTabs;

