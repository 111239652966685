'use strict';

var Urls = (typeof window !== "undefined" ? window['Urls'] : typeof global !== "undefined" ? global['Urls'] : null);

function getAndInsertCSRFToken(form){
      $.ajax(
          {     
            url: Urls.csrfGenerateToken, 
            dataType: 'json',
            success: function(data, status){
                insertCSRFForm(data, form);
            },
            error: function(xhr, status, error){
                console.log('error' + error);
            }
          }
      );
}

function insertCSRFForm(csrfjson, form){
    var csrf_name = csrfjson.csrf_token_name;
    var csrf_value = csrfjson.csrf_token_value;
    var inputfield = document.createElement("input");
    inputfield.type = "hidden";
    inputfield.name = csrf_name;
    inputfield.value = csrf_value;
    var children = form.children;
    form.insertBefore(inputfield, children[children.length-1]);
}

module.exports = getAndInsertCSRFToken;
