'use strict';
// jQuery extensions

var validator = require('./../../../app_storefront_core/cartridge/js/validator');
var libphonenumber = require('./util/libphonenumber');
var phone = new RegExp(Resources.REGEXP_PHONE);
var regex = $.extend(true, validator.regex, {
    'notLatinAlphabet': new RegExp(Resources.REGEXP_NOT_LATIN_ALPABET),
    'notLatinAphabetAndNumbers': new RegExp(Resources.REGEXP_NOT_LATIN_ALPABET_AND_NUMBERS),
    'latinAphabetAndNumbers': new RegExp(Resources.REGEXP_LATIN_ALPABET_AND_NUMBERS_WITH_SPACES),
    'email': new RegExp(Resources.REGEXP_EMAIL),
    'mobile': new RegExp(Resources.REGEXP_MOBILE),
    'password': new RegExp(Resources.REGEXP_PASSWORD),
    'postalCode': {
        'uk': new RegExp(Resources.UK_POSTAL_CODE_REG_EXP),
        'bfpo': new RegExp(Resources.BFPO_NUMBER_REG_EXP)
    },
    'allNumbers': new RegExp(Resources.REGEXP_ALL_NUMBERS),
    'phone': {
        'gb': phone,
        'us': phone,
        'ca': phone
    }
});
var settings = validator.settings;
var allowedKlarnaAddressCharacters = Resources.ALLOWED_KLARNA_ADDRESS_SPECIAL_CHARACTERS.split('');

function validateEmail(value, element) {
    var isOptional = this.optional(element);
    var isValid = regex.email.test($.trim(value));
    return isOptional || isValid;
}

function validateInputOnLatinAplabet(value, element) {
    var isValid = !regex.notLatinAlphabet.test($.trim(value));
    return isValid;
}

function validateInputOnLatinAplabetAndNumbers(value, element) {
    var isValid = !regex.notLatinAphabetAndNumbers.test($.trim(value));
    return isValid;
}

function validateLengthMin(value, el) {
    var isOptional = this.optional(el);
    var isValid = value.length >= 2;

    return isOptional || isValid;
};

function validateNoOnlyNumbers(value, el) {
    var replacedValue = value.replace(regex.allNumbers, '');
    var isValid = replacedValue.length > 0;

    return isValid;
}

function validateKlarnaAddressSpecialCharacters(value, el) {
    var isValid = true;
    var preparedValue = value;
    var prevValue = value;
    do {
        prevValue = preparedValue;
        preparedValue = preparedValue.replace(/\w+/, '');
    } while(preparedValue !== prevValue);


    for (var index = 0; index < preparedValue.length; index++) {
        var character = preparedValue.charAt(index);
        isValid = allowedKlarnaAddressCharacters.indexOf(character) > -1;
        if (!isValid) {
            break;
        }
    }

    return isValid;
}

function getPostalRegExp(element) {
    var regExpValue = '';
    for (var name in regex.postalCode) {
        if($(element).hasClass(name)) {
            return regex.postalCode[name];
        }
    }
    return regExpValue;
}

function toggleManualTrigger() {
    var $toggleManual = $('.js-toggle-manual-form');
    if ($toggleManual.length && $toggleManual.attr('data-collapsed') === '') {
        $toggleManual[0].click();
    }
}

function validatePostalCode(value, element) {
    var isOptional = this.optional(element);
    var regExpPostalCode = getPostalRegExp(element);
    if (regExpPostalCode == '' || isOptional) {
        return true;
    }

    var isValid = regExpPostalCode.test($.trim(value));
    if (!(isOptional || isValid)) {
        toggleManualTrigger();
    }

    return isOptional || isValid;
}

function validatePostalByCountry(value, element) {
    var country = $(element).closest('form').find('.country');
    if (country.length === 0 || country.val().length === 0 || !regex.postal[country.val().toLowerCase()]) {
        return true;
    }

    var rgx = regex.postal[country.val().toLowerCase()];
    var isOptional = this.optional(element);
    var isValid = rgx.test($.trim(value));

    if (!(isOptional || isValid)) {
        toggleManualTrigger();
    }
    return isOptional || isValid;
}

function validatePostalCodeForContactUs(value, element) {
    if (window.GlobalE && window.GlobalE.Country !== 'GB') {
        return true;
    }

    var rgx = regex.postal['gb'];
    var isOptional = this.optional(element);
    var isValid = rgx.test($.trim(value));

    if (!(isOptional || isValid)) {
        toggleManualTrigger();
    }

    return isOptional || isValid;
}

function validatePhoneForContactUs(value, element) {
    if (window.GlobalE && window.GlobalE.Country !== 'GB') {
        return true;
    }

    if (this.optional(element)) {
        return true;
    }

    var rgx = regex.phone['gb'];
    var isValid = rgx.test($.trim(value));
    var isBlur = document.activeElement.id !== element.id;
    var onlyNumbers = value.replace(regex.notLatinAphabetAndNumbers, '').length;
    if (isValid && (isBlur || onlyNumbers > 12)) {
        try {
            isValid = libphonenumber.parsePhoneNumber(value, "GB").isValid();
        } catch (error) {
            isValid = false;
        }
    }

    return isValid;
}

function validatePassword(value, element) {
    var isOptional = this.optional(element);
    var isValid = regex.password.test($.trim(value));
    return isOptional || isValid;
}

function validateMobile(value, element) {
    var isOptional = this.optional(element);
    var isValid = regex.mobile.test($.trim(value));
    return isOptional || isValid;
}

function $pluginFallback() {
    return $.fn;
};

function initializeEvents() {
    $(document).on('quickview.open', function() {
        validator.init();
    });
    $("[id$=country]").on('change', function() {
        $(this).closest("form").find('[id$=postal]').trigger('change');
    });
};

function validateBirthdayFields(value, el) {
    var isValid = false;
    var form = $(el).closest("form");
    var birthdayErrorMsgInvalid = form.find('.js-date_fields-error-invalid');
    var birthdayErrorMsgPast = form.find('.js-date_fields-error-past');
    birthdayErrorMsgPast.removeClass(settings.errorClass).removeClass(settings.validClass).hide();
    birthdayErrorMsgInvalid.removeClass(settings.errorClass).removeClass(settings.validClass).hide();
    var day = $(el).hasClass('js-date_field-day') ? $(el) : form.find("select[name$='_day']");
    var month = $(el).hasClass('js-date_field-month') ? $(el) : form.find("select[name$='_month']");
    var year = $(el).hasClass('js-date_field-year') ? $(el) : form.find("select[name$='_year']");

    // in case if customer chose all fields. Check date on valid
    if( day.val() && month.val() && year.val() ) {
        var dob = new Date( year.val(), month.val() - 1, day.val() );
        var currentDate = new Date();
        if( dob.getDate() != day.val() ) {
            showBlockViolation([day, month, year], birthdayErrorMsgInvalid, Resources.DATE_INVALID);
            return false;
        }
        validateElements([day, month, year], true);
        return true;
    } else if(value !== "") {
        return true;
    }
};

function showBlockViolation($elementContainer, $elementMessage, resourceMessage) {
    $elementMessage
        .text(resourceMessage)
        .addClass(settings.errorClass).show();
    validateElements($elementContainer, false);
};

function validateElements($elementContainer, isValid) {
    for (var i = 0; i < $elementContainer.length; i++ ){
        if($elementContainer[i].val()) {
            $elementContainer[i]
                .removeClass(isValid ? settings.errorClass : settings.validClass)
                .addClass(isValid ? settings.validClass : settings.errorClass);
        }
    }
};

function validateTextareaMaxLength(value, element) {
    var isOptional = this.optional(element);
    var isValid = value.length <= +Resources.VALIDATE_MAXLENGTH_TEXTAREA_NUMBER;

    return isOptional || isValid;
}

module.exports = function () {
    // params
    // toggleClass - required
    // triggerSelector - optional. the selector for the element that triggers the event handler. defaults to the child elements of the list.
    // eventName - optional. defaults to 'click'
    $.fn.toggledList = function (options) {
        if (!options.toggleClass) { return this; }
        var list = this;
        return list.on(options.eventName || 'click', options.triggerSelector || list.children(), function (e) {
            e.preventDefault();
            var classTarget = options.triggerSelector ? $(this).parent() : $(this);
            classTarget.toggleClass(options.toggleClass);
            // execute callback if exists
            if (options.callback) {options.callback();}
        });
    };

    $.fn.syncHeight = function () {
        var arr = $.makeArray(this);
        arr.sort(function (a, b) {
            return $(a).height() - $(b).height();
        });
        return this.height($(arr[arr.length - 1]).height());
    };

    /**
     * The event handling as first handler
     * @param {String} name
     * @param {Function} fn
     */
    $.fn.bindFirst = function (name, fn) {
        this.bindNth(name, fn, 0);
    };

    /**
     * The event handling on given specific position on handlers queue
     * @param {String} name
     * @param {Function} fn
     * @param {Number} index
     */
    $.fn.bindNth = function (name, fn, index) {
        // Bind event normally.
        this.bind(name, fn);
        // Move to nth position.
        this.changeEventOrder(name, index);
    };

    /**
     * Changing the order of event handler for current element
     * @param {String} names
     * @param {Number} newIndex
     */
    $.fn.changeEventOrder = function (names, newIndex) {
        var that = this;
        // Allow for multiple events.
        $.each(names.split(' '), function (idx, name) {
            that.each(function () {
                var handlers = $._data(this, 'events')[name.split('.')[0]];
                // Validate requested position.
                newIndex = Math.min(newIndex, handlers.length - 1);
                handlers.splice(newIndex, 0, handlers.pop());
            });
        });
    };

    /**
     * Getting the data attributes collection by the given prefix
     * @param  {String} prefix
     * @return {Array}
     */
    $.fn.dataByPrefix = function (prefix) {
        var data = this.data(),
            regex = new RegExp('^' + prefix),
            result = {};

        for (var key in data) {
            if(regex.test(key)) {
                result[key] = data[key];
            }
        }

        return result;
    };

    /** Setting the default options set for volidator plugin */
    $.validator.setDefaults({
        'ignore' : ":hidden:input:not([id^='dwfrm_singleshipping_shippingAddress_addressFields_']):not(.b-billing_checkout-form_collapsed-not input[id^='dwfrm_billing_billingAddress_addressFields_']), .js-validate-ignore"
    });

    $.validator.addMethod('v-only-latin-alphabet', validateInputOnLatinAplabet, Resources.VALIDATE_NON_LATIN_ALPHABET);

    $.validator.addMethod('v-only-latin-alphabet-and-numbers', validateInputOnLatinAplabetAndNumbers, Resources.VALIDATE_NON_LATIN_ALPHABET_WITH_SPACES);

    $.validator.addMethod('v-length-min', validateLengthMin, $.validator.format(Resources.VALIDATE_MINLENGTH, 2));

    $.validator.addMethod('v-length-textarea', validateTextareaMaxLength, $.validator.format(Resources.VALIDATE_MAXLENGTH_TEXTAREA_MSG, Resources.VALIDATE_MAXLENGTH_TEXTAREA_NUMBER));

    $.validator.addMethod('v-no-only-numbers', validateNoOnlyNumbers, Resources.VALIDATE_NO_ONLY_NUMBERS);

    $.validator.addMethod('v-klarna-address-special-characters', validateKlarnaAddressSpecialCharacters, Resources.VALIDATE_KLARNA_ADDRESS_SPECIAL_CHARACTERS);

    /**
     * Add email validation method to jQuery validation plugin.
     * Text fields must have 'email' css class to be validated as email
     */
    $.validator.addMethod('email', validateEmail, Resources.VALIDATE_EMAIL);

    $.validator.addMethod('postalcode', validatePostalCode, Resources.VALIDATE_POSTALCODE);

    $.validator.addMethod('postal', validatePostalByCountry, Resources.VALIDATE_POSTALCODE);

    $.validator.addMethod('v-postal-contact-us', validatePostalCodeForContactUs, Resources.VALIDATE_POSTALCODE);

    $.validator.addMethod('v-phone-contact-us', validatePhoneForContactUs, Resources.INVALID_PHONE);

    /**
     * Add email validation method to jQuery validation plugin.
     * Text fields must have 'mobile' css class to be validated as mobile number
     */
    $.validator.addMethod('mobile', validateMobile, Resources.VALIDATE_MOBILE);

    /**
     * Add password validation method to jQuery validation plugin.
     * Text fields must have 'password' css class to be validated as password
     */
    $.validator.addMethod('password', validatePassword, Resources.VALIDATE_PASSWORD);

    /**
     * Add date via selectbox validation method to jQuery validation plugin.
     * Text fields must have 'js-date_fields' css class to be validated
     */
    $.validator.addMethod('js-birthday_field', validateBirthdayFields, "");

    $.validator.addMethod('isEmailEqual', function(val, el) {
        return val.toLowerCase() == $('.js-email_field').val().toLowerCase();
    }, Resources.VALIDATE_EMAIL_NOTMATCH);

    $.validator.addMethod('isPasswordEqual', function(val, el) {
        return val == $('.js-password_field').val();
    }, Resources.VALIDATE_PASSWORD_NOTMATCH);

    $.validator.addClassRules({
        'js-emailconfirm_field': { isEmailEqual: true },
        'js-passwordconfirm_field': { isPasswordEqual: true }
    });
    /**
     * Add possibility to get required message from attribute in if it is else will be used default value
     */
    $.validator.messages.required = function($1, element, $3) {
        var requiredText = $(element).closest('.f-form-row').data('requiredText');
        return requiredText || Resources.VALIDATE_REQUIRED;
    };

    $.validator.methods.phone = function(value, element) {
        var isOptional = this.optional(element);
        if (isOptional) {
            return isOptional;
        }

        var country = $(element).closest('form').find('.country');
        var rgx = regex.phone[country.val().toLowerCase()];
        var isValid = rgx.test($.trim(value));

        if (country.length === 0 || country.val().length === 0 || !regex.phone[country.val().toLowerCase()]) {
            return true;
        }

        var isBlur = document.activeElement.id !== element.id;
        var onlyNumbers = value.replace(regex.notLatinAphabetAndNumbers, '').length;
        if (isValid && (isBlur || onlyNumbers > 12)) {
            try {
                isValid = libphonenumber.parsePhoneNumber(value, "GB").isValid();
            } catch (error) {
                isValid = false;
            }
        }

        return isValid;
    }

    $.validator.methods['v-emoji'] = function (value, el) {
        var apostropheRgx = new RegExp(/[‘]/, 'g');
        if ($.trim(value).match(apostropheRgx)) {
            value = value.replace('‘', '');
        }

        var rgx = new RegExp(regex.emoji, 'g');
        var isOptional = this.optional(el);
        var isValid = $.trim(value).match(rgx) === null;

        return isOptional || isValid;
    };

    initializeEvents();

    /** Fallback to avoid application crash in case lagacy carousel plugin executed */
    $.fn.jcarousel = $pluginFallback;
    $.fn.jcarouselControl = $pluginFallback;
    $.fn.jcarouselPagination = $pluginFallback;
    $.fn.jcarouselAutoscroll = $pluginFallback;
};
