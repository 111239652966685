'use strict';

var sitePreferences = (typeof window !== "undefined" ? window['SitePreferences'] : typeof global !== "undefined" ? global['SitePreferences'] : null);

module.exports = {
    init: function() {
        if (SitePreferences.LAYBUY_POPUP_ENABLED) {
            require('./../../../../../int_laybuy/cartridge/static/default/js/laybuy').init();
        }
    }
};