'use strict';

var $cache = {},
	layout = require('./layout'),
	initialized = false,
	options = {
	    'container' : '',
	    'options' : {
	        'parent' : 'body'
	    }
	};

function initializeParams(params) {
	options = $.extend(true, {}, options, params);
}

function initializeCache() {
	$cache.document = $(document);
	$cache.elements = $(options['container']);
}

function initializeEvents() {
	elementsInitialization();

	$cache.document
	.on('window.resize layout.reload', elementsReCalculation)
	.on('click', '.js-toggler', elementsReCalculation)
	.on('stickykit.update', elementsInitialization)
	.on('search.update', function (event, data) {
	    elementsInitialization(data && !!data['container'] ? data['container'] : document);
	});
}

function extendDeviceConfig(data) {
	var options = {},
		dataNamePattern = /(desktop|tablet|mobile)(\w+)/,
		deviceMode = layout.getMode(),
		matched = [];

	for (var name in data) {
		if (matched = name.match(dataNamePattern)) {
			if (matched[1] !== deviceMode) continue;
			options[matched[2].toLowerCase()] = data[name];
		} else {
			options[name] = data[name];
		}
	}

	return options;
}

function elementsInitialization(container, data) {
	var $container = $(!!container && !(container instanceof $.Event) ? container : document);
	var params = $.extend(true, {}, options, data);
	if ('components' in params && params['components']) {
		params['components'].forEach(function(data) {
			$container.find(data.container).each(function (index, element) {
				var $beforeStickyElem = $(data.beforeStickyElem);
				if ($beforeStickyElem.length) {
					data.offset_top = $beforeStickyElem.height();
				}
				$(element).stick_in_parent(data);
			});
		});
	}
}

function elementsReCalculation() {
	$(document.body).trigger('sticky_kit:recalc');
}

var stickykit = {
	init : function (params) {
        if (initialized) return;

        if ('undefined' === typeof $.fn['stick_in_parent']) {
            $.getScript(Urls.staticBasePath + '/lib/jquery/jquery.sticky-kit.min.js').done((function (params) {
                return function (data) {
                    require('./stickykit').init(params);
                };
            })(params));
            return;
        }

        initializeParams(params);
        initializeCache();
        initializeEvents();
        initialized = true;
    }
}

module.exports = stickykit;
