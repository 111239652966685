'use strict';

var $cache = {},
    options = {
        addToCart: '.js-add-to-cart',
        addToWishlist: '.js-add-to-wishlist',
        productActionButtons: '.js-product_actions',
        pdpMainSelector: '#pdpMain',
        addAllToCart: '.js-add-all-to-cart',
        swatches: '.js-swatches',
        swatchSelected: '.js-swatch-selected',
        variations: '.js-variations',
        errorClass: 'error',
        tooltip: '.js-options-tooltip',
        stickedClass: 'm-sticked',
        fullWidthClass: 'm-full_width',
        productSetItemCheckClass: '.js-productset-item_check',

        quantityFieldSelector: 'input[name="Quantity"]',
        personaliseProductClass: 'js-personalised_product',
        variantLabel: '.js-variant-label'
    },

    dialog = require('./../../dialog'),
    page = require('./../../page'),
    util = require('./../../util'),
    layout = require('./../../layout'),
    CssConstants = require('./../../cssconstants'),
    Constants = require('./../constants'),
    minicart = require('./../../minicart'),
    progress = require('./../../progress');

var addtocart = {
    init: function(params) {
        if  (params && params.ignorePages && params.ignorePages.length && params.ignorePages.indexOf(window.pageContext.ns) > -1) {
            return;
        }

        initializeConfig(params);

        initializeCache();
        initializeEvents();

        if ($(options.addToCart).attr('data-disabled')) {
            $(options.addToCart).attr('title', $('.availability-msg').text());
        }
    }
};

function initializeConfig(params) {
    $.extend(options, params || {});
}

function initializeCache() {
    $cache.window = $(window);
    $cache.document = $(document);
    $cache.pdpMain = $(options.pdpMainSelector);
    $cache.addToCart = $(options.addToCart);
    $cache.addToWishlist = $(options.addToWishlist);
    $cache.addAllToCart = $(options.addAllToCart);
    $cache.productActionButtons = $(options.productActionButtons);
}

function initializeEvents() {
    $cache.document.on('click', options.addToCart, addToCartHandler);
    $cache.document.on('click', options.addToWishlist, addToWishlistHandler);

    $cache.addAllToCart.off('click');
    $cache.document.on('click', options.addAllToCart, addAllToCartHandler);

    $cache.document.on('product.variation.changed', function() {
        initializeCache();
    });
}

/**
 * @description Check if variations are selected before taking action
 */
function errorOnVariationAttributes() {
    var variants = $cache.document.find(options.variantLabel),
        variantsError = false;
    if (variants.length > 0) {
        $.each(variants, function(i, el) {
            var $el = $(el);
            if ($el.data('error')) {
                $el.addClass(CssConstants.M_ERROR);
                $el.html($el.data('error'));
                variantsError = true;
            }
        });
    }

    return variantsError;
}

/**
 * @description Handler to handle the add to wishlist event
 */
function addToWishlistHandler(e) {
    e.preventDefault();

    var href = $(this).attr('href');

    if (href) {
        location.href = href;
        return;
    }

    if (errorOnVariationAttributes()) {
        var firstError = $('.' + CssConstants.M_ERROR)[0];
        $('html,body').animate({scrollTop: $(firstError).offset().top - 80}, 'slow');
    } else {
        var _this = $(this),
            link = util.appendParamsToUrl($(this).attr('data-link'), { format: 'ajax' });

        util.progressShow($('#pdpMain'));
        $.ajax({
            type: 'GET',
            url: link,
            cache: false,
            dataType: "json",
        }).done(function(response) {
            util.progressHide($('#pdpMain'));
            _this.addClass('wishlist-added');
            var $wishlistLink = $('.js-wishlistIcon');
            $wishlistLink.attr('title', response.title);
            if ($wishlistLink.find('.js-wishlistCounter').length) {
                $('.js-wishlistCounter').text(response.amountOfItems);
            } else {
                var wishlistCounter = '<span class="js-wishlistCounter b-wishlist-counter">' + response.amountOfItems +'</span>'
                $wishlistLink.append(wishlistCounter);
            }
        })
        .fail(function (e) {
            util.progressHide($('#pdpMain'));
            console.warn(e);
        });
    }
}

/**
 * @description Handler to handle the add to cart event
 */
function addToCartHandler(e) {
    e.preventDefault();

    var $target = $(e.target);

    var $addToBagContext = $cache.document;

    if ($('.js-product-set-item').length > 0) {
        // When product sets, there will be several 'add to bag' buttons for separate items
        // and we only want to check the one we are clicking
        $addToBagContext = $(this).closest('.js-product-set-item');
    } else if ($(this).hasClass('js-add-from-tile')) {
        // Same thing for the product tiles with the 'add to bag' functionality
        $addToBagContext = $(this).closest('.js-b-product_tile');
    } else if ($('#QuickViewDialog #pdpMain').length > 0) {
        $addToBagContext = $('#QuickViewDialog #pdpMain');
    } else if ($('#AddToBagDialog').length > 0) {
        $addToBagContext = $('#AddToBagDialog');
    }

    var isProductSetItem = $target.closest('.js-product-set-item').length > 0;
    if ($target.attr('data-disabled') && !isProductSetItem) {
        $addToBagContext.find('.js-sizeselectionerror').removeClass('g-hidden');
        return false;
    }

    //try to check variation attibutes
    var variants = $addToBagContext.find(options.variantLabel);
    var variantsError = false;
    if (variants.length > 0) {
        $.each(variants, function(i, el) {
            var $el = $(el);
            if ($el.data('error')) {
                $el.addClass(CssConstants.M_ERROR);
                $el.html($el.data('error'));
                variantsError = true;
            }
        });

        if (variantsError) {
            $('.js-sizeselectionerror').removeClass('g-hidden');

            if (layout.isMobile()) {
                var firstError = $('.' + CssConstants.M_ERROR)[0];
                $('html,body').animate({scrollTop: $(firstError).offset().top - 80}, 'slow');
            }

            return;
        }
    }

    var $actionButton = $(this);
    var $form = $actionButton.closest('form');

    if ($form.valid()) {
        $cache.document.trigger('before.additem.tocart');
    } else {
        $cache.document.trigger('form.addtocart.invalid');
        return;
    }

    var ajaxResponse = addItemToCart($form);
    if (window.pageContext.ns === 'cart') {
        progress.show();

        ajaxResponse.then(function(response) {
            dialog.closeAll();

            var $cartContent = $(Constants.CLASS_CART_CONTENT);
            $cartContent.html(response);
            minicart.update(true);

            $(Constants.CLASS_CHECKOUTCART_BTN).prop({
                'disabled': $(document).find(Constants.ClASS_NO_SHIPPING_METHODS).length ? true : false
            });

            window.KlarnaOnsiteService = window.KlarnaOnsiteService || [];
            window.KlarnaOnsiteService.push({ eventName: 'refresh-placements' });

            dynamicYield.callEvent('Sync cart');

            progress.hide();
        });
    } else {
        ajaxResponse.then(function (response) {
            var $uuid = $form.find('input[name="uuid"]');
            if ($uuid.length > 0 && $uuid.val().length > 0) {
                page.refresh();
            } else {
                // do not close quickview if adding individual item that is part of product set
                // @TODO should notify the user some other way that the add action has completed successfully
                if (!$(this).hasClass('sub-product-item')) {
                    dialog.closeAll();
                }

                $cache.document.trigger('product.addedToCart', [response, $actionButton, $form]);

                dynamicYield.callEvent('Add to Cart', {
                    productId: $form.find('input[name="pid"]').val(),
                    quantity: $form.find( options.quantityFieldSelector ).val()
                });

                $cache.document.trigger('product.afterAddedToCart');
            }
        }.bind(this));
    }
}

/**
 * @description Make the AJAX request to add an item to cart
 * @param {Element} form The form element that contains the item quantity and ID data
 * @returns {Promise}
 */
function addItemToCart(form) {
    var $form = $(form),
        $qty = $form.find( options.quantityFieldSelector );

    if ($qty.length === 0 || isNaN($qty.val()) || parseInt($qty.val(), 10) === 0) {
        $qty.val('1');
    }

    var pid = $form.find('#pid').attr('value').replace('/', '\\/');
    var variantData = $form.find('#datalayer_info_product_' + pid).data('datalayerProductInfoJson');
    $(document).trigger('gtm.push.pdp.event', ['add_to_cart', +$qty.val(), variantData]);

    dynamicYield.callEvent('setAddedItem', {
        productId: $form.find('input[name="pid"]').val(),
        quantity: $qty.val()
    });

    var data = $form.serialize();
    if (window.pageContext.ns === 'cart') {
        data = util.removeParamFromURL('?' + data, 'cartAction');
        data = util.appendParamToURL(data, 'cartAction', 'refreshcart');
        data = data.substring(1);
    }

    return $.ajax({
        type: 'POST',
        url: util.ajaxUrl(Urls.addProduct),
        data: data
    });
}

/**
 * @description Handler to handle the add all items to cart event
 */
function addAllToCartHandler(e) {
    var $productForms = [];
    var $allProductForms = $('#product-set-list').find('form').each(function() {
        if( $(this).find(options.productSetItemCheckClass + ':checked').length ) {
            $productForms.push(this);
        }
    });
    e.preventDefault();

    if(!$productForms.length) {
        return;
    }

    addAllToCart($productForms, $productForms.pop());
}

function addAllToCart($allForms, $form) {
    addItemToCart($form).then(function(response) {
        if($allForms.length == 0) {
            dialog.closeAll();
            // show the final response only, which would include all the other items
            $cache.document.trigger('product.addedToCart', response);
        } else {
            addAllToCart($allForms, $allForms.pop());
        }
    });
}

module.exports = addtocart;
