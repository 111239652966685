module.exports = {
    /*
     * Global
     */
    CLASS_LOGIN_BOX: '.js-login_box',
    CLASS_ADDRESS_FORM: '.js-address_form',
    /*
     * Personal Details
     */
    CLASS_PERSONAL_DETAILS_FORM: '.js-personal_details',
    CLASS_SET_NEW_PASSWORD_FORM: '.js-set_new_password',
    CLASS_PERSONAL_DATA_SUBMIT_BUTTON: '.js-personal_data_submit_button',
    CLASS_CHANGE_PASSWORD_SUBMIT_BUTTON: '.js-change_password_submit_button',
    CLASS_BIRTHDAY: '.js-birthday',
    CLASS_BIRTHDAY_DAY: '.js-birthday_day',
    CLASS_BIRTHDAY_MONTH : '.js-birthday_month',
    CLASS_BIRTHDAY_YEAR: '.js-birthday_year',
    CLASS_VALIDATE_IGNORE: 'js-validate-ignore',
    CLASS_EMAIL_ALREADY_EXIST_ERROR: 'js-email_already_exist_error',
    CLASS_CURRENT_PASSWORD_NO_MACH_ERROR: 'js-current_password_no_match_error',
    
    /*
     * Address Book
     */ 
    CLASS_ADDRESS_MAKE_DAFAULT: '.js-address_make_default',
    CLASS_ADDRESS_INCLUDE: '.js-address_include',
    CLASS_ADDRESS_FORM: '.js-address_form',
    CLASS_ADDRESS_CREATE: '.js-address_create',
    CLASS_ADDRESS_EDIT: '.js-address_edit',
    CLASS_ADDRESS_NAME_EXIST_ERROR: 'js-address_name_exist_error',

    /*
     * Size Preference
     */ 
    CLASS_SIZE_PREFERENCE_WRAPPER: '.js-size-preference_wrapper',
    CLASS_BRA_SIZES: '.js-bra_sizes',
    CLASS_DRESS_SIZES: '.js-dress_sizes',
    CLASS_HOSIERY_SIZES: '.js-hosiery_sizes',
    CLASS_IS_SIZE_PREFERENCE : '.js-is_size-preference',
    CLASS_UPDATE_SIZE_PREFERENCE_BUTTON :'.js-update_size-preference_btn',
    CLASS_SIZE_TOOLTIP: '.js-size_tooltip',
    /*
     * Card request
     */
    CLASS_CARDREQUEST_FORM: '.js-cart_request',
    CLASS_SELECT_ADDRESS: '.js-select_address',
    /*
     * News & alerts
     */
    CLASS_NEWS_SUBSCRIPTION: '.js-news_subscription',
    CLASS_NEWS_VIP: '.js-newsletter-vip',
    CLASS_UNSUBSCRIBE: 'js-unsubscribe',
    CLASS_MESSAGES_CONTAINER: '.js-account_subscribe-messages-container',

    /*
     * Payment Settings
    */
    CLASS_PAYMENTS_LIST: '.js-payments_list',
    CLASS_ADD_CARD: '.js-add-card',
    CLASS_DELETE_CARD: '.js-delete-card',
    CLASS_PAYMENTS_LIST_POPUP_APPLY_BUTTON: '.js-payments_list-popup_apply',
    CLASS_PAYMENTS_LIST_POPUP_CANCEL_BUTTON: '.js-payments_list-popup_cancel',
    
    /*
     * Wish List
     */
    CLASS_ADD_TO_CART_WISH_LIST: '.js-add-to-cart_wish_list',

    /*
     * DCStorm
    */
    PRODUCT_NAME : ".js-product-name",
    PRODUCT_PRICE : ".js-product-price",
    PRODUCT_TOP_INFO: ".js-pdp-top_info",
    PRODUCT_CONTENT : ".js-product-content",
    CART_CONTENT : ".js-cart_content",
    MINICART_CONTENT : ".js-mini-cart-content",
    ORDER_CONTENT : ".js-order-details",
    ORDER_TOTAL : ".js-order_total",
    CART_ITEMS_CONTAINER : ".js-cart-items-container",
    
    
    /*
    * Login page 
    */
    CLASS_PASSWORD_RESET: '.js-password_reset',
    
    /*
     * Forgot Password Dialog
     */
    CLASS_REQUEST_PASSWORD_SEND_BUTTON: '.js-request_password_button'};