'use strict';

var $ = (typeof window !== "undefined" ? window['jQuery'] : typeof global !== "undefined" ? global['jQuery'] : null),
    creditCardValidator = require('./../../lib/credit-card-validator'),
    constants = require('./constants'),
    cssConstants = require('./../../cssconstants'),
    urls = (typeof window !== "undefined" ? window['Urls'] : typeof global !== "undefined" ? global['Urls'] : null),
    resources = (typeof window !== "undefined" ? window['Resources'] : typeof global !== "undefined" ? global['Resources'] : null);

var $cache = {};

function initializeCache() {
    $cache.document = $(document);
    $cache.cardTypes = {
            visa: 'Visa',
            visa_electron: 'VisaElectron',
            amex: 'Amex',
            mastercard: 'MasterCard',
            maestro: 'Maestro',
            discover: 'Discover'
        };
}

function initializeEvents() {
    $cache.document
        .on('keyup focusin', constants.CLASS_CREDIT_CARD_NUMBER + ' input', function() {
            var $this = $(this),
                paymentClass = '',
                prevPaymentClass = $this.data('prevPaymentClass'),
                $paymentMethodImage = $(constants.CLASS_PAYMENT_METHOD_IMAGE);

            $this.validateCreditCard(function(result) {
                paymentClass = result.card_type == null ? '' : result.card_type.name;
                if (prevPaymentClass || paymentClass.length) {
                    if (paymentClass.length && $cache.cardTypes[paymentClass]) {
                        var $currentImage = $paymentMethodImage.find('img'),
                            imageUrl = urls.imagesFolder + $cache.cardTypes[paymentClass] + '.png';

                        if ($currentImage.attr('src') !== imageUrl) {
                            $currentImage.attr({
                                  src: imageUrl,
                                  alt: $cache.cardTypes[paymentClass]
                            });
                        }
                        $paymentMethodImage.removeClass(cssConstants.G_HIDDEN);
                    } else {
                        $paymentMethodImage.addClass(cssConstants.G_HIDDEN);
                    }
                    $this.data('prevPaymentClass', paymentClass);
                    $(constants.CLASS_CREDIT_CARD_TYPE + ' input').val($cache.cardTypes[paymentClass]);
                } else {
                    $this.removeData('prevPaymentClass');
                    $paymentMethodImage.addClass(cssConstants.G_HIDDEN);
                }
            });
        });
}

module.exports = {
    init: function() {
        initializeCache();
        initializeEvents();
    }
};