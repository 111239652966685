'use strict';

var Constants = require('./constants'),
    CssConstants = require('./../../cssconstants'),
    util = require('./../../util'),
    page = require('./../../page'),
    progress = require('./../../progress'),
    $ = (typeof window !== "undefined" ? window['jQuery'] : typeof global !== "undefined" ? global['jQuery'] : null),
    resources = (typeof window !== "undefined" ? window['Resources'] : typeof global !== "undefined" ? global['Resources'] : null),
    urls = (typeof window !== "undefined" ? window['Urls'] : typeof global !== "undefined" ? global['Urls'] : null);

var $cache = {},
    options = {
        customerEmail: Constants.CLASS_PERSONAL_DETAILS_FORM + ' input[id$=email]',
        customerEmails: Constants.CLASS_PERSONAL_DETAILS_FORM + ' input[id*=email]',
        currentPassword: Constants.CLASS_SET_NEW_PASSWORD_FORM + ' input[id*=currentpassword]',
        customerPassword: Constants.CLASS_PERSONAL_DETAILS_FORM + ' input[id*=password]'
    };

function initializeCache() {
    $cache.document = $(document);
}

function updateCustomerDetailsHandler(e) {
    var $form = $(this),
        $personalDataSubmitBtn = $form.find(Constants.CLASS_PERSONAL_DATA_SUBMIT_BUTTON),
        $email = $form.find('input[id$=email]'),
        $currentPassword = $form.find('input[id*=password]');

    e.preventDefault();
    
    if (!$form.valid()) {
        return;
    }
    
    updateBirthday(Constants.CLASS_BIRTHDAY_DAY, Constants.CLASS_BIRTHDAY_MONTH, Constants.CLASS_BIRTHDAY_YEAR, Constants.CLASS_BIRTHDAY);

    var formData = $form.serializeArray();

    progress.show($form);

    $.ajax({
        type: 'POST',
        url: util.ajaxUrl(urls.updatePersonalDetails),
        data: formData
    }).done(function(response) {
        if ($($.parseHTML(response)).find(Constants.CLASS_LOGIN_BOX).length) {
            page.refresh();
        } else {
            if (response && response.success) {
                $currentPassword.val('');
                $personalDataSubmitBtn
                    .addClass(CssConstants.M_UPDATED)
                    .text(resources.SAVED);
            } else if (response && response.userNameIsAlreadyExist) {
                var $customerEmail = $(options.customerEmail);
                
                $customerEmail
                    .removeClass(CssConstants.VALID)
                    .addClass(CssConstants.ERROR)
                    .parent().find('span.' + CssConstants.ERROR).remove();
                $customerEmail.after('<span class="'+ CssConstants.ERROR + ' ' + Constants.CLASS_EMAIL_ALREADY_EXIST_ERROR + '">' + resources.EMAIL_ALREADY_EXIST_ERROR + '</span>');
            } else {
                $currentPassword
                    .removeClass(CssConstants.VALID)
                    .addClass(CssConstants.ERROR)
                    .parent().find('span.' + CssConstants.ERROR).remove();
                $currentPassword.after('<span class="'+ CssConstants.ERROR + ' ' + Constants.CLASS_CURRENT_PASSWORD_NO_MACH_CLASS + '">' + resources.CURRENT_PASSWORD_NO_MACH + '</span>');
            }           
        }
        progress.hide();
    });
}

function changePasswordHandler(e) {
    var $form = $(this),
        $changePasswordSubmitBtn = $form.find(Constants.CLASS_CHANGE_PASSWORD_SUBMIT_BUTTON),
        $currentPassword = $form.find('input[id*=currentpassword]'),
        $newpassword = $form.find('input[id*=newpassword]'),
        $newpasswordConfirm = $form.find('input[id*=newpasswordconfirm]');

    e.preventDefault();
    
    if (!$form.valid()) {
        return;
    }
    
    var formData = $form.serializeArray();

    progress.show($form);

    $.ajax({
        type: 'POST',
        url: util.ajaxUrl(urls.changePassword),
        data: formData
    }).done(function(response) {
        if ($($.parseHTML(response)).find(Constants.CLASS_LOGIN_BOX).length) {
            page.refresh();
        } else {
            if (response && response.success) {
                $currentPassword.val('');
                $newpassword.val('');
                $newpasswordConfirm.val('');
                $changePasswordSubmitBtn
                    .addClass(CssConstants.M_UPDATED)
                    .text(resources.SAVED);
            } else {
                $currentPassword
                    .removeClass(CssConstants.VALID)
                    .addClass(CssConstants.ERROR)
                    .parent().find('span.' + CssConstants.ERROR).remove();
                $currentPassword.after('<span class="'+ CssConstants.ERROR + ' ' + Constants.CLASS_CURRENT_PASSWORD_NO_MACH_CLASS + '">' + resources.CURRENT_PASSWORD_NO_MACH + '</span>');
            }
            progress.hide();
        }
    });
}

function updateBirthday(day, month, year, birthday) {
    var $day = $(day),
        $month = $(month),
        $year = $(year),
        $birthday = $(birthday);

    if($day.val() && $month.val() && $year.val()) {
        $birthday.val($month.val() + '/' + $day.val() + '/' + $year.val());
    } else {
        $birthday.val('');
    }
}

function resetButtonView() {
    var $this = $(this),
        $submitBtn = $this.closest('form').find(Constants.CLASS_PERSONAL_DATA_SUBMIT_BUTTON + ', ' + Constants.CLASS_CHANGE_PASSWORD_SUBMIT_BUTTON);

    if ($submitBtn.hasClass(CssConstants.M_UPDATED)) {
        $submitBtn
            .removeClass(CssConstants.M_UPDATED)
            .text($submitBtn.val());
    }
}

function updateAddress() {
    var $form = $(Constants.CLASS_CARDREQUEST_FORM);
    var selected = $(this).children(':selected').first();
    var selectedAddress = $(selected).data('address');
    if (!selectedAddress) { 
        return; 
    }
    util.fillAddressFields(selectedAddress, $form);
    $form.validate().form();
}

function initializeEvents() {
    $cache.document
        .on('change', Constants.CLASS_PERSONAL_DETAILS_FORM + ' input, select', resetButtonView)
        .on('change', Constants.CLASS_SELECT_ADDRESS, updateAddress)
        .on('blur', options.customerEmail, function() {
            $(this).parent().find('.' + Constants.CLASS_EMAIL_ALREADY_EXIST_ERROR).remove();
        })
        .on('blur', options.customerPassword, function() {
            $(this).parent().find('.' + Constants.CLASS_CURRENT_PASSWORD_NO_MACH_CLASS).remove();
        });
    
    $(Constants.CLASS_PERSONAL_DETAILS_FORM).off('submit')
        .on('submit', updateCustomerDetailsHandler)
        .on('submit', function() {
            $(this).validate().form();
        });
    
    $cache.document
        .on('change', Constants.CLASS_SET_NEW_PASSWORD_FORM + ' input, select', resetButtonView)
        .on('blur', options.currentPassword, function() {
            $(this).parent().find('.' + Constants.CLASS_CURRENT_PASSWORD_NO_MACH_CLASS).remove();
        });

    $(Constants.CLASS_SET_NEW_PASSWORD_FORM).off('submit')
        .on('submit', changePasswordHandler)
        .on('submit', function() {
            $(this).validate().form();
        });
}

module.exports = {
    init: function() {
        initializeCache();
        initializeEvents();
    }
};