module.exports = {
		
	/*
	 * Selectors for Google Maps API
	 */
		
	CLASS_COUNTRY_FIELD : '.js-storelocator-country',
	CLASS_AUTOCOMPLETE_FIELD : '.js-storelocator-address',
	CLASS_COUNTRY_ADDRESS : '.js-counrty-address-submit',
	ID_STORELOCATOR_COUNTRY : '#js-gmaps-storelocator-country',
	CLASS_STORELOCATOR_DATA : '.js-storelocator-data',
	CLASS_ERROR_MESSAGE: '.js-storelocator_error',
	CLASS_STORELOCATOR_WRAPPER: '.js-storelocator_reload_wrapper',
	
	/*
	 * Pagination selectors 
	 */
	
	CLASS_PAGE : '.js-page',
	CLASS_PAGINATION : '.js-pagination',	
	CLASS_PAGE_CONTROL : '.js-page_control',
	CLASS_NEXT_PAGE : '.js-page_control_next',
	CLASS_PREV_PAGE : '.js-page_control_prev',
	
	/*
	 * Selectors for detailed store block
	 */
	
	ID_STORELOCTATOR_DETAILS_MAP : '#js-store-locator-details-map',
	CLASS_STORELOCATOR_DETAILS_DATA : '.js-store-locator-details-data',
	CLASS_STORE_DETAILS : '.js-storedetails',
	CLASS_STORELOCATOR_CONTENT_WRAPPER : '.js-storelocator-content_wrapper',
	CLASS_STORE_DETAILS_LINK : '.js-store-details-link',
	CLASS_STOREDETAILS_CLOSE : '.js-storedetails-close',
	CLASS_ALL_STORES : '.js-all_stores',
	
	/*
	 * Selectors for footer section of storelocator
	 */
	
	CLASS_FOOTER_AUTOCOMPLETE: '.js-footer-autocomplete',
	CLASS_FOOTER_STORELOCATOR: '.js-footer-storelocator'
};