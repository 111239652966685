var $ = (typeof window !== "undefined" ? window['jQuery'] : typeof global !== "undefined" ? global['jQuery'] : null);

function initializeEvents() {
    var $day = $('.js-birthday_day'),
        $month = $('.js-birthday_month'),
        $year = $('.js-birthday_year'),
        $birthday = $('.js-birthday');

    $('.js-gdpr_request')
        .on('submit', function() {
            $birthday.val($month.val() + '/' + $day.val() + '/' + $year.val());
        });
}

module.exports = {
    init: function() {
        initializeEvents();
    }
};
