'use strict';

var Constants = require('./constants'),
    util = require('./util');

var $cache = {},
    options = {
        containers: '.header-search, ' + Constants.CLASS_NOHITS_SEARCH_CONTAINER
    };

function initializeCache() {
    $cache.document = $(document);
    $cache.searchContainers = $cache.document.find(options.containers);
    $cache.stickyHeader = $cache.document.find(Constants.CLASS_STICKY_HEADER);
    $cache.mobileInputSearch = $cache.document.find(Constants.CLASS_MOBILE_INPUT_SEARCH);
    $cache.html = $cache.document.find(Constants.CLASS_HTML);
}

function initializeEvents() {
    $cache.document.on('click', Constants.CLASS_SEARCH_TOGGLE, function () {
        if(!$cache.stickyHeader.hasClass('is-active-search')) {
            $cache.stickyHeader.toggleClass('is-active-search');
            $cache.html.toggleClass('is-search-active-html');
            $cache.mobileInputSearch.focus();
        }
    })
    .on('click', Constants.CLASS_SCROLL, function (e) {
        e.preventDefault();
        util.scrollBrowser($cache.document.find($(this).attr('href')).offset().top - $(Constants.CLASS_HEADER).height());
    })

    $cache.mobileInputSearch.blur(function() {
        $cache.stickyHeader.removeClass('is-active-search');
        $cache.html.removeClass('is-search-active-html');
    });

    $cache.searchContainers.on('submit', 'form', function (e) {
        if (!$(this).find('input[type=text]').val().length) {
            e.preventDefault();
        }
    });
}

module.exports = {
    init: function () {
        initializeCache();
        initializeEvents();
    }
};
