
'use strict';
var dialog = require('./../../../../../app_storefront_core_ext/cartridge/js/dialog');

function initializeEvents() {
    // laybuy popup
    $(document).on('click', '.laybuy-pdp-modal-trigger', function(e) {
        e.preventDefault();
        dialog.open({
            url: $(e.target).attr('data-url'),
            options: {
                width: 775,
                dialogClass: 'b-laybuy-dialog'
            }
        });
    });
}

var laybuy = {
    initializeEvents: initializeEvents,
    init: function () {
        initializeEvents();
    }
};

module.exports = laybuy;
