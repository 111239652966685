var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __getOwnPropNames = Object.getOwnPropertyNames;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __export = (target, all) => { 
  for (var name in all)
    __defProp(target, name, { get: all[name], enumerable: true });
};
var __copyProps = (to, from, except, desc) => {
  if (from && typeof from === "object" || typeof from === "function") {
    for (let key of __getOwnPropNames(from))
      if (!__hasOwnProp.call(to, key) && key !== except)
        __defProp(to, key, { get: () => from[key], enumerable: !(desc = __getOwnPropDesc(from, key)) || desc.enumerable });
  }
  return to;
};
var __toCommonJS = (mod) => __copyProps(__defProp({}, "__esModule", { value: true }), mod);

// src/index.ts
var src_exports = {};
__export(src_exports, {
  downloadZip: () => downloadZip,
  predictLength: () => predictLength
});
module.exports = __toCommonJS(src_exports);

// src/polyfills.ts
if (!("stream" in Blob.prototype))
  Object.defineProperty(Blob.prototype, "stream", {
    value() {
      return new Response(this).body;
    }
  });

// src/utils.ts
var makeBuffer = (size) => new DataView(new ArrayBuffer(size));
var makeUint8Array = (thing) => new Uint8Array(thing.buffer || thing);
var encodeString = (whatever) => new TextEncoder().encode(String(whatever));

// src/input.ts
function normalizeInput(input, modDate) {
  if (modDate !== void 0 && !(modDate instanceof Date))
    modDate = new Date(modDate);
  if (input instanceof File)
    return {
      modDate: modDate || new Date(input.lastModified),
      bytes: input.stream()
    };
  if (input instanceof Response)
    return {
      modDate: modDate || new Date(input.headers.get("Last-Modified") || Date.now()),
      bytes: input.body
    };
  if (modDate === void 0)
    modDate = new Date();
  else if (isNaN(modDate))
    throw new Error("Invalid modification date.");
  if (typeof input === "string")
    return { modDate, bytes: encodeString(input) };
  if (input instanceof Blob)
    return { modDate, bytes: input.stream() };
  if (input instanceof Uint8Array || input instanceof ReadableStream)
    return { modDate, bytes: input };
  if (input instanceof ArrayBuffer || ArrayBuffer.isView(input))
    return { modDate, bytes: makeUint8Array(input) };
  if (Symbol.asyncIterator in input)
    return { modDate, bytes: ReadableFromIter(input) };
  throw new TypeError("Unsupported input format.");
}
function ReadableFromIter(iter) {
  const gen = "next" in iter ? iter : iter[Symbol.asyncIterator]();
  return new ReadableStream({
    async pull(controller) {
      let pushedSize = 0;
      while (controller.desiredSize > pushedSize) {
        const next = await gen.next();
        if (next.value) {
          const chunk = normalizeChunk(next.value);
          controller.enqueue(chunk);
          pushedSize += chunk.byteLength;
        } else {
          controller.close();
          break;
        }
      }
    },
    async cancel(err) {
      var _a;
      try {
        await ((_a = gen.throw) == null ? void 0 : _a.call(gen, err));
      } catch (_) {
      }
    }
  });
}
function normalizeChunk(chunk) {
  if (typeof chunk === "string")
    return encodeString(chunk);
  if (chunk instanceof Uint8Array)
    return chunk;
  return makeUint8Array(chunk);
}

// src/metadata.ts
function normalizeMetadata(input, name, size) {
  let [encodedName, nameIsBuffer] = normalizeName(name);
  if (input instanceof File)
    return {
      encodedName: fixFilename(encodedName || encodeString(input.name)),
      uncompressedSize: input.size,
      nameIsBuffer
    };
  if (input instanceof Response) {
    const contentDisposition = input.headers.get("content-disposition");
    const filename = contentDisposition && contentDisposition.match(/;\s*filename\*?=["']?(.*?)["']?$/i);
    const urlName = filename && filename[1] || input.url && new URL(input.url).pathname.split("/").findLast(Boolean);
    const decoded = urlName && decodeURIComponent(urlName);
    const length = size || +input.headers.get("content-length");
    return { encodedName: fixFilename(encodedName || encodeString(decoded)), uncompressedSize: length, nameIsBuffer };
  }
  encodedName = fixFilename(encodedName);
  if (typeof input === "string")
    return { encodedName, uncompressedSize: encodeString(input).length, nameIsBuffer };
  if (input instanceof Blob)
    return { encodedName, uncompressedSize: input.size, nameIsBuffer };
  if (input instanceof ArrayBuffer || ArrayBuffer.isView(input))
    return { encodedName, uncompressedSize: input.byteLength, nameIsBuffer };
  return { encodedName, uncompressedSize: getUncompressedSize(input, size), nameIsBuffer };
}
function getUncompressedSize(input, size) {
  if (size > -1) {
    return size;
  }
  return input ? void 0 : 0;
}
function normalizeName(name) {
  if (!name)
    return [void 0, false];
  if (name instanceof Uint8Array)
    return [name, true];
  if (ArrayBuffer.isView(name) || name instanceof ArrayBuffer)
    return [makeUint8Array(name), true];
  return [encodeString(name), false];
}
function fixFilename(encodedName) {
  if (!encodedName || encodedName.every((c) => c === 47))
    throw new Error("The file must have a name.");
  while (encodedName[encodedName.length - 1] === 47)
    encodedName = encodedName.subarray(0, -1);
  return encodedName;
}

// src/crc32.ts
var CRC_TABLE = new Uint32Array(256);
for (let i = 0; i < 256; ++i) {
  let crc = i;
  for (let j = 0; j < 8; ++j) {
    crc = crc >>> 1 ^ (crc & 1 && 3988292384);
  }
  CRC_TABLE[i] = crc;
}
function crc32(data, crc = 0) {
  crc = crc ^ -1;
  for (var i = 0, l = data.length; i < l; i++) {
    crc = crc >>> 8 ^ CRC_TABLE[crc & 255 ^ data[i]];
  }
  return (crc ^ -1) >>> 0;
}

// src/datetime.ts
function formatDOSDateTime(date, into, offset = 0) {
  const dosTime = date.getSeconds() >> 1 | date.getMinutes() << 5 | date.getHours() << 11;
  const dosDate = date.getDate() | date.getMonth() + 1 << 5 | date.getFullYear() - 1980 << 9;
  into.setUint16(offset, dosTime, true);
  into.setUint16(offset + 2, dosDate, true);
}

// src/zip.ts
var fileHeaderSignature = 1347093252;
var fileHeaderLength = 30;
var descriptorSignature = 1347094280;
var descriptorLength = 16;
var centralHeaderSignature = 1347092738;
var centralHeaderLength = 46;
var endSignature = 1347093766;
var endLength = 22;
var fileOverhead = descriptorLength + fileHeaderLength + centralHeaderLength;
function contentLength(files) {
  var _a;
  let length = endLength;
  for (const file of files) {
    if (!file.encodedName)
      throw new Error("Every file must have a non-empty name.");
    if (isNaN((_a = file.uncompressedSize) != null ? _a : NaN))
      throw new Error(`Missing size for file "${new TextDecoder().decode(file.encodedName)}".`);
    length += file.encodedName.length * 2 + file.uncompressedSize + fileOverhead;
  }
  return length;
}
function flagNameUTF8({ encodedName, nameIsBuffer }, buffersAreUTF8) {
  return (!nameIsBuffer || (buffersAreUTF8 != null ? buffersAreUTF8 : tryUTF8(encodedName))) * 8;
}
var UTF8Decoder = new TextDecoder("utf8", { fatal: true });
function tryUTF8(str) {
  try {
    UTF8Decoder.decode(str);
  } catch (e) {
    return false;
  }
  return true;
}
async function* loadFiles(files, options) {
  const centralRecord = [];
  let offset = 0;
  let fileCount = 0;
  for await (const file of files) {
    const flags = flagNameUTF8(file, options.buffersAreUTF8);
    yield fileHeader(file, flags);
    yield file.encodedName;
    yield* fileData(file);
    yield dataDescriptor(file);
    centralRecord.push(centralHeader(file, offset, flags));
    centralRecord.push(file.encodedName);
    fileCount++;
    offset += fileHeaderLength + descriptorLength + file.encodedName.length + file.uncompressedSize;
  }
  let centralSize = 0;
  for (const record of centralRecord) {
    yield record;
    centralSize += record.length;
  }
  const end = makeBuffer(endLength);
  end.setUint32(0, endSignature);
  end.setUint16(8, fileCount, true);
  end.setUint16(10, fileCount, true);
  end.setUint32(12, centralSize, true);
  end.setUint32(16, offset, true);
  yield makeUint8Array(end);
}
function fileHeader(file, flags = 0) {
  const header = makeBuffer(fileHeaderLength);
  header.setUint32(0, fileHeaderSignature);
  header.setUint32(4, 335546368 | flags);
  formatDOSDateTime(file.modDate, header, 10);
  header.setUint16(26, file.encodedName.length, true);
  return makeUint8Array(header);
}
async function* fileData(file) {
  let { bytes } = file;
  if ("then" in bytes)
    bytes = await bytes;
  if (bytes instanceof Uint8Array) {
    yield bytes;
    file.crc = crc32(bytes, 0);
    file.uncompressedSize = bytes.length;
  } else {
    file.uncompressedSize = 0;
    const reader = bytes.getReader();
    while (true) {
      const { value, done } = await reader.read();
      if (done)
        break;
      file.crc = crc32(value, file.crc);
      file.uncompressedSize += value.length;
      yield value;
    }
  }
}
function dataDescriptor(file) {
  const header = makeBuffer(16);
  header.setUint32(0, descriptorSignature);
  header.setUint32(4, file.crc, true);
  header.setUint32(8, file.uncompressedSize, true);
  header.setUint32(12, file.uncompressedSize, true);
  return makeUint8Array(header);
}
function centralHeader(file, offset, flags = 0) {
  const header = makeBuffer(centralHeaderLength);
  header.setUint32(0, centralHeaderSignature);
  header.setUint32(4, 352523264);
  header.setUint16(8, 2048 | flags);
  formatDOSDateTime(file.modDate, header, 12);
  header.setUint32(16, file.crc, true);
  header.setUint32(20, file.uncompressedSize, true);
  header.setUint32(24, file.uncompressedSize, true);
  header.setUint16(28, file.encodedName.length, true);
  header.setUint16(40, 33204, true);
  header.setUint32(42, offset, true);
  return makeUint8Array(header);
}

// src/index.ts
function normalizeArgs(file) {
  return file instanceof File || file instanceof Response ? [[file], [file]] : [[file.input, file.name, file.size], [file.input, file.lastModified]];
}
function* mapMeta(files) {
  for (const file of files)
    yield normalizeMetadata(...normalizeArgs(file)[0]);
}
async function* mapFiles(files) {
  for await (const file of files) {
    const [metaArgs, dataArgs] = normalizeArgs(file);
    yield Object.assign(normalizeInput(...dataArgs), normalizeMetadata(...metaArgs));
  }
}
var predictLength = (files) => contentLength(mapMeta(files));
function downloadZip(files, contentType, options = {}) {
  const headers = { "Content-Type": contentType || "application/zip", "Content-Disposition": "attachment" };
  if (Number.isInteger(options.length) && options.length > 0)
    headers["Content-Length"] = options.length;
  if (options.metadata)
    headers["Content-Length"] = predictLength(options.metadata);
  return new Response(ReadableFromIter(loadFiles(mapFiles(files), options)), { headers });
}
