'use strict';

var Constants = require('./constants'),
    CssConstants = require('./../../cssconstants'),
    util = require('./../../util'),
    urls = (typeof window !== "undefined" ? window['Urls'] : typeof global !== "undefined" ? global['Urls'] : null);

var $cache = {};

function initializeCache() {
    $cache.document = $(document);
}

function setDefaultAddress() {
    $.ajax({
        type: 'POST',
        url: urls.makeDefaultAddress,
        data: {
            addressId: $(this).data('addressid')
        }
    }).done(function(response) {
        $(Constants.CLASS_ADDRESS_INCLUDE).html(response);
    });
}

function initializeEvents() {
    $cache.document.on('click', Constants.CLASS_ADDRESS_MAKE_DAFAULT, setDefaultAddress);
}

module.exports = {
    init: function() {
        initializeCache();
        initializeEvents();
    }
};