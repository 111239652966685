module.exports = {
    WRAPPER: '#wrapper',
    MAIN: '#main',
    M_SHOW: 'm-show',
    M_ACTIVE: 'm-active',
    M_UPDATED: 'm-updated',
    G_HIDDEN: 'g-hidden',
    M_ERROR: 'm-error',
    M_DISABLED: 'm-disabled',
    M_SELECTED: 'm-selected',
    M_UNSELECTABLE: 'm-unselectable',
    B_COOKIES_JS_ERROR: 'b-cookies_js_error',
    B_COOKIES_NOTICE: 'b-cookies_notice',
    M_CHECKED: 'm-checked',
    M_RELATIVE: 'm-relative',
    M_HIDDEN: 'm-hidden',
    M_REFINE_BY: 'm-refine-by',
    B_REFINEMENTS_CLOSE_MOBILE: 'b-refinements-close_mobile',
    B_REFINEMENTS_APPLY_MOBILE: 'b-refinements-apply_mobile',
    B_REFINEMENTS_CLEARALL_MOBILE: 'b-refinements-clearall_mobile',
    B_REFINEMENTS_FULLSCREEN_MOBILE: 'b-refinements-fullscreen_mobile',
    B_REFINEMENTS_BUTTONS_MOBILE: 'b-refinements-buttons_mobile',
    ERROR: 'error',
    VALID: 'valid',
    B_RESET_PASSWORD: 'b-reset_password',
    REQUIRED: 'required',
    M_FULLORDER: 'm-fullorder',
    DIALOG_CONTENT: 'ui-dialog-content',
    M_SUBSCRIBED: 'm-subscribed',
    C_ZOOM: 'in-zoom'
};
