var base = {
    SIMPLE_CLASS_SRS_ITEMS: 'js-search-result-items',
    SIMPLE_CLASS_SRS_ITEM: 'js-search-result-item'
};

module.exports = {
    /*
     * Search Items
     */
    SIMPLE_CLASS_SRS_ITEMS: base.SIMPLE_CLASS_SRS_ITEMS,
    SIMPLE_CLASS_SRS_ITEM: base.SIMPLE_CLASS_SRS_ITEM,
    CLASS_SRS_ITEMS: '.' + base.SIMPLE_CLASS_SRS_ITEMS,
    CLASS_SRS_ITEM: '.' + base.SIMPLE_CLASS_SRS_ITEM
};