'use strict';

var sitePreferences = (typeof window !== "undefined" ? window['SitePreferences'] : typeof global !== "undefined" ? global['SitePreferences'] : null),
    $ = (typeof window !== "undefined" ? window['jQuery'] : typeof global !== "undefined" ? global['jQuery'] : null),
    urls = (typeof window !== "undefined" ? window['Urls'] : typeof global !== "undefined" ? global['Urls'] : null),
    ajax = require('./../ajax'),
    JSConstants = require('./constants'),
    CssConstants = require('./../cssconstants'),
    constants = (typeof window !== "undefined" ? window['Constants'] : typeof global !== "undefined" ? global['Constants'] : null),
    carousel = require('./../carousel'),
    util = require('./../util'),
    page = require('./../page'),
    options = {
        autocompleteField: JSConstants.CLASS_FOOTER_AUTOCOMPLETE,
        footerStorelocatorForm: JSConstants.CLASS_FOOTER_STORELOCATOR
    },
    $cache = {};

const googleMapsUtils = require('./../googleMapsUtils');

function initializeConfig(params) {
    options = $.extend({}, options, params);
}

function initCache(){
    $cache.document = $(document);
}

/**
 * Init google map for store from page designer
 */
function initStoreGoogleMap() {
    if (!window.pageContext.type) {
        const googleMapsLoader = googleMapsUtils.getGoogleMapsLoader();
        if (!googleMapsLoader) {
            return;
        }

        googleMapsLoader.load();
    }
}

function initializeEvents(){
    if (!$(options.footerStorelocatorForm).length) {
        return;
    }

    const googleMapsLoader = googleMapsUtils.getGoogleMapsLoader();
    if (!googleMapsLoader) {
        return;
    }

    googleMapsLoader.load().then(function(google) {
        new google.maps.places.Autocomplete($(options.autocompleteField).get(0));
    });

    $(options.footerStorelocatorForm).on('submit', function(e, triggeredAction) {
        if (triggeredAction) {
            return true;
        }
        e.preventDefault();
        queryAddress(this);
    });
}

function queryAddress(form){
    var $form = $(form),
        csrf_token = $form.find('input[name$="csrf_token"]').val(),
        autocomplete = $form.find(options.autocompleteField).val();

    var geocoder = new google.maps.Geocoder();
    geocoder.geocode({'address': autocomplete}, function(results, status) {
        if (results.length > 0) {
            $.addFields($form, {
                'latitude': results[0].geometry.location.lat(),
                'longitude': results[0].geometry.location.lng(),
                'country': results[0].address_components[results[0].address_components.length - 1].short_name,
                'fromfooter': true,
                'prefilled': autocomplete,
                'csrf_token': csrf_token
            });
            $form.trigger('submit', true);
        }
    });
    return false;
}

$.extend({
    addFields: function(form, args){
        $.each( args, function(key, value) {
            var field = $('<input></input>');

            field.attr('type', 'hidden');
            field.attr('name', key);
            field.attr('value', value);

            form.append(field);
        });
    }
});

module.exports = {
    init: function(params) {
        initializeConfig(params);
        initCache();
        initStoreGoogleMap();
        initializeEvents();
    }
}
