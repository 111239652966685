'use strict';

/**
 * @description mention me
 * @return {Void}
 */
function initMentionMe() {
    if(window.mentionMeSrc) {
        var js, scriptElements = document.body.getElementsByTagName('script');

        if (!document.getElementById('mentionMe')) {
            js = document.createElement('script'); 
            js.id = 'mentionMe';
            js.src = window.mentionMeSrc;
            scriptElements[0].parentNode.insertBefore(js, scriptElements[scriptElements.length-1]);
        }
        
        delete window.mentionMeSrc;
    }
}

module.exports = {
    init: function () {
        initMentionMe();
    }
};
