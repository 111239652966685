'use strict';
var $ = (typeof window !== "undefined" ? window['jQuery'] : typeof global !== "undefined" ? global['jQuery'] : null),
    userPreferences = (typeof window !== "undefined" ? window['User'] : typeof global !== "undefined" ? global['User'] : null),
    ajax = require('./../../ajax'),
    util = require('./../../util'),
    progress = require('./../../progress'),
    zoom = require('./zoom'),
    carousel = require('./../../carousel'),
    productinfoaccordion = require('./productinfoaccordion'),
    productImages = require('./images'),
    navigation = require('./navigation'),
    Constants = require('./constants'),
    BasicConstants = require('./../../constants'),
    detailsTabs = require('./detailsTabs'),
    CssConstants = require('./../../cssconstants');

    var initialized = false;
    var primarySizeInput = null;
    var enableMatchingSetTabsPdp = false;
    var options = {
        productContainer: Constants.CLASS_PRD_CONTAINER,
        productSetProductContainer: Constants.CLASS_PRD_SET_CONTAINER,
        productImage: Constants.CLASS_PRD_IMAGES_MAIN,
        swatchAnchor: Constants.CLASS_PRD_SWATCH_ANCHOR,
        swatchAnchorColor: Constants.CLASS_PRD_SWATCH_ANCHOR_COLOR,
        swatchSelect: Constants.CLASS_PRD_SWATCH_SELECT,
        productVariationContainer: Constants.CLASS_PRD_VARIATIONS_CONTAINER
    };

function updateImages($content) {
    zoom.init();
    navigation.init();
    productImages.updateTile($content);
    productImages.updateSizeGrid();
}

function updateVariant($container, href) {
    if (!$container || !href) {
        return;
    }

    var source = $('#QuickViewDialog:not(:empty)').length ? 'quickview' : '';
    var $productForm = $container.find(Constants.CLASS_PRD_FORM);
    var qty = $productForm.find(Constants.CLASS_PRD_QUANTITY_FIELD).first().val();
    var params = {
        Quantity: isNaN(qty) ? '1' : qty,
        format: 'ajax',
        isSet: variantFromPS($container),
        productlistid: $productForm.find('input[name="productlistid"]').first().val(),
        source: source
    };

    progress.show($container);

    ajax.load({
        url: util.appendParamsToUrl(href, params),
        target: $container.find(Constants.CLASS_PRD_CONTENT),
        timeoutProgress: 2500,
        callback: function () {

            var currentBreadcrumbs = $('.js-product-breadcrumbs');
            var newoneBreadcrumbs = $('.js-product-breadcrumbs-returned');

            $(currentBreadcrumbs[0]).html(newoneBreadcrumbs.find('.js-product-breadcrumbs').html());

            var $mainSliderContainer = $container.find('.js-product-images_container').eq(0);
            var $mainSlider = $mainSliderContainer.find('.js-carousel');
            var $thumsSlider = $mainSliderContainer.find('.js-product-images_thumbs');
            if ($mainSlider.hasClass('slick-initialized') && $thumsSlider.hasClass('slick-initialized')) {
                updateMainAndThumbnailCarousels($container);
            } else {
                setTimeout(function self() {
                    if ($mainSlider.hasClass('slick-initialized') && $thumsSlider.hasClass('slick-initialized')) {
                        updateMainAndThumbnailCarousels($container);

                        return;
                    }

                    return setTimeout(self, 1000);
                }, 1000);
            }

            $(document).trigger('product.updated');

            var pid = variantFromPS($container.find(Constants.CLASS_PRD_CONTENT))
                ? $container.find('#pid').attr('value')
                : $container.find('.product-add-to-cart.b-product_actions').find('#pid').attr('value');

            // updates invalid slash from jquery syntax
            pid = pid.replace('/', '\\/');

            var $varGroupPid = $container.find('.l-pdp-bottom_info').eq(0).find('#varGroupPid');
            var $selectedVariant = $container.find('.l-pdp-bottom_info').eq(0).find('#selectedVariant');

            var choosedColorAttr = $('.js-product-swatch_anchor_color.m-selected');

            var isSizeBlockToHide = choosedColorAttr.attr('data-is-size-block-hide');

            if (isSizeBlockToHide === 'true') {
                $('.js-size-block').addClass('hidden');
            } else {
                $('.js-size-block').removeClass('hidden');
            }

            setTimeout(function() {
                if (source !== 'quickview') {
                    productinfoaccordion.updateProductInfoAccordion($container, $selectedVariant.val() || $varGroupPid.val() || pid);
                }

                if (variantFromPS($container.find(Constants.CLASS_PRD_CONTENT))) {
                    updatePromoMsg($container, true);
                } else {
                    updatePromoMsg();
                }
            }, 0);
            updateKlarnaMessages();

            var $imagesDataContainer = $container.find(Constants.CLASS_PRD_IMAGES_CONTAINER).first();

            if (!variantFromPS($container) && $imagesDataContainer.length) { // These won't be available for sets
                updateImages($imagesDataContainer);
                carousel.initCarousels($container.find(BasicConstants.CLASS_CAROUSEL));
            }

            scrollToActiveTab();

            var updatedVariant = $container.find('#datalayer_info_product_' + pid).data('datalayerProductInfoJson');
            $(document).trigger('gtm.push.pdp.event', ['view_item_update', 0, updatedVariant]);


            var $varGroupPidInput = $container.find('#varGroupPid');
            var spaEventData = $varGroupPidInput.val();

            dynamicYield.callSPAEvent({
                context: {
                    type: "PRODUCT",
                    data: [spaEventData]
                },
                url: util.appendParamsToUrl(href, params),
                countAsPageview: true
            });
        }
    });
}

function scrollToActiveTab() {
    if (!util.isMobile()) {
        return;
    }

    var $productTabList = $('.js-pdp-top_info').find('.product-tab__list');
    if (!$productTabList.length) {
        return;
    }

    var $activeTab = $productTabList.find('.active');
    if (!$activeTab.length) {
        return;
    }


    var positions = $activeTab[0].getBoundingClientRect();
    if (positions.right > document.body.clientWidth) {
        $productTabList[0].scrollLeft = positions.right;
    } else if (positions.left < 0) {
        $productTabList[0].scrollLeft = positions.left;
    }
}

function updateURLForTab(href, info) {
    var urlParams = {
        pid: info.pid,
        baseProductID: info.baseProductID,
        cgid: info.cgid
    };

    Object.keys(info).forEach(function(key) {
        if (key in urlParams) {
            return;
        }

        var param = ['dwvar', info.pid, key].join('_');
        urlParams[param] = info[key];
    });

    href = util.appendParamsToUrl(href, urlParams);

    return href;
}

function updateVariantByTab($container, href) {
    if (!$container || !href) {
        return;
    }

    if (href === '#') {
        return;
    }

    progress.show($container);

    ajax.load({
        url: href,
        target: $container.find(Constants.CLASS_PRD_CONTENT),
        timeoutProgress: 2500,
        callback: function () {

            var currentBreadcrumbs = $('.js-product-breadcrumbs');
            var newoneBreadcrumbs = $('.js-product-breadcrumbs-returned');

            currentBreadcrumbs.eq(0).html(newoneBreadcrumbs.find('.js-product-breadcrumbs').html());

            $(document).trigger('product.updated');
            updateKlarnaMessages();

            scrollToActiveTab();

            var $imagesDataContainer = $container.find(Constants.CLASS_PRD_IMAGES_CONTAINER).first();
            if (!variantFromPS() && $imagesDataContainer.length) { // These won't be available for sets
                updateImages($imagesDataContainer);
                carousel.initCarousels($container.find(BasicConstants.CLASS_CAROUSEL));
            }
        }
    });
}

function initializeParams(params) {
    options = $.extend(true, {}, options, params);
    enableMatchingSetTabsPdp = $(options.productVariationContainer).data('enableMatchingSetTabsPdp');

    return;
}

function updateKlarnaMessages() {
    window.KlarnaOnsiteService = window.KlarnaOnsiteService || [];
    window.KlarnaOnsiteService.push({ eventName: 'refresh-placements' });
}

function initializeEvents () {
    $(document).on('click', options.swatchAnchor + ':not(' + Constants.CLASS_PRD_SWATCH_UNSELECTABLE + ')', function (e) {
        e.preventDefault();

        var $this = $(this);

        //for Bra Products only
        //on hold BXAV-1002
        var braSize = $this.data('brasize');
        if (braSize) {
            productImages.setSize(braSize);
        }

        if (variantFromPS(this)) {
            updateVariant($this.closest(options.productSetProductContainer), this.href);
        } else {
            var href = this.href;

            var $container = $this.closest('#QuickViewDialog #pdpMain').length
                ? $this.closest('#QuickViewDialog #pdpMain')
                : $this.closest('#AddToBagDialog').length
                ? $this.closest('#AddToBagDialog')
                : null;

            if ($container) {
                var isNewTypeProductBra = $container.find('#bra-size-details').length;
                if (isNewTypeProductBra) {
                    var color = $this.data('attrCodeValue');
                    href = generateBraSizeHref('', color, '');
                }
            } else {
                var isNewTypeProductBra = $('#bra-size-details').length;
                if (isNewTypeProductBra) {
                    var color = $this.data('attrCodeValue');
                    href = generateBraSizeHref('', color, '');
                }
            }

            if (enableMatchingSetTabsPdp) {
                var baseProductID = util.getParameterValueFromUrl('baseProductID', this.href);
                href = util.appendParamToURL(href, 'baseProductID', baseProductID)
            }

            updateVariant($this.closest(options.productContainer), href);
        }
        var swatchtype = $this.data('swatchtype');
        dynamicYield.callEvent('Change Attribute', swatchtype);
    }).on('click', '.js-product-tab_anchor', function(e) {
        e.preventDefault();

        var $this = $(this);
        var parent = $this.parent();
        if (parent.hasClass('active')) {
            return;
        }

        var href = $this.data('href');
        var info = $this.data('info')
        href = updateURLForTab(href, info);

        updateVariantByTab($(this).closest(options.productContainer), href);
    }).on('focus', options.swatchSelect, function (e) {
        e.preventDefault();
        // we need to get the previously selected swatch with the link to reset its value
        $(this).data('previous', $(this).val());
    }).on('change', options.swatchSelect, function (e) {
        e.preventDefault();
        var href;

        if ($(this).val().length === 0) {
            href = $(this).data('previous');
        } else {
            href = $(this).val();
        }

        if (variantFromPS(this)) {
            updateVariant($(this).closest(options.productSetProductContainer), href);
        }
        else {
            updateVariant($(this).closest(options.productContainer), href);
        }
        var swatchtype = $(this.selectedOptions[0]).data('swatchtype');
        dynamicYield.callEvent('Change Attribute', swatchtype);
    });

    $('.js-b-product_tile .js-show-more').click(function(e) {
        e.preventDefault();
        var $self = $(this);
        $self.hide();
        $('.swatch-hidden', $self.parent().parent()).removeClass('swatch-hidden');
    });
}

function variantFromPS(target) {
    if (target) {
        var $target = target instanceof jQuery ? target : $(target);
        var hasQuickView = $target.closest('#QuickViewDialog').length > 0;
        if (hasQuickView) {
            return false;
        }
    }

    return $(options.productSetProductContainer).length > 0;
}

function initializePrefSize(params) {
    if (params.skipSizePref || !userPreferences.SIZE_PREFERENCE) {
        return;
    }
    var sizeCont = $(Constants.CLASS_PRD_CONTENT + ' ' + Constants.CLASS_PRD_SIZE_CONTAINER);
    try {
        var prefs = JSON.parse(userPreferences.SIZE_PREFERENCE);
        // check class from preferences - braSize, dressSize, hosierySize
        var possibleSizeAttrs = Object.keys(prefs);
        var sizeElement = [];
        $.each(possibleSizeAttrs, function(i, key) {
            // getting element with size value from preferences
            // data-attr-value*= used due to some products has combined size - ex. dress size 12/14
            sizeElement = sizeCont.find('[data-attr-value="'+prefs[key]+'"]');
            if (sizeElement.length) {
                // breaking each in case element found
                return false;
            }
        });
        // try to select element with value from preferences
        sizeElement.length && !sizeElement.parent().hasClass('selected') && sizeElement.trigger('click');
    } catch (e){
        console.log(e);
    }
}

/**
 * @description Initialize first available variant in case Master's PDP opened
 */
function initializeFirstVariant() {
    var $pdpMain = $('#pdpMain');
    if ($pdpMain.data('isMaster')) {
        // selecting color
        var isColorSelected = false;
        !$pdpMain.find(Constants.CLASS_PRD_COLOR_CONTAINER + ' .selectable.selected').length
            && $pdpMain.find('.selectable:first a').trigger('click')
            && (isColorSelected = true);
        // selecting size
        isColorSelected
            && $(document).one('product.updated', function() {
                $pdpMain.find(Constants.CLASS_PRD_SIZE_CONTAINER + ' .selectable:first a').trigger('click');
            });
        !isColorSelected
            && !$pdpMain.find(Constants.CLASS_PRD_SIZE_CONTAINER + ' .selectable.selected').length
            && $pdpMain.find(Constants.CLASS_PRD_SIZE_CONTAINER + ' .selectable:first a').trigger('click')
    }
}

function clearBraSizeFilters(type, container, variantSelector) {
    // Clean up - reset
    if (type === 'bands') {
        var $bandSizes = $(variantSelector + ' .js-unique-band-sizes a');

        $bandSizes.removeClass('active');
        $bandSizes.removeClass('js-ab-disabled');
        $bandSizes.removeClass('ab-size-button--low-stock')
    } else if (type === 'cups') {
        var $cupSizes = $(variantSelector + ' .js-unique-cup-sizes a');

        $cupSizes.removeClass('active');
        $cupSizes.removeClass('js-ab-disabled');
        $cupSizes.removeClass('ab-size-button--low-stock');
    } else {
        var $bandSizes = $(variantSelector + ' .js-unique-band-sizes a');
        var $cupSizes = $(variantSelector + ' .js-unique-cup-sizes a');

        $bandSizes.removeClass('active');
        $bandSizes.removeClass('js-ab-disabled');
        $bandSizes.removeClass('ab-size-button--low-stock');

        $cupSizes.removeClass('active');
        $cupSizes.removeClass('js-ab-disabled');
        $cupSizes.removeClass('ab-size-button--low-stock');

        $(variantSelector + ' .b-product_variations-low-stock-msg').hide();
    }

    var $container = $(container).closest('.js-pdp-bottom_info').eq(0).find('.product-add-to-cart.b-product_actions').eq(0);
    var $addToCart = $container.find('.js-add-to-cart');
    var errorText = $addToCart.data('errorText');

    $addToCart.attr('data-disabled', 'disabled');
    $addToCart.attr('title', errorText);
}

function updateSelectedBraSize(variantSelector) {
    var $bandSizeActive = $(variantSelector + ' .js-band-size-button.active');
    var $cupSizeActive = $(variantSelector + ' .js-cup-size-button.active');

    if (!($bandSizeActive.length > 0 && $cupSizeActive.length > 0)) {
        return;
    }

    var selectedBraSize = '';
    var selectedCupSize = $cupSizeActive.data('cup-size');
    primarySizeInput = null;

    selectedBraSize += $bandSizeActive.data('band-size');
    selectedBraSize += selectedCupSize;

    var braType = (['A','B','C','D'].indexOf(selectedCupSize) > -1 && 'ad') || (['DD','E','F','FF','G'].indexOf(selectedCupSize) > -1 && 'ddg');
    if (productImages.getSize() !== braType) {
        $('.js-size_' + braType).trigger('click');
        productImages.setSize(braType);
    }

    var selectedColor = '';
    var selectedAttrs = $(variantSelector + ' .l-pdp-variations').data('attributes');
    if (selectedAttrs && selectedAttrs.color) {
        selectedColor = selectedAttrs.color.value;
    }

    updateVariantBySize(selectedBraSize, selectedColor, variantSelector);
}

function bandSizeClickHandler(e) {
    e.preventDefault();

    var $this = $(this);

    var variantSelector = '';
    if ($this.closest('#QuickViewDialog #pdpMain').length > 0) {
        variantSelector = '#QuickViewDialog #pdpMain';
    } else if ($this.closest('#pdpMain').length > 0) {
        variantSelector = '#pdpMain';
    } else if ($this.closest('#AddToBagDialog').length > 0) {
        variantSelector = '#AddToBagDialog';
    }

    var $cupSizeButtons = $(variantSelector + ' .js-cup-size-button');
    var $bandSizeButtons = $(variantSelector + ' .js-band-size-button');
    // var isNotifyMeDisplayed = false;

    // define what selected refinement was first - cup or band size
    if (primarySizeInput === null) {
        primarySizeInput = 'bands';
    }

    // selected active band size - reset cup and band sizes refinements
    if ($this.hasClass('active')) {
        clearBraSizeFilters(null, this, variantSelector);

        // Reset primary input
        primarySizeInput = null;

        // selected not active, not disabled band size with active cup size - active selected band size and dis activate rest band sizes
    } else if (!$this.hasClass('js-ab-disabled') && $cupSizeButtons.hasClass('active') && primarySizeInput !== 'bands') {
        $bandSizeButtons.removeClass('active');
        $this.addClass('active');

        var lowStockAvailability = '' + $this.data('cup-sizes-low-stock-availability');
        if (lowStockAvailability) {
            $(variantSelector + ' .b-product_variations-low-stock-msg').show();
        }

        // selected not active, not disabled band size as first part of bra size - active selected band size and set availability statuses for cup sizes
    } else if (!$this.hasClass('js-ab-disabled')) {
        clearBraSizeFilters(null, this, variantSelector);
        $this.addClass('active');

        var lowStockAvailability = '' + $this.data('cup-sizes-low-stock-availability');
        if (lowStockAvailability) {
            $(variantSelector + ' .b-product_variations-low-stock-msg').show();
        }

        var selectedBandSize = $this.data('band-size');
        $cupSizeButtons.each(function() {
            var $cupSize = $(this);

            var availability = '' + $cupSize.data('band-sizes-availability');
            if (!availability || availability.split(',').indexOf('' + selectedBandSize) === -1) {
                $cupSize.addClass('js-ab-disabled');
            }

            if (lowStockAvailability && lowStockAvailability.split(',').indexOf('' + $cupSize.data('cup-size')) > -1) {
                $cupSize.addClass('ab-size-button--low-stock');
            }
        });
    }

    updateSelectedBraSize(variantSelector);
}

function cupSizeClickHandler(e) {
    e.preventDefault();

    var $this = $(this);

    var variantSelector = '';
    if ($this.closest('#QuickViewDialog #pdpMain').length > 0) {
        variantSelector = '#QuickViewDialog #pdpMain';
    } else if ($this.closest('#pdpMain').length > 0) {
        variantSelector = '#pdpMain';
    } else if ($this.closest('#AddToBagDialog').length > 0) {
        variantSelector = '#AddToBagDialog';
    }

    var $cupSizeButtons = $(variantSelector + ' .js-cup-size-button');
    var $bandSizeButtons = $(variantSelector + ' .js-band-size-button');

    if (primarySizeInput === null) {
        primarySizeInput = 'cups';
    }

    if ($this.hasClass('active')) {
        clearBraSizeFilters(null, this, variantSelector);
        // Reset primary input now cleared
        primarySizeInput = null;
    } else if($bandSizeButtons.hasClass('active') && !$this.hasClass('js-ab-disabled') && primarySizeInput !== 'cups' ) {
        $cupSizeButtons.removeClass('active');
        $this.addClass('active');

        var lowStockAvailability = '' + $this.data('band-sizes-low-stock-availability');
        if (lowStockAvailability) {
            $(variantSelector + ' .b-product_variations-low-stock-msg').show();
        }

    } else if (!$this.hasClass('js-ab-disabled')) {
        clearBraSizeFilters(null, this, variantSelector);
        $this.addClass('active');

        var lowStockAvailability = '' + $this.data('band-sizes-low-stock-availability');
        if (lowStockAvailability) {
            $(variantSelector + ' .b-product_variations-low-stock-msg').show();
        }

        var selectedCupSize = $this.data('cup-size');
        $bandSizeButtons.each(function() {
            var $bandSize = $(this);
            var availability = '' + $bandSize.data('cup-sizes-availability');

            if (!availability || availability.split(',').indexOf('' + selectedCupSize) === -1) {
                $bandSize.addClass('js-ab-disabled');
            }

            if (lowStockAvailability && lowStockAvailability.split(',').indexOf('' + $bandSize.data('band-size')) > -1) {
                $bandSize.addClass('ab-size-button--low-stock');
            }
        });
    }

    updateSelectedBraSize(variantSelector);
}

function generateBraSizeHref(braSize, color, isBraReguest, variantSelector) {
    var $braSizeDetails = variantSelector
        ? $(variantSelector + ' #bra-size-details')
        : $('#bra-size-details');

    var selectedColorEl = variantSelector ? $(variantSelector).find('.js-product-swatch_anchor_color.m-selected') : $('.js-product-swatch_anchor_color.m-selected');
    var selectedColor = color || selectedColorEl.data('attr-code-value');

    var band = '';
    var cup = '';

    if (!braSize) {
        var activeBand = $('.js-unique-band-sizes .active');
        var activeCup = $('.js-unique-cup-sizes .active');

        band = activeBand.data('bandSize');
        cup = activeCup.data('cupSize');
    }

    var url = $braSizeDetails.data(isBraReguest ? 'braUrl' : 'basicUrl');
    if (variantSelector === '#AddToBagDialog') {
        url = $braSizeDetails.data('addToBagModalUrl');
    }

    var productID = $braSizeDetails.data('productId');

    var braSizeVar = 'dwvar_' + productID + '_braSize';
    var colorVar = 'dwvar_' + productID + '_color';
    var urlParams = {
        pid: productID
    };
    urlParams[braSizeVar] = braSize || band + cup;
    urlParams[colorVar] = selectedColor;

    return util.appendParamsToUrl(url, urlParams);
}

function updateMainAndThumbnailCarousels($context) {
    if (!$context) {
        return;
    }

    var $mainSliderContainer = $context.find('.js-product-images_container').eq(0);
    var selectedSizeType = productImages.getSize();

    var $mainSlider = $mainSliderContainer.find('.js-carousel');
    var $thumsSlider = $mainSliderContainer.find('.js-product-images_thumbs');

    if (!$mainSlider.hasClass('slick-initialized') || (!util.isMobile() && !$thumsSlider.hasClass('slick-initialized'))) {
        return;
    }

    var $currentSlideImg = $mainSlider.find('.slick-slide.slick-current').find('.b-product_images-main_image');
    if ($currentSlideImg.length && $currentSlideImg.data('type-img') === selectedSizeType) {
        return;
    }

    var $sizeSlides = $mainSlider.find('img[data-type-img=' + selectedSizeType +']');
    var filteredSlides = $sizeSlides.toArray().filter(function(item) {
        var $item = $(item);
        return $item.parents('.slick-slide').data('slick-index') > -1;
    });

    var newIndex = $(filteredSlides[0]).parents('.slick-slide').data('slick-index');
    if (newIndex !== undefined) {
        setTimeout(function() {
            $mainSlider.slick('slickGoTo', newIndex, true);

            if (!util.isMobile()) {
                $thumsSlider.slick('slickGoTo', newIndex, true);
            }
        }, 1000);
    }
}

function updateVariantBySize(braSize, color, variantSelector) {
    var $braSizeDetails = $(variantSelector + ' #bra-size-details');
    var href = generateBraSizeHref(braSize, color, true, variantSelector);

    if (!href) {
        return;
    }

    $(variantSelector + ' .js-sizeselectionerror').addClass('g-hidden');

    if (variantFromPS($braSizeDetails)) {
        updateVariant($braSizeDetails.closest(options.productSetProductContainer), href);
    } else {
        var source = $('#QuickViewDialog:not(:empty)').length ? 'quickview' : '';
        var $container = $braSizeDetails.closest(options.productContainer);
        var $productForm = $container.find(Constants.CLASS_PRD_FORM);
        var qty = $productForm.find(Constants.CLASS_PRD_QUANTITY_FIELD).first().val();
        var params = {
            Quantity: isNaN(qty) ? '1' : qty,
            format: 'ajax',
            isSet: variantFromPS($braSizeDetails),
            productlistid: $productForm.find('input[name="productlistid"]').first().val(),
            source: source
        };

        if ($('#uuid').length) {
            params['uuid'] =  $('#uuid').val();
        }

        if ($('#source').length) {
            params['source'] =  $('#source').val();
        }

        if (enableMatchingSetTabsPdp) {
            params.baseProductID = $(options.productVariationContainer).data('baseProductId');
        }

        progress.show($container);

        ajax.load({
            url: util.appendParamsToUrl(href, params),
            timeoutProgress: 2500,
            callback: function (response) {
                var $context = variantSelector && $(variantSelector).length ? $(variantSelector) : $(document);
                var parsedResponse = $($.parseHTML(response));

                var newLabel = $(parsedResponse.find('.b-product_variations-label'));
                if (newLabel.length) {
                    var oldLabel = $context.find('.brasize .b-product_variations-label');
                    oldLabel.empty().html(newLabel.children());
                }

                var $addToCart = $($context.find('.l-pdp-bottom_info .js-product_form.pdpForm:visible')[0]);
                var newData = parsedResponse.find('.js-product-add-to-cart');
                $addToCart.empty().html(newData);

                updateMainAndThumbnailCarousels($context);

                $(document).trigger('product.updated');

                scrollToActiveTab();

                var pid = variantFromPS($braSizeDetails)
                    ? $context.find('#pid').attr('value')
                    : $context.find('.product-add-to-cart.b-product_actions').find('#pid').attr('value');

                // updates invalid slash from jquery syntax
                pid = pid.replace('/', '\\/');

                var $varGroupPid = $container.find('.l-pdp-bottom_info').eq(0).find('#varGroupPid');
                var $selectedVariant = $container.find('.l-pdp-bottom_info').eq(0).find('#selectedVariant');

                if (source !== 'quickview') {
                    setTimeout(function() {
                        productinfoaccordion.updateProductInfoAccordion($context, $selectedVariant.val() || $varGroupPid.val() || pid);
                    }, 0);
                }

                var $data = parsedResponse.find('#data');
                if ($data.length && $data.data('productDataForPageContext')) {
                    $(document).trigger('gtm.push.pdp.event', ['view_item_update', 0, $data.data('productDataForPageContext')])
                }

                dynamicYield.callSPAEvent({
                    context: {
                        type: "PRODUCT",
                        data: [$container.find($('span[itemprop="productID"]')).text().replace(/(\r\n|\n|\r)/gm, "").trim()]
                    },
                    url: util.appendParamsToUrl(href, params),
                    countAsPageview: true
                });
            }
        });
    }

    var swatchtype = { "type": "Bra Size", "value": braSize };
    dynamicYield.callEvent('Change Attribute', swatchtype);
}

function updatePromoMsg($container, isSet) {
    var $varGroupInput = null;
    var $promoMsgContainer = null;
    var $promoMsgPriceContainer = null;

    if (isSet) {
        $varGroupInput = $container.find('#varGroupPid').eq(0);
        $promoMsgContainer = $container.find('.l-pdp-promo_message').eq(0);
        $promoMsgPriceContainer = $container.find('.b-product_bottom_price').eq(0);
    } else {
        $container = $('.js-product_content');

        $varGroupInput = $container.find('.l-pdp-bottom_info').eq(0).find('#varGroupPid').eq(0);
        $promoMsgContainer = $container.find('.js-pdp-top_info').find('.l-pdp-promo_message').eq(0);
        $promoMsgPriceContainer = $container.find('.l-pdp-bottom_info').eq(0).find('.b-product_bottom_price').eq(0);
    }

    if (!$varGroupInput.val()) {
        return;
    }

    var params = {
        pid: $varGroupInput.val()
    }

    ajax.load({
        url: util.appendParamsToUrl(window.Urls.getProductPromotionsMsg, params),
        timeoutProgress: 2500,
        callback: function(response) {
            if (!response) {
                return;
            }

            var $parsedResponse = $($.parseHTML(response));
            var $allPromoMsg = $parsedResponse.filter('.b-promo_message');
            if (!$allPromoMsg.length) {
                return;
            }

            if ($promoMsgContainer.length) {
                $promoMsgContainer.empty().html($allPromoMsg);
            }
        }
    });
}

function activeSelectedBraSize() {
    var variantSelector = '';
    var $braSizeDetails = $(variantSelector + ' #bra-size-details');
    var selectedBandSize = $braSizeDetails.data('selected-band-size');
    var selectedCupSize = $braSizeDetails.data('selected-cup-size');

    if (selectedBandSize && selectedCupSize) {
        var $selectedBandSizeBtn = $(variantSelector + ' .js-band-size-button[data-band-size="' + selectedBandSize + '"]');
        var cupSizeAvailability = $selectedBandSizeBtn.data('cup-sizes-availability');

        var braType = (['A','B','C','D'].indexOf(selectedCupSize) > -1 && 'ad') || (['DD','E','F','FF','G'].indexOf(selectedCupSize) > -1 && 'ddg');
        productImages.setSize(braType);

        if (cupSizeAvailability && cupSizeAvailability.split(',').indexOf(selectedCupSize) > -1) {
            $selectedBandSizeBtn.addClass('active');
            $(variantSelector + ' .js-cup-size-button[data-cup-size="' + selectedCupSize + '"]').addClass('active');
        } else {
            clearBraSizeFilters(null, this, variantSelector);
            $selectedBandSizeBtn.trigger('click');
        }
    }
}

function initializeSizeButtons() {
    var $document = $(document);

    $document.on('click', '.js-ab-disabled', function (e) {
        e.preventDefault();
    });
    $document.on('click', '.js-band-size-button:not(".js-ab-disabled")', bandSizeClickHandler);
    $document.on('click', '.js-cup-size-button:not(".js-ab-disabled")', cupSizeClickHandler);

    activeSelectedBraSize();
}

module.exports = {
    init : function(params) {
        initializeEvents();

        if (initialized) {
            return;
        }

        initializeParams(params);
        initializePrefSize(params);
        initializeSizeButtons();
        initializeFirstVariant();
        detailsTabs();

        if (!variantFromPS()) {
            updatePromoMsg();
        }

        initialized = true;
    }
};
