'use strict';

var $               = (typeof window !== "undefined" ? window['jQuery'] : typeof global !== "undefined" ? global['jQuery'] : null),
    resources       = (typeof window !== "undefined" ? window['Resources'] : typeof global !== "undefined" ? global['Resources'] : null),
    constants       = require('./constants'),
    cssConstants    = require('./../../cssconstants'),
    urls            = (typeof window !== "undefined" ? window['Urls'] : typeof global !== "undefined" ? global['Urls'] : null);

var $cache = {};

function initializeCache() {
    $cache.document = $(document);
    $cache.placeOrderButton = $cache.document.find(constants.CLASS_PLACE_ORDER_BUTTON);
    $cache.paypalButton = $cache.document.find(constants.CLASS_PAYPAL_SELECTOR).addClass(cssConstants.G_HIDDEN).appendTo($cache.placeOrderButton.parent());
}

function initializeEvents() {
    $cache.document.on('checkout.switchmethod', handleSwitchMethod);
    $cache.paypalButton.on('click', handleBillingForm);
    window.paypalCheckFrom = handleBillingForm;
    return true;
}

function handleBillingForm() {
    // sending data to backend and checking all data filled
    // cannot avoid direct call
    return require('./billing').checkRequiredFields(null, true) && saveBillingAddress();
};

function handleSwitchMethod(event, data) {
    if (data && data.method === resources.PP_METHOD_ID) {
        $cache.placeOrderButton.addClass(cssConstants.G_HIDDEN);
        $cache.paypalButton.removeClass(cssConstants.G_HIDDEN)
    } else {
        $cache.placeOrderButton.removeClass(cssConstants.G_HIDDEN);
        $cache.paypalButton.addClass(cssConstants.G_HIDDEN)
    }
}

function saveBillingAddress() {
    var saveResult = true;
    $.ajax({
        url: urls.setBillingAddress,
        type: "POST",
        cache: false,
        dataType: "json",
        data: $(constants.CLASS_BILLING_FORM).serialize(),
        success: function(response){
            saveResult = (response && response.status === "ok");
        }
    });
    return saveResult;
}

var paypal = {
        init : function (params) {
            initializeCache();
            $cache.paypalButton.length 
                && initializeEvents(params) 
                && handleSwitchMethod(null, {'method': $(constants.CLASS_PAYMENT_METHODS).find(':checked').val()});
        }
    };

module.exports = paypal;