'use strict';

var $ = (typeof window !== "undefined" ? window['jQuery'] : typeof global !== "undefined" ? global['jQuery'] : null);
var dialog = require('./../dialog');
var contacUs = require('./contactus');
var validator = require('./../../../../app_storefront_core/cartridge/js/validator');
var formPrepare = require('./../../../../app_storefront_core/cartridge/js/pages/checkout/formPrepare');
var layout = require('./../layout');

var $cache = {};
var initialized = false;
var isModalOpen = false;

function initializeCache() {
    $cache.document = $(document);
};

function initializeEvents() {
    $cache.document.on('click', '.js-modal', function(e){
        e.preventDefault();
        show($(this).attr('href'));
    });
};

function show (url) {
    var scrollPosition = 0;
    var body = document.getElementsByTagName('body')[0];
    dialog.open({
        url: url,
        options: {
            width: 1120,
            dialogClass: 'contact-us-modal',
            open: function() {
                validator.init();
                formPrepare.init({
                    continueSelector: '[name$="dwfrm_contactusemail_send"]',
                    formSelector: 'form[id$="contactUsEmailForm"]'
                });
                contacUs.initOpen();

                $('body').css('overflow', 'hidden');
                if (!layout.isDesktop()) {
                    scrollPosition = window.pageYOffset;
                    window.scrollTo(0, 0);
                    layout.bodyLock.call(body);
                }
            },
            close: function() {
                $('body').css('overflow', 'visible');
                if (!layout.isDesktop()) {
                    layout.bodyUnlock.call(body);
                    window.scrollTo(0, scrollPosition);
                }
            }
        }
    });

    if (!isModalOpen){
        if (dialog.isActive()) {
            contacUs.init();
        }
        isModalOpen = true;
    }

};

module.exports = {
    init: function() {
        if (initialized) return;
        initializeCache();
        initializeEvents();
    },
}
