module.exports={
	"creditcarderrors": {
		"101": "input[name *= _creditCard_number]",
		"102": "input[name *= _creditCard_number]",
		"103": "input[name *= _creditCard_number]",
		"201": "input[name *= _cvn]",
		"301": "select[name *= _expiration_month]",
		"302": "select[name *= _expiration_month]",
		"303": "select[name *= _expiration_month]",
		"304": "select[name *= _expiration_year]",
		"305": "select[name *= _expiration_year]",
		"306": "select[name *= _expiration_month]",
		"401": "input[name $= _creditCard_owner]",
		"402": "input[name $= _creditCard_owner]"
	}
}
