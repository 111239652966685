'use strict';

var $ = (typeof window !== "undefined" ? window['jQuery'] : typeof global !== "undefined" ? global['jQuery'] : null),
    util = require('./util'),
    $cache = {},
    options = {
        backToTopBtn: '.js-back_to_top',
        backToTopBtnDOM: '<div class="b-back_to_top-container"><a href="#" class="js-back_to_top b-back_to_top-btn" title="Back to top">Top</a></div>'
    };

function insertButton(){
	$(".js-btt_wrapper").append(options.backToTopBtnDOM);
}

function initializeCache() {
    $cache.document = $(document);
    $cache.window = $(window);
    $cache.backToTopBtn = $(options.backToTopBtn);
}

function initializeEvents() {
    if ($cache.backToTopBtn.length) {
        $cache.window.on('load scroll', function() {
            backToTop();
        });
        $cache.document.on('click', options.backToTopBtn, function(e) {
            e.preventDefault();
            $(this).addClass('m-active');
            util.scrollBrowser(0, 700);
        });
    }
}

function backToTop() {
    var scrollTrigger = 200,
        scrollTop = $cache.window.scrollTop();

    if (scrollTop > scrollTrigger) {

        $cache.backToTopBtn.addClass('is--shown');
    } else {
        $cache.backToTopBtn.removeClass('m-active is--shown');
    }
}

module.exports = {
    init: function() {
        // DO NOT show on PDP
        if (document.querySelector('#pdpMain') === null) {
            insertButton();
            initializeCache();
            initializeEvents();
        }
    }
};
