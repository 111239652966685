/** The references object of all modules needed for components initialization */
var references = {};

/** The components initialization configuration object */
var configuration = {
    'global' : {
        'components': {}
    }
};

module.exports = {
    'configuration' : configuration,
    'references' : references
};