module.exports = {
    /*
     * html
     */
    CLASS_HTML: 'html',
    /*
     * Header
     */
    CLASS_HEADER: '.js-header',
    /*
     * Sliders
     */
    CLASS_CAROUSEL: '.js-carousel',
    /**
     * Recommender PDP
     */
    CLASS_RECOMMENDER: '.js-recommendation',
    /*
     * Search in the header
     */
    CLASS_HEADER_SEARCH_MOBILE: '.js-header_search_mobile',
    CLASS_HEADER_SEARCH: '.js-header_search',
    CLASS_STICKY_HEADER: '.js-sticky_header',
    CLASS_SEARCH_TOGGLE: '.js-search_toggle',
    CLASS_SCROLL: '.js-scroll',
    CLASS_NOHITS_SEARCH_CONTAINER: '.js-nohits_search_container',
    CLASS_MOBILE_INPUT_SEARCH: '.js-mobile-input-field',
    /*
     * Print
     */
    CLASS_PRINT_VERSION: '.js-print_version',
    /*
     * Countries Select
     */
    CLASS_COUNTRIES_SELECTBOX: '.js-countries_selectbox',
    CLASS_COUNTRIES_CURRENT: '.js-countries_current',
    CLASS_COUNTRIES: '.js-countries',
    /*
     * Minicart
     */
    CLASS_MINICART_CONTENT: '.js-mini-cart-content',
    CLASS_PRE_ORDER_ACTION: '.js-pre-order-action',
    CLASS_MINICART_PRODUCT_DESCR: '.js-minicart_description',
    CLASS_MINICART_IMAGE: '.js-minicart_image',
    CLASS_MINICART_TOGGLE_IMAGE: '#mini-cart .js-minicart_toggle-image',
    CLASS_MINICART_ICON: '.js-minicart_icon',
    CLASS_MINICART_EXPANDED: 'js-minicart-expanded',
     /*
     * Cookie privacy
     */
    CLASS_COOKIES_NOTICE_CONTAINER: 'js-cookies_notice_container',
    CLASS_COOKIES_NOTICE_LINK: 'js-cookies_notice_link',
    CLASS_COOKIES_CLOSE_BUTTON: 'js-cookies_closebutton',
    /*
     * Refinements
     */
    CLASS_REFINEMENT_TOOLTIP: '.js-refinement-tooltip',
    CLASS_REFINEMENT_OPEN: '.js-refinements_open',
    CLASS_REFINEMENT_BUTTONS_MOBILE: '.js-refinement-buttons-mobile',
    CLASS_REFINEMENT_HEADER_MOBILE: '.js-refinement-header-mobile',
    CLASS_REFINEMENTS_SHOW: '.js-refinements-show',
    CLASS_REFINEMENTS_BACK: '.js-refinements_close',
    CLASS_REFINEMENTS_LINK: '.js-refinement-link',
    CLASS_REFINEMENT_CLEARALL: '.js-refinement-clearall',
    CLASS_REFINEMENT_CLAERONE: '.js-refinement-clearone',
    CLASS_REFINEMENT_DONE: '.js-refinement-done',
    CLASS_REFINEMENT_APPLY: '.js-refinement-apply',
    CLASS_REFINEMENT_BACK: '.js-refinement-back',
    CLASS_REFINEMENT_HEADER: '.js-refinement-header',
    CLASS_REFINEMENT_LINK: '.js-refinement_link',
    CLASS_REFINEMENT_CONTAINER: '.js-refinement_container',
    CLASS_REFINEMENT_ITEM: '.js-refinement_item',
    CLASS_REFINEMENT_VALUE: '.js-breadcrumb-refinement-value',
    CLASS_ACCORDION_SWITCHER: '.js-accordion-switcher',
    CLASS_ACCORDION_ITEM: '.js-accordion-item',
    CLASS_CHECKBOX: '.js-checkbox',
    CLASS_REFINEMENT_SCROLL: '.js-refinements-scroll',
    CLASS_NO_RESULTS: '.js-no_results'
};
