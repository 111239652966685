var util = require('./util'),
    layout = require('./layout'),
    quickview = require('./quickview'),
    carousel = require('./carousel'),
    searchsuggest = require('./searchsuggest'),
    Constants = require('./constants'),
    CSSConstants = require('./cssconstants'),
    ajax = require('./ajax'),
    urls = (typeof window !== "undefined" ? window['Urls'] : typeof global !== "undefined" ? global['Urls'] : null),
    getAndInsertCSRFToken = require('./csrf');

/**
 * @description Moved from SG app.js
 */ 
function initializeEvents() {
    var controlKeys = ['8', '13', '46', '45', '36', '35', '38', '37', '40', '39'];

    $('body')
        .on('keydown', 'textarea[data-character-limit]', function (e) {
            var text = $.trim($(this).val()),
                charsLimit = $(this).data('character-limit'),
                charsUsed = text.length;

            if ((charsUsed >= charsLimit) && (controlKeys.indexOf(e.which.toString()) < 0)) {
                e.preventDefault();
            }
        })
        .on('change keyup mouseup', 'textarea[data-character-limit]', function () {
            var text = $.trim($(this).val()),
                charsLimit = $(this).data('character-limit'),
                charsUsed = text.length,
                charsRemain = charsLimit - charsUsed;

            if (charsRemain < 0) {
                $(this).val(text.slice(0, charsRemain));
                charsRemain = 0;
            }

            $(this).next('div.char-count').find('.char-remain-count').html(charsRemain);
        })
        .on('click', Constants.CLASS_PRINT_VERSION, function(e) {
            e.preventDefault();
            window.print();
        });

    /**
     * initialize search suggestions, pending the value of the site preference(enhancedSearchSuggestions)
     * this will either init the legacy(false) or the beta versions(true) of the the search suggest feature.
     * */
    $(Constants.CLASS_HEADER_SEARCH).each(function () {
        new searchsuggest($(this), Resources.SIMPLE_SEARCH);
    });

    // add show/hide navigation elements
    $('.secondary-navigation .toggle').click(function () {
        $(this).toggleClass('expanded').next('ul').toggle();
    });

    // add generic toggle functionality
    $('.toggle').each(function(index, item) {
        $(this).next('.toggle-content').toggleClass('g-hidden', !$(this).hasClass('expanded'));
    });
    $('.toggle').click(function (e) {
        e.preventDefault();
        $(this).toggleClass('expanded').next('.toggle-content').trigger('toggled', {'expanded': $(this).hasClass('expanded')}).toggleClass('g-hidden', !$(this).hasClass('expanded'));
    });

    // subscribe email box
    var $subscribeEmail = $('.subscribe-email');
    if ($subscribeEmail.length > 0)    {
        $subscribeEmail.focus(function () {
            var val = $(this.val());
            if (val.length > 0 && val !== Resources.SUBSCRIBE_EMAIL_DEFAULT) {
                return; // do not animate when contains non-default value
            }

            $(this).animate({color: '#999999'}, 500, 'linear', function () {
                $(this).val('').css('color', '#333333');
            });
        }).blur(function () {
            var val = $.trim($(this.val()));
            if (val.length > 0) {
                return; // do not animate when contains value
            }
            $(this).val(Resources.SUBSCRIBE_EMAIL_DEFAULT)
                .css('color', '#999999')
                .animate({color: '#333333'}, 500, 'linear');
        });
    }

    $('.p-privacy_policy').on('click', function (e) {
        e.preventDefault();
        dialog.open({
            url: $(e.target).attr('href'),
            options: {
                height: 600
            }
        });
    });

    // main menu toggle
    $('.menu-toggle').on('click', function (e) {
        e.stopPropagation();
        $('html').toggleClass('js-menu_active');
    });

    $(document).on('click', 'html.js-menu_active', function(e) {
        var container = $("#navigation");

        // if the target of the click isn't the container nor a descendant of the container
        if ($('html').hasClass('js-menu_active') && !container.is(e.target) && container.has(e.target).length === 0) {
            $('html').toggleClass('js-menu_active');
        }
    });

    $('.menu-category li .menu-item-toggle').on('click', function (e) {
        e.preventDefault();
        var $parentLi = $(e.target).closest('li');
        $parentLi.siblings('li').removeClass('active').find('.menu-item-toggle').removeClass('fa-chevron-up active').addClass('fa-chevron-right');
        $parentLi.toggleClass('active');
        $(e.target).toggleClass('fa-chevron-right fa-chevron-up active');
    });
    $('.user-account').on('click', function (e) {
        e.preventDefault();
        $(this).parent('.user-info').toggleClass('active');
    });
    
    if(!layout.isDesktop()) {
        $(window).resize(function() {
            $('.' + CSSConstants.DIALOG_CONTENT + ':visible').each(function() {
                $(this).dialog('option', 'position', $(this).dialog('option', 'position'));
            });
        });
    }

    $(document).on('recommendations-loaded', function() { 
        carousel.initCarousel($(Constants.CLASS_RECOMMENDER).find(Constants.CLASS_CAROUSEL)); 
    });

    $(document).trigger("component-inited");

    $(document).on('minicart.updated product.afterAddedToCart', invalidateDeliveryPromotion);

    $(document).on('minicart.updated product.afterAddedToCart', updateMenuQuantity);

    invalidateDeliveryPromotion();
}

/**
 * @description Moved from SG app.js
 * @private
 * @function
 * @description Adds class ('js') to html for css targeting and loads js specific styles.
 */
function initializeDom() {
    // add class to html for css targeting
    $('html').addClass('js');
    if (SitePreferences.LISTING_INFINITE_SCROLL) {
        $('html').addClass('infinite-scroll');
    }
    // load js specific styles
    util.limitCharacters();
    util.checkLimitMessage();
    
    if (document.cookie.length === 0) {
        $('<div/>').addClass('browser-compatibility-alert' + ' ' + CSSConstants.B_COOKIES_JS_ERROR).append($('<p/>').addClass('browser-error').html(Resources.COOKIES_DISABLED)).appendTo('#browser-check');
    }

    //generate csrf for form
    var forms = $("form[data-csrfgenerate='true']");

    for (var i = 0; i < forms.length; i++) {
        getAndInsertCSRFToken(forms[i]);
    }
    
    checkItemWideFlyoutMenu();
}

function checkItemWideFlyoutMenu() {
    var countItemsWideFlyoutMenu = $(".m-flyout_full .b-menu_main-level_2_wrapper").find('img').length;

    if (countItemsWideFlyoutMenu == 1) {
        var menuFullWight = $(".menu-category.level-1.b-menu_main-level_1").css('width');
        var menuItemWight = $(".m-flyout_full").css('width');
        var offsetItem = $(".m-flyout_full").position()['left'];
        
        var offsetRight = parseInt(menuFullWight) - parseInt(menuItemWight) - offsetItem;
        $(".m-flyout_full .b-menu_main-level_2_wrapper").css('right',offsetRight);
    }
}

function invalidateDeliveryPromotion() {
    var approachingFreeStandardUKShipping = $('.js-approachingfreestandardukshipping');
    var approachingFreeNextDayUKShipping = $('.js-approachingfreenextdayukshipping');
    var qualifiedFreeNextDayUKShipping = $('.js-qualifiedfreenextdayukshipping');
    var subtotal = parseInt($('.js-totalvalue').attr('data-totalvalue'));

    approachingFreeStandardUKShipping.addClass('hidden');
    approachingFreeNextDayUKShipping.addClass('hidden');
    qualifiedFreeNextDayUKShipping.addClass('hidden');

    if (subtotal >= SitePreferences.FREE_NEXT_DAY_UK_SHIPPING_THRESHOLD) {
        $('.js-qualifiedfreenextdayukshipping').removeClass('hidden');
    } else if (subtotal >= SitePreferences.FREE_STANDARD_UK_SHIPPING_THRESHOLD) {
        $('.js-approachingfreenextdayukshipping').removeClass('hidden');
        $('.js-discountdistance').html(SitePreferences.FREE_NEXT_DAY_UK_SHIPPING_THRESHOLD - subtotal);
    } else if (subtotal >= SitePreferences.FREE_STANDARD_UK_SHIPPING_ALERT) {
        $('.js-approachingfreestandardukshipping').removeClass('hidden');
        $('.js-discountdistance').html(SitePreferences.FREE_STANDARD_UK_SHIPPING_THRESHOLD - subtotal);
    }
}

function updateMenuQuantity() {
    var quantityHeader = $('.js-quantity-header');
    var quantityMenu = $('.js-menu-cart-quantity');
    var quantityMenuWraper = $('.js-menu-quantity-wrapper');
    if (quantityHeader.length && quantityMenuWraper.length && quantityMenu.length) {
        var quantity = quantityHeader.attr('data-quantity');
        if (quantity) {
            quantityMenu.html(quantity);  
        }
        if ( quantity != "0" ) {
            quantityMenuWraper.removeClass('g-hidden');
        } else {
            quantityMenuWraper.addClass('g-hidden');
        }
    }
}

module.exports = {
    'init' : function () {
        initializeDom();
        initializeEvents();
    }
};