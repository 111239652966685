'use strict';

//Global variable used by GTM
window['dataLayer'] = window['dataLayer'] || [];


var analyticsData = pageContext.analytics || {},
    currentPage = pageContext.currentPage || pageContext.ns;

function initGTM() {
    (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});
        var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),
            dl=l!='dataLayer'?'&l='+l:'';
        j.async=true;j.src= '//www.googletagmanager.com/gtm.js?id='+i+dl;
        f.parentNode.insertBefore(j,f);
    })(window, document, 'script', 'dataLayer', SitePreferences.GTM_ACCOUNT_ID);
}

function initEvents() {
    var eventsToInit = $.merge($(pageEventConfig["global"]).slice() || [], pageEventConfig[currentPage] || []);

    for (var i = 0; i < eventsToInit.length; i++) {
        var functionName = eventsToInit[i];

        if (functionName && eventsLib[functionName]) {
            eventsLib[functionName](analyticsData, currentPage);
        }
    }
}

function pushPageData() {
    pageDataCollectors['global'](analyticsData);

    if(currentPage in pageDataCollectors) {
        pageDataCollectors[currentPage](analyticsData);
    }
}

function autoPushDatalayer() {
    var eventObject = Object.assign({
        event: 'user_data'
    }, pageContext.analytics.user);

    window.dataLayer = window.dataLayer || [];
    dataLayer.push(eventObject);

    if (window.pageContext.type === 'product') {
        $(document).trigger('gtm.push.pdp.event', ['view_item']);
    }

    if (window.pageContext.type === 'Cart') {
        var products = pageContext.analytics.cartProducts;
        window.dataLayer = window.dataLayer || [];
        dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
        dataLayer.push({
            event: "view_cart",
            ecommerce: {
                items: products
            }
        });
    }

    if (window.pageContext.type === 'checkout') {
        var products = pageContext.analytics.cartProducts;
        var eventName = window.pageContext.page === 'shipping' ? 'begin_checkout' : 'add_payment_info';
        window.dataLayer = window.dataLayer || [];
        dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
        dataLayer.push({
            event: eventName,
            ecommerce: {
                items: products
            }
        });
    }

    if (window.pageContext.type === 'orderconfirmation') {
        var orderData = window.pageContext.analytics.orderData;
        if (!orderData) {
            return;
        }

        window.dataLayer = window.dataLayer || [];

        dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
        dataLayer.push({
            event: "purchase",
            ecommerce: orderData
        });
    }
}

module.exports = {
    init: function() {
        $(document).on('gtm.push.pdp.event', function(event, eventName, quantity, updatedVariant) {
            var eventName = eventName || 'view_item';
            var product = updatedVariant ? updatedVariant : Object.assign({}, window.pageContext.analytics.productPDP);
            if (quantity) {
                product.quantity = quantity;
            } else {
                delete product.quantity;
            }

            var items = [product];

            window.dataLayer = window.dataLayer || [];
            dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
            dataLayer.push({
                event: eventName,
                ecommerce: {
                    items: items
                }
            });
        });

        var isActivated = false;
        $(document).on('gtm.gle.push.order.confirmation', function(event, gleData) {
            if (isActivated) {
                return;
            }

            isActivated = true;

            var transaction_id = gleData.MerchantOrderId;
            var affiliation = 'international';
            var value = gleData.details.customerTotalPriceInMerchantCurrency;
            var shipping = gleData.details.customerDiscountedShippingPriceInMerchantCurrency;
            var shipping_type = gleData.details.shippingServiceName;
            var payment_type = gleData.details.paymentMethodName;
            var items = window.pageContext.analytics.cartProducts;

            var couponArr = [];
            gleData.details.discounts.forEach(function(discount) {
                if (discount.couponCode) {
                    couponArr.push(discount.couponCode);
                }
            });

            var coupon = couponArr.join('|').toUpperCase();

            var orderData = {
                transaction_id: transaction_id,
                affiliation: affiliation,
                value: value,
                tax: tax,
                shipping: shipping,
                coupon: coupon,
                shipping_type: shipping_type,
                payment_type: payment_type,
                items: items
            };

            window.dataLayer = window.dataLayer || [];
            dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
            dataLayer.push({
                event: "purchase",
                ecommerce: orderData
            });
        });

        autoPushDatalayer();
    }
};
