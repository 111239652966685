/**
 * Refinements model created for managing refinements via JS object
 * 
 * Model interface consists of following methods:
 * 
 *     toggleCurrentRefinementValue - add or remove value to current refinement.
 *         If current refinement is price then type of argument have to be Array;
 *         
 *     clearCurrentRefinement - removes all values of current refinement;
 *     
 *     clearAllRefinements - removes all refinements;
 *     
 *     isInCurrent - check if given value is in current refinement;
 *     
 *     composeRefinementsForUrl - returns object with params prepared for url appending;
 *     
 *     constructFromUrl - constructs new refinement object from given url;
 *     
 *     saveCurrentValues - saves current values of current refinement;
 *     
 *     getSavedValues - returns saved refinement values;
 *     
 *     getCurrentValues - returns values of current refinement
 *			
 *	   get - returns values of given refinement
 *			
 *	   getPrefs - returns all refinements
 *				
 *	   setCurrentRefinement - sets current refinement name
 *				
 *	   getCurrentRefinement - returns current refinement name
 *				
 *	   newCurrentRefinementValue - resets current refinement values
 *				
 *	   isNoRefinements - returns true if there is no selected refinements, false in other case
 */
var util = require('./../../util');

var refinements = {};
var currentRefinement,
    savedValues;

var attributes = {
    required: [
        'priceMin',
        'priceMax',
        'refineName',
        'refineValue',
        'priceRefinementName',
        'url'
    ],
    optional: [
        'categoryID',
        'searchPhrase',
        'promotionID',
        'sortBy',
        'sortDirection',
        'sortRule',
        'pagingSize',
        'pagingStart'
    ]
};

var refinementsmodel = {
    init: function(params) {
        params = params || {};

        setAttributes(params);
        constructRefinements();

        return {
            toggleCurrentRefinementValue: function(value) {
                var currRef = this.getCurrentRefinement();
                if (currRef == attributes.priceRefinementName) {
                    value = value || [];
                    setPriceRefinement.call(this, value[0], value[1]);
                } else {
                    toggleRefinementValue.call(this, currRef, value);
                }
            },

            clearCurrentRefinement: function() {
                clearRefinement.call(this, this.getCurrentRefinement());
            },

            /**
             * Removes all refinements
             */
            clearAllRefinements: function() {
                refinements.prefs = {};
            },

            isInCurrent: function(value) {
                return this.getCurrentValues().indexOf(value) >= 0;
            },

            /**
             * Makes object which is suitable for url composing
             * @returns {Object}
             */
            composeRefinementsForUrl: function() {
                var result = {};
                addExtraParams(refinements, result);
                var i = 1;
                for (var p in refinements.prefs) {
                    if (p == attributes.priceRefinementName) {
                        var priceMin = refinements.prefs[attributes.priceRefinementName][0],
                            priceMax = refinements.prefs[attributes.priceRefinementName][1];
                        if (priceMin || priceMin === 0) {
                            result[attributes.priceMin] = priceMin;
                        }
                        if (priceMax) {
                            result[attributes.priceMax] = priceMax;
                        }
                    } else {
                        result[attributes.refineName + i] = p;
                        result[attributes.refineValue + i] = refinements.prefs[p].join('|');
                        i++;
                    }
                }
                return result;
            },

            constructFromUrl: function(url) {
                attributes.url = url;
                constructRefinements();
            },

            saveCurrentValues: function() {
                savedValues = this.getCurrentValues();
            },

            getSavedValues: function() {
                return savedValues;
            },

            getCurrentValues: function() {
                return this.get(this.getCurrentRefinement());
            },

            get: function(name) {
                return refinements.prefs[name];
            },

            getPrefs: function() {
                return refinements.prefs;
            },

            setCurrentRefinement: function(refinementName) {
                currentRefinement = refinementName;
            },
            getCurrentRefinement: function() {
                return currentRefinement;
            },
            removeCurrentRefinement: function() {
                currentRefinement = undefined;
            },

            newCurrentRefinementValue: function(value) {
                newRefinementValue(this.getCurrentRefinement(), value);
            },

            isNoRefinements: function() {
                return $.isEmptyObject(this.getPrefs());
            },

            isChanged: function() {
                return !util.arraysEqualByValues(this.getSavedValues(), this.getCurrentValues());
            }

        };
    }
};

function setAttributes(params) {
    setRequiredAttributes(params);
    var opt = attributes.optional;
    for (var i = 0; i < opt.length; i++) {
        if (opt[i] in params) {
            var p = opt[i];
            attributes[p] = params[p];
        }
    }
}

function setRequiredAttributes(params) {
    var req = attributes.required;
    for (var i = 0; i < req.length; i++) {
        if (!(req[i] in params)) {
            throw new Error('Parameter ' + req[i] + ' is required');
        }

        var p = req[i];
        attributes[p] = params[p];
    }
}

/**
 * Extract refinements from given url
 * @param {String} url
 * @returns {Object}
 */
function constructRefinements() {
    var queryString = attributes.url || '';
    var queryParams = util.getQueryStringParams(queryString);

    var size = parseInt(queryString.split('&').length / 2);

    var prefs = {};
    if (attributes.priceMin in queryParams || attributes.priceMax in queryParams) {
        var from = parseInt(queryParams[attributes.priceMin]),
            to = parseInt(queryParams[attributes.priceMax]);

        if (!to) {
            to = 0;
        }

        prefs[attributes.priceRefinementName] = [];
        if (!!from) {
            prefs[attributes.priceRefinementName].push(from);
        } else {
            prefs[attributes.priceRefinementName].push(0);
        }
        if (!!to) {
            prefs[attributes.priceRefinementName].push(to);
        }

        size--;
    }
    addExtraParams(queryParams, refinements);

    for (var i = 1; i <= size; i++) {
        var name = queryParams[attributes.refineName + i],
            value = queryParams[attributes.refineValue + i];

        if (!name || !value) {
            continue;
        }
        value = value.split('|');

        prefs[name] = value;
    }

    refinements.prefs = prefs;
}

/**
 * Copies search phrase and/or category parameters from one object into another
 * @param fromObj
 * @param toObj
 */
function addExtraParams(fromObj, toObj) {
    var opt = attributes.optional;
    for (var i = 0; i < opt.length; i++) {
        var p = opt[i];
        if ((p in attributes) && fromObj[attributes[p]]) {
            toObj[attributes[p]] = fromObj[attributes[p]];
        }
    }
}

/**
 * If given value of given refinement exists then remove, if it doesn't then add into array
 * @param {String} name
 * @param {String} value
 */
function toggleRefinementValue(name, value) {
    refinements.prefs[name] = util.toggleFromArray(refinements.prefs[name], value.toString());

    if (refinements.prefs[name].length == 0) {
        clearRefinement.call(this, name);
    }
}

/**
 * Removes refinement with given name
 * @param {String} name
 */
function clearRefinement(name) {
    if (name in refinements.prefs) {
        delete refinements.prefs[name];
    }
}

/**
 * Create new refinement with given name and given start value
 * @param {String} name
 * @param {String} value
 */
function newRefinementValue(name, value) {
    if (typeof value == 'undefined') {
        refinements.prefs[name] = [];
    } else if (value instanceof Array && value.length >= 2) {
        refinements.prefs[name] = [value[0], value[1]];
    } else {
        refinements.prefs[name] = [value];
    }
};

/**
 * Sets price refinement with given start-end values
 * @param {Number} from
 * @param {Number} to
 */
function setPriceRefinement(from, to) {
    var appliedValues = refinements.prefs[attributes.priceRefinementName];
    
        from = +from;
        to = +to;
        refinements.prefs[attributes.priceRefinementName] = [];
        
    if (!appliedValues.length || !(appliedValues[0] == from && appliedValues[1] == to)) {
        if (!from) {
            from = 0;
        }
        toggleRefinementValue.call(this, this.getCurrentRefinement(), from);

        if (to || to === 0) {
            toggleRefinementValue.call(this, this.getCurrentRefinement(), to);
        }
    }
}

module.exports = refinementsmodel;