'use strict'
    
var $ = (typeof window !== "undefined" ? window['jQuery'] : typeof global !== "undefined" ? global['jQuery'] : null),
    urls = (typeof window !== "undefined" ? window['Urls'] : typeof global !== "undefined" ? global['Urls'] : null),
    JSConstants = require('./constants'),
    CssConstants = require('./../../cssconstants'),
    options = {
        nominatedDayDeliverySection : '.js-delivery_nominated_day_section'
    },
    $cache = {};

function initializeConfig(params) {
    $.extend(options, params || {});
}

function cacheInit(){
    $cache.document = $(document);
}

function showNominatedDaySection(){
    if (~this.value.indexOf(Constants.NOMINATED_DAY_SHIPPING_METHOD_ID)) {
        $(options.nominatedDayDeliverySection).removeClass(CssConstants.G_HIDDEN);
    } else {
        $(options.nominatedDayDeliverySection).addClass(CssConstants.G_HIDDEN);
    }
}

function setNominatedDay() {
    var self = $(this);
    
    $.ajax({
        url: urls.setNominatedDeliveryDay,
        type: "post",
        data: {
            format: 'ajax',
            nominatedday: self.val()
        }
    })
    .always(function(response){
        $cache.document.find('input[value="' + Constants.NOMINATED_DAY_SHIPPING_METHOD_ID + '"]').click();
    });
}

function initEvents(){
    $cache.document
        .on('change', JSConstants.CLASS_SHIPPING_METHOD_LIST + ' input:not(#dwfrm_deliverytostore_postcode)', showNominatedDaySection)
        .on('change', JSConstants.CLASS_SHIPPING_METHOD_LIST + ' select[name$="cart_shippingMethodNominatedDay"]', setNominatedDay);
}

var nominatedDayDelivery = {
    init: function(params){
        initializeConfig(params);
        cacheInit();
        initEvents();
    }
}

module.exports = nominatedDayDelivery;
