var Constants = require('./constants'),
    CssConstants = require('./../../cssconstants'),
    util = require('./../../util'),
    $ = (typeof window !== "undefined" ? window['jQuery'] : typeof global !== "undefined" ? global['jQuery'] : null),
    urls = (typeof window !== "undefined" ? window['Urls'] : typeof global !== "undefined" ? global['Urls'] : null),
    resources = (typeof window !== "undefined" ? window['Resources'] : typeof global !== "undefined" ? global['Resources'] : null),
    vipcard = require('./vipcard');
    
var $cache = {},
    options = {
        container: Constants.CLASS_ORDERDETAILS_REGISTRATION,
        form: Constants.CLASS_CONFIRMATION_REGISTER_FORM,
        errorBlock: Constants.CLASS_CONFIRMATION_REGISTER_ERROR
    };

function initializeCache() {
    $cache.document = $(document);
    $cache.form = $(document).find(options.form);
    $cache.container = $(document).find(options.container);
    $cache.content = $(document).find(Constants.CLASS_CONFIRNATION_REGISTER_CONTENT);
}

function initializeEvents() {
    $cache.form.on('submit', customerRegistrationHandler);
}

function customerRegistrationHandler(e) {
    e.preventDefault();

    util.progressShow($cache.form);
    
    $.ajax({
        type: 'POST',
        url: util.ajaxUrl(urls.confirmationRegister),
        data: $cache.form.serializeArray()
    }).done(function(response) {
        if (response && response.existingCustomer) {
            $cache.container.find(options.errorBlock).html(resources.EMAIL_ALREADY_EXIST_ERROR).removeClass(CssConstants.G_HIDDEN);
        } else if (response && response.success) {
            $cache.container.html($cache.content.html());
            
            // VIP card popup
            if (SitePreferences.VIPCARD_ENABLED) {
                vipcard.showSendVipCardPopup();
            }
        }
        util.progressHide($cache.form);
    });
}

module.exports = {
    init: function() {
        initializeCache();
        initializeEvents();
        
        vipcard.init({countryCode: $cache.form.data('billingCountry')});
    }
};