'use strict';

var options = {
        allowedActions: {
            'addtocart': true
        },
        pdpMain: '#pdpMain'
    },

    $cache = {},

    layout = require('./../../layout');

var actions = {
    'notifyme': require('./notifyme'),
    'addtocart': require('./addtocart')
};

var productactions = {
    init: function(params) {
        initializeConfig(params);
        initializeCache();
        initializeMobile();

        initializeActions(params);
    }
}

function initializeConfig(params) {
    options = $.extend({}, options, params);
}

function initializeCache() {
    $cache.window = $(window);
    $cache.document = $(document);
    $cache.pdpMain = $(options.pdpMain);
}

function initializeMobile() {
    if (layout.isMobile()) {
        initializeMobileEvents();
    }
}

function initializeMobileEvents() {
    $cache.document.on('product.options.error', scrollToInvalidOptions);
}

function initializeActions(params) {
    for (var action in options.allowedActions) {
        if (options.allowedActions[action]) {
            actions[action].init(params);
        }
    }
}

function scrollToInvalidOptions(e, params) {
    var $firstInvalid = params.$invalidOptions[0];
    var scrollTop = $cache.window.scrollTop();
    var windowHeight = $cache.window.height();
    var middleOfWindow = windowHeight / 2;
    var optionOffset = $firstInvalid.offset().top;
    if (optionOffset < scrollTop || optionOffset > scrollTop + windowHeight) {
        $cache.window.scrollTop(optionOffset - middleOfWindow);
    }
}

module.exports = productactions;