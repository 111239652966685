'use strict';

var compareWidget = require('./../../../../app_storefront_core/cartridge/js/compare-widget'),
    productTile = require('./../../../../app_storefront_core/cartridge/js/product-tile'),
    progress = require('./../progress'),
    sitePreferences = (typeof window !== "undefined" ? window['SitePreferences'] : typeof global !== "undefined" ? global['SitePreferences'] : null),
    util = require('./../../../../app_storefront_core/cartridge/js/util'),
    layout = require('./../layout'),
    productSearch = require('./../productsearch'),
    productImages = require('./product/images'),
    Constants = require('./product/constants'),
    pagesConstants = require('./constants'),
    SearchConstants = require('./search/constants'),
    variant = require('./product/variant'),
    refinements = require('../refinements'),
    cookie = require('./../cookie');

function checkSizeSwitches() {
    // Once the tiles are loaded, check if include different images for sizing and then show the main filter
    if ($('.js-product-images_switch .b-product_tile-size_switches').length > 0) {
        $('.b-plp_header_right .b-product_tile-size_switches').css('visibility', 'visible');
    }
}

function updatePLPHeader() {
    var $plpHeaderHeadline = $('input[name="plpHeaderHeadline"]');
    var updatedValue = ($plpHeaderHeadline.length ? $plpHeaderHeadline.val() : '');
    var $targetContainer = $('h1 span');

    if (updatedValue.length && $targetContainer.length &&
            updatedValue.toUpperCase() !== $targetContainer.text().toUpperCase()) {
        $targetContainer.text(updatedValue);
    }
}

/**
 * Update PLP description of content slot by value from hidden input (page meta tag) 'plpHeaderDescription'
 */
function updatePLPDescription() {
    var $isRefinedSearch = $('input[name="isRefinedSearch"]');

    if ($isRefinedSearch.val() !== 'true') {
        return;
    }

    var $targetContainer = $('.b-plps-links-container-description');
    var $plpHeaderDescription = $('input[name="plpHeaderDescription"]');
    var updatedValue = $plpHeaderDescription.length ? $plpHeaderDescription.val() : '';

    if ($targetContainer.length) {
        if (updatedValue) {
            $targetContainer.text(updatedValue);
        } else {
            $targetContainer.hide();
        }
    }
}

/**
 * Update PLP subcategories url of content slot by value hidden from input (page meta tag) 'plpRefinementQuery'
 */
function updatePLPSubCategoriesUrl() {
    var $plpRefinementQuery = $('input[name="plpRefinementQuery"]');
    var $targetContainer = $('.b-plps-links-container-list');
    var refinementQuery = $plpRefinementQuery.length ? $plpRefinementQuery.val() : '';
    var $links = $targetContainer.length ? $targetContainer.find('li a') : [];

    if (refinementQuery && $links.length) {
        $links.each(function () {
            var $link = $(this);
            $link.attr('href', $link.attr('href') + refinementQuery);
        });
    }
}

function infiniteScroll() {

    // getting the hidden div, which is the placeholder for the next page
    var loadingPlaceHolder = $('.js-infinite-scroll-placeholder[data-loading-state="unloaded"]');
    // get url hidden in DOM
    var gridUrl = loadingPlaceHolder.attr('data-grid-url');

    if (loadingPlaceHolder.length === 1 && util.elementInViewport(loadingPlaceHolder.get(0), 250)) {
        // switch state to 'loading'
        // - switches state, so the above selector is only matching once
        // - shows loading indicator
        loadingPlaceHolder.attr('data-loading-state', 'loading');
        loadingPlaceHolder.addClass('infinite-scroll-loading');


        // named wrapper function, which can either be called, if cache is hit, or ajax repsonse is received
        var fillEndlessScrollChunk = function (html) {
            loadingPlaceHolder.removeClass('infinite-scroll-loading');
            loadingPlaceHolder.attr('data-loading-state', 'loaded');
            $('.js-search-result-content').append(html);
        };

        // old condition for caching was `'sessionStorage' in window && sessionStorage["scroll-cache_" + gridUrl]`
        // it was removed to temporarily address RAP-2649

        $.ajax({
            type: 'GET',
            dataType: 'html',
            url: gridUrl,
            success: function (response) {
                // put response into cache
                try {
                    sessionStorage['scroll-cache_' + gridUrl] = response;
                } catch (e) {
                    // nothing to catch in case of out of memory of session storage
                    // it will fall back to load via ajax
                }
                // update UI
                fillEndlessScrollChunk(response);
                productTile.init();
                $(document).trigger('products.loaded');
            }
        });
    }
}

/**
 * @private
 * @function
 * @description function that handles 'Load More' button
 */
function loadMore() {
    var $loadingPlaceHolder = $('.js-load-more-placeholder');

    // get url hidden in DOM
    var gridUrl = $loadingPlaceHolder.attr('data-grid-url');
    var namePageSizeAttribute = 'p';
    var gridUrlParams = util.getQueryStringParams(gridUrl);

    //removing current element, we get new one from ajax response
    var $currentLoadMoreBtn = $(this).closest('.js-loadMoreButton');
    $currentLoadMoreBtn.attr('disabled', true).off('click');
    progress.show($(pagesConstants.CLASS_SEARCH_RESULT_CONTENT));

    if ($currentLoadMoreBtn.attr('disabled')) {
        var fillEndlessScrollChunk = function ($parsedHTML) {
            var $srsItems = $parsedHTML.find('.' + SearchConstants.SIMPLE_CLASS_SRS_ITEMS);
            if ($srsItems.length) {
                var item = $srsItems.children(SearchConstants.CLASS_SRS_ITEM);
                $(SearchConstants.CLASS_SRS_ITEMS).append(item);
                productImages.updateTiles(item.find(Constants.CLASS_PRD_IMAGES_CONTAINER));
            }

            var $loadMorePlaceholder = $parsedHTML.find('.js-load-more-placeholder');
            if ($loadMorePlaceholder.length) {
                $('.js-search-result-content').append($loadMorePlaceholder);

                $('.js-load-more-placeholder').replaceWith($loadMorePlaceholder);
            }
        };

        $.ajax({
            type: 'GET',
            dataType: 'html',
            url: gridUrl,
            success: function (response) {
                // put response into cache
                try {
                    sessionStorage['scroll-cache_' + gridUrl] = response;
                } catch (e) {
                    // nothing to catch in case of out of memory of session storage
                    // it will fall back to load via ajax
                }

                // update UI
                var url = window.location.href;
                url = util.removeParamFromURL(url, namePageSizeAttribute);
                url = util.appendParamToURL(url, namePageSizeAttribute, (gridUrlParams['start']/gridUrlParams['sz']) + 1);
                history.pushState(undefined, '', url);

                var response = $($.parseHTML(response));
                fillEndlessScrollChunk(response);

                progress.hide();
                productTile.init();
                productSearch.init();
                variant.init();

                $(document).trigger('products.loaded');
            }
        });
    }

}
/**
 * @private
 * @function
 * @description replaces breadcrumbs, lefthand nav and product listing with ajax and puts a loading indicator over the product listing
 */
function updateProductListing(url, scroller) {
    if (!url || url === window.location.href) {
        return;
    }
    progress.show($('.js-search-result-content'));
    $('#main').load(util.appendParamToURL(url, 'format', 'ajax'), function () {
        compareWidget.init();
        productTile.init();
        productSearch.init();
        progress.hide();
        productImages.updateSizeGrid();
        refinements.refresh();
        $.each($(document).find(Constants.CLASS_PRD_IMAGES_CONTAINER), function(i, el) {
            var $el = $(el);
            productImages.updateTileActiveTab($el);
        });
        history.pushState(undefined, '', url);
        productImages.initProductTileCarousels();
        $(document).trigger('products.loaded');

        if (scroller) {
            if (scroller.position > 0) {
                $(scroller.element.selector).animate({
                    scrollTop: scroller.position
                  }, scroller.speed);
            }
        }
        variant.init();
        checkSizeSwitches();
        updatePLPHeader();
        updatePLPDescription();
        updatePLPSubCategoriesUrl();
    });
}

/**
 * @private
 * @function
 * @description Initializes DOM
 */
function initializeDom() {
    updatePLPHeader();
    updatePLPDescription();
    updatePLPSubCategoriesUrl();
    initCategorySlotPositions()
}

function insertSlots(grid) {
    var isMobile = layout.isMobile();
    var slotsContainer = document.querySelector('.plp_slots');
    if (!!slotsContainer) {
        var slots = slotsContainer.querySelectorAll('.plp-slot');
 
        slots.forEach(slot => {
            var position = isMobile ? slot.dataset.mobilePosition : slot.dataset.position;
            if (position < grid.childElementCount) {
                var shiftedTile = grid.querySelectorAll('.b-product_list-tile')[position - 1];
                shiftedTile.insertAdjacentHTML("beforebegin", slot.outerHTML);

                slot.remove();
            }
        });
    }
}

function initCategorySlotPositions() {
    var grid = document.querySelector('#search-result-items');

    var mutationObserver = new MutationObserver(function(mutations) {
        var mutation = mutations[0];
        var isSlot = Array.from(mutation.addedNodes).filter(m => m.classList.contains('plp-slot')).length > 0;
        if (!isSlot) {
            insertSlots(grid);
        }
    });

    mutationObserver.observe(grid, {
        childList: true
    });

    insertSlots(grid);
}

/**
 * @private
 * @function
 * @description Initializes events for the following elements:<br/>
 * <p>refinement blocks</p>
 * <p>updating grid: refinements, pagination, breadcrumb</p>
 * <p>item click</p>
 * <p>sorting changes</p>
 */
function initializeEvents() {

    var $main = $('#main');

    checkSizeSwitches();

    // handle events for updating grid
    $main.on('search.update', function (e, params) {
        updateProductListing(params.targetURL, params.scroller);
    });

    // handle events item click. append params.
    $main.on('click', Constants.CLASS_PRD_IMAGES_MAIN + ', ' + Constants.CLASS_PRD_NAME, function () {
        var a = $(this);
        // get current page refinement values
        var wl = window.location;

        var qsParams = (wl.search.length > 1) ? util.getQueryStringParams(wl.search.substr(1)) : {};
        var hashParams = (wl.hash.length > 1) ? util.getQueryStringParams(wl.hash.substr(1)) : {};

        // merge hash params with querystring params
        var params = $.extend(hashParams, qsParams);
        if (!params.start) {
            params.start = 0;
        }
        // get the index of the selected item and save as start parameter
        var idx = a.closest(SearchConstants.CLASS_SRS_ITEM).index() || 0;

        // convert params.start to integer and add index
        params.start = (+params.start) + (idx + 1);
        // set the hash and allow normal action to continue
        a[0].hash = $.param(params);
    });

    // handle sorting change
    $main.on('change', '#grid-sort-header', function (e) {
        e.preventDefault();
        updateProductListing($(this).find('option:selected').val());
        var urlParam = $(this).find('option:selected').val();
        urlParam = urlParam.substring(urlParam.indexOf('srule=') + 6); urlParam = urlParam.substring(0, urlParam.indexOf('&'));
        var srules = {
            'best-matches': {sortBy: 'Best Matches', sortOrder: 'ASC'},
            'price-low-to-high': {sortBy: 'Price', sortOrder: 'ASC'},
            'price-high-to-low': {sortBy: 'Price', sortOrder: 'DESC'},
            'product-name-ascending': {sortBy: 'Product Name', sortOrder: 'ASC'},
            'product-name-descending': {sortBy: 'Product Name', sortOrder: 'DESC'},
            'brand': {sortBy: 'Brand', sortOrder: 'ASC'},
            'most-popular': {sortBy: 'Most Popular', sortOrder: 'ASC'},
            'top-sellers': {sortBy: 'Top Sellers', sortOrder: 'ASC'},
            'Miss-b-loves': {sortBy: 'Best Sellers', sortOrder: 'ASC'},
            'customer-favorites': {sortBy: 'Top Rated', sortOrder: 'ASC'},
            'new-in': {sortBy: 'New', sortOrder: 'DESC'}
        }
        var params = srules[urlParam];
        dynamicYield.callEvent('Sort Items', params);
    })
    .on('change', '.items-per-page select', function () {
        var refineUrl = $(this).find('option:selected').val();
        if (refineUrl === 'INFINITE_SCROLL') {
            $('html').addClass('infinite-scroll').removeClass('disable-infinite-scroll');
        } else {
            $('html').addClass('disable-infinite-scroll').removeClass('infinite-scroll');
            updateProductListing(refineUrl);
        }
    });

    switch (sitePreferences.SEARCH_MODE) {
        case 'INFINITE':
            $(window).on('scroll', infiniteScroll);
            break;
        case 'LAZY':
            $main.on('click', ".js-loadMoreButton", loadMore);
            break;
    }
}

exports.init = function () {
    compareWidget.init();
    productTile.init();
    initializeDom();
    initializeEvents();
};

