'use strict';

var Constants = require('./constants'),
    CssConstants = require('./../../cssconstants'),
    util = require('./../../util'),
    page = require('./../../page'),
    $ = (typeof window !== "undefined" ? window['jQuery'] : typeof global !== "undefined" ? global['jQuery'] : null),
    resources = (typeof window !== "undefined" ? window['Resources'] : typeof global !== "undefined" ? global['Resources'] : null),
    urls = (typeof window !== "undefined" ? window['Urls'] : typeof global !== "undefined" ? global['Urls'] : null);
    
var $cache = {},
    options = {
        braSizes: Constants.CLASS_BRA_SIZES,
        dressSizes: Constants.CLASS_DRESS_SIZES,
        hosierySizes: Constants.CLASS_HOSIERY_SIZES,
        updateBtn: Constants.CLASS_UPDATE_SIZE_PREFERENCE_BUTTON,
        isSizePreferenceCheckBox: Constants.CLASS_IS_SIZE_PREFERENCE
    };

function initializeCache() {
    $cache.document = $(document);
    $cache.sizePreferenceWrapper = $cache.document.find(Constants.CLASS_SIZE_PREFERENCE_WRAPPER);
    $cache.updateBtn = $cache.document.find(options.updateBtn);
}

function resetBtnView() {
    $cache.updateBtn
        .removeClass(CssConstants.M_UPDATED)
        .text($cache.updateBtn.val());
}

function selectSize(e) {
    var target = e.currentTarget;
    resetBtnView();
    if ($(target).hasClass(CssConstants.M_SELECTED)){
        $(target).removeClass(CssConstants.M_SELECTED);
    }
    else{
        switch(target.tagName) {
            case 'TD':
                $(options.braSizes).find('td').removeClass(CssConstants.M_SELECTED);
                break;
            case 'LI':
                $(target).closest('ul').find('li').removeClass(CssConstants.M_SELECTED);
        }
        $(target).addClass(CssConstants.M_SELECTED);    
    }
}
    
function updateSizePreference(e) {
    e.preventDefault();

    var data = {
        braSize: $(options.braSizes).find('.' + CssConstants.M_SELECTED).text(),
        dressSize: $(options.dressSizes).find('.' + CssConstants.M_SELECTED).text(),
        hosierySize: $(options.hosierySizes).find('.' + CssConstants.M_SELECTED).text(),
        isSizePreference: $(options.isSizePreferenceCheckBox).is(':checked')
    };
    
    util.progressShow($cache.sizePreferenceWrapper);
    
    $.ajax({
        type: 'POST',
        url: urls.updateSizePreferences,
        data: data
    }).done(function(response) {
        if ($($.parseHTML(response)).find(Constants.CLASS_LOGIN_BOX).length) {
            page.refresh();
        } else {
            util.progressHide($cache.sizePreferenceWrapper);
            if(response.success) {
                $cache.updateBtn
                    .addClass(CssConstants.M_UPDATED)
                    .text(resources.SAVED);
            }
        }
    });
}

function initializeEvents() {
    $cache.document
        .on('click', options.braSizes + ' td,' +  options.dressSizes + ' li,' + options.hosierySizes + ' li', selectSize)
        .on('click', options.isSizePreferenceCheckBox, resetBtnView)
        .on('click', options.updateBtn, updateSizePreference)
        .on('click', Constants.CLASS_SIZE_TOOLTIP, function(e) {
            util.contentShow(e.currentTarget);
        });
}

module.exports = {
    init: function() {
        initializeCache();
        initializeEvents();
    }
}